const voiceMailListMenu = [
    {
        module: "voicemail",
        name: "Voicemail Box",
        data: { "action": "check" },
        icon: "mdi-email-outline",
        children: {},
        tooltip: "Direct a caller to leave a voicemail message",
        dropEnable: false
    },
    {
        module: "voicemail",
        name: "Voice Mail",
        data: {},
        icon: "mdi-email-outline",
        tooltip: "Direct a caller to leave a voicemail message",
        dropEnable: false
    },
]


export default { voiceMailListMenu };
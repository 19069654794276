<template>
  <section>
    <!-- Add new user Dialog -->
    <v-dialog v-model="addNewUserDialog" width="400" persistent>
      <v-card>
        <v-form ref="form" v-model="isFormValid" lazy-validation>
          <v-card-title class="title">
            Create User
            <v-icon @click="closeAddNewUserDialog()" class="ml-auto"
              >cancel</v-icon
            >
          </v-card-title>
          <v-divider></v-divider>
          <v-tabs right v-model="tabs" class="pb-4">
            <v-tab> Basic</v-tab>
            <v-tab> Advanced</v-tab>
          </v-tabs>
          <v-card class="" elevation="0">
            <v-card-text class="py-0">
              <v-tabs-items v-model="tabs">
                <v-tab-item>
                  <v-col cols="12" md="12">
                    <!-- <v-col cols="12" md="6" class="py-0"> -->
                    <v-text-field
                      label="First Name"
                      v-model="user.first_name"
                      :rules="firstNameRules"
                      color="primary"
                      outlined
                    ></v-text-field>
                    <!-- </v-col> -->
                    <!-- <v-col cols="12" md="6" class="py-0"> -->
                    <v-text-field
                      label="Last Name"
                      v-model="user.last_name"
                      :rules="lastNameRules"
                      color="primary"
                      outlined
                    ></v-text-field>
                    <!-- </v-col> -->
                    <!-- <v-col cols="12" md="6" class="py-0"> -->
                    <v-text-field
                      label="Email"
                      v-model="user.email"
                      :rules="emailRules"
                      color="primary"
                      outlined
                    ></v-text-field>
                    <!-- </v-col> -->
                    <!-- <v-col cols="12" md="6" class="py-0"> -->
                    <v-text-field
                      label="Confirm Email"
                      v-model="confrimEmail"
                      outlined
                      color="primary"
                      :rules="[confirmEmailRules]"
                    >
                    </v-text-field>
                    <!-- </v-col> -->
                    <!-- <v-col cols="12" md="6" class="py-0"> -->
                    <v-checkbox
                      class="mt-0 pt-0"
                      label="Email Notifications"
                      v-model="user.emailNotification"
                    ></v-checkbox>
                  </v-col>
                  <!-- </v-row> -->
                  <!-- <user-devices-table :usersList="usersList" /> -->
                </v-tab-item>
                <!-- Advanced -->
                <v-tab-item>
                  <p><b>Standard Options</b></p>
                  <!-- <v-row> -->
                  <!-- <v-col cols="12" md="6"> -->
                  <v-col cols="12" md="12">
                    <v-select
                      label="Time zone"
                      :items="countriesList"
                      v-model="user.timezone"
                      outlined
                      color="primary"
                      clearable
                    >
                    </v-select>
                    <!-- </v-col> -->
                    <!-- <v-col cols="12" md="6"> -->
                    <v-select
                      label="Music on Hold"
                      :items="mediaList"
                      v-model="user.media"
                      item-text="name"
                      item-value="id"
                      outlined
                      color="primary"
                      hint="Select the media file that you want to be played by default when it 's not set on a user or a device."
                      persistent-hint
                      clearable
                    >
                    </v-select>
                    <!-- <v-row class="text-right mx-right"> -->
                    <!-- <v-col cols="12"> -->
                    <v-btn
                      text
                      class="primary-btn"
                      @click="openNewAddMediaDialog"
                      >Add a media</v-btn
                    >
                    <!-- </v-col> -->
                    <!-- </v-row> -->
                    <!-- </v-col> -->

                    <!-- <v-checkbox
                label="User has been verified"
                v-model="user.verified"
              ></v-checkbox>
              <v-checkbox
                class="pt-0 mt-0"
                label="Hide from Contact List"
                v-model="user.contactEnabled"
              ></v-checkbox> -->
                    <p><b>Queue Options</b></p>
                    <v-checkbox
                      class="pt-0 mt-0"
                      disabled
                      label="Record this agent(coming soon)"
                      v-model="user.record_call"
                    ></v-checkbox>
                  </v-col>
                  <!-- </v-row> -->
                  <!-- <v-btn
                  color="primary"
                  outlined
                  large
                  class="px-5 mt-4"
                  :loading="saveLoader"
                  @click="saveNewUser()"
                  block
                  >Save
                </v-btn> -->
                </v-tab-item>
              </v-tabs-items>
              <v-card-actions class="pb-5">
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  outlined
                  large
                  class="px-8 mt-4"
                  :loading="saveLoader"
                  @click="saveNewUser()"
                  >Save
                </v-btn>
              </v-card-actions>
            </v-card-text>
          </v-card>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- Add media Dialog -->
    <add-new-media
      :addNewMediaDialogBySelect="addNewMediaDialogBySelect"
      @close-media="closeNewMedia"
    />
    <!-- end: Add media Dialog  -->

    <!-- Add new user Dialog
    <add-new-user-dialog
      :addNewUserDialog="addNewUserDialog"
      @close-new-user-dialog="closeAddNewUserDialog"
    /> -->
  </section>
</template>


<script>
import getCountryList from "./jsFile/countryList";
import callFlow from "@/services/callFlowAPI.js";
import addNewMedia from "./addNewMedia.vue";
// import AddNewDevices from "./addNewDevices.vue";
// import AddNewUserDialog from "./addNewUserDialog";
// import userDevicesTable from "./userDevicesTable";

export default {
  props: ["addNewUserDialog"],
  components: {
    addNewMedia,
    // userDevicesTable,
    // AddNewDevices,
    // AddNewUserDialog,
  },
  data() {
    return {
      user: {
        emailNotification: true,
        priv_level: "user",
        contactEnabled: false,
        verified: false,
        record_call: false,
      },
      firstNameRules: [(v) => !!v || "first name is required"],
      lastNameRules: [(v) => !!v || "Last name is required"],
      emailRules: [
        (v) =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "this value should be a valid email",
      ],
      devices: [],

      tabs: null,
      isFormValid: true,
      countriesList: [],
      saveLoader: false,
      userDetail: {},
      authToken: "",
      // userEmail: "",
      addNewMediaDialogBySelect: false,
      // addNewDeviceDialog: false,
      // addNewUserDialog: false,
      mediaList: [],
      usersList: [],
      userLoader: false,
      userId: "",
      confrimEmail: "",
      // passwordRule: [
      //   (value) => {
      //     const pattern =
      //       /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
      //     return (
      //       pattern.test(value) ||
      //       "Min. 8 characters with at least one capital letter, a number and a special character."
      //     );
      //   },
      // ],
      characters: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz",
    };
  },
  mounted() {
    this.countriesList = getCountryList.countryList;
    this.userDetail = JSON.parse(localStorage.getItem("userDetail"));
    this.authToken = localStorage.getItem("authToken");
    // this.userEmail = localStorage.getItem("username");
    this.getMediaList();
    this.getUsersList();
    // this.getParticularDeviceData();
  },
  computed: {
    confirmEmailRules() {
      return this.user.email === this.confrimEmail || "Email must match";
    },
  },
  methods: {
    async getMediaList() {
      var getUsersData = {
        accountId: this.userDetail.accountId,
      };
      try {
        let response = await callFlow.getMediaList(getUsersData);
        this.mediaList = response.mediaList;
      } catch (error) {
        console.log("====error===", error);
      }
    },
    async getUsersList() {
      var getUsersData = {
        accountId: this.userDetail.accountId,
        userId: this.userDetail.userId,
        forwardType: "SOFTPHONE",
      };
      try {
        let response = await callFlow.getUsersList(getUsersData);
        var userData = {};
        for (let index = 0; index < response.usersList.length; index++) {
          const element = response.usersList[index];
          if (element.last_name === undefined || element.last_name === null) {
            element.name = element.first_name;
          } else {
            element.name = element.first_name + " " + element.last_name;
          }
          userData = element;
          this.usersList.push(userData);
        }
      } catch (error) {
        console.log("====error===", error);
      }
    },

    openNewAddMediaDialog() {
      this.addNewMediaDialogBySelect = true;
    },

    closeNewMedia() {
      this.addNewMediaDialogBySelect = false;
      this.getMediaList();
    },
    closeNewDeviceDialog() {
      this.addNewDeviceDialog = false;
      this.addNewUserDialog = true;
      // this.resetUserData();
      this.$emit("open-device-modal");
    },
    resetUserData() {
      this.$refs.form.reset();
    },
    closeAddNewUserDialog() {
      this.$emit("close-new-user-dialog", this.userId);
      this.resetUserData();
    },
    generateString(length) {
      let result = "";
      const charactersLength = this.characters.length;
      for (let i = 0; i < length; i++) {
        result += this.characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }

      return result;
    },
    async saveNewUser() {
      if (this.$refs.form.validate()) {
        //random  number
        let number = Math.floor(100000 + Math.random() * 900000);
        let userName = this.generateString(3) + "-" + number;

        this.userLoader = true;
        this.saveLoader = true;
        let name = null;
        var createPsw = "psw" + ("" + Math.random()).substring(2, 7);
        if (this.user.first_name && this.user.last_name) {
          name = this.user.first_name + " " + this.user.last_name;
        } else {
          name = this.user.first_name;
        }

        var userData = {
          accountId: this.userDetail.accountId,
          authToken: this.authToken,
          usersData: {
            data: {
              apps: {},
              call_forward: {
                substitute: true,
                enabled: false,
                failover: false,
                require_keypress: false,
                keep_caller_id: false,
                direct_calls_only: false,
              },
              call_restriction: {
                closed_groups: { action: "inherit" },
                tollfree_us: { action: "inherit" },
                toll_us: { action: "inherit" },
                emergency: { action: "inherit" },
                caribbean: { action: "inherit" },
                did_us: { action: "inherit" },
                international: { action: "inherit" },
                unknown: { action: "inherit" },
              },
              caller_id: {
                internal: {
                  name: name,
                },
                external: {
                  name: name,
                },
              },
              hotdesk: {
                enabled: false,
                id: "",
                require_pin: false,
                keep_logged_in_elsewhere: false,
              },
              contact_list: { exclude: this.user.contactEnabled },
              priv_level: "user",
              music_on_hold: { media_id: this.user.media },
              timezone: this.user.timezone,
              username: userName,
              first_name: this.user.first_name,
              last_name: this.user.last_name,
              email: this.user.email,
              vm_to_email_enabled: this.user.emailNotification,
              verified: this.user.verified,
              record_call: this.user.record_call,
              password: createPsw,
              ui_metadata: {
                version: "4.3-139",
                ui: "monster-ui",
                origin: "callflows",
              },
            },
          },
        };
        try {
          let response = await callFlow.addUsers(userData);
          this.userLoader = false;
          this.userId = response.responseData.data.id;
          this.closeAddNewUserDialog();
          this.resetUserData();
          this.saveLoader = false;
          this.$root.$emit("SHOW_SNACKBAR", {
            text: response.messageDetail,
            color: "success",
          });
        } catch (error) {
          this.userLoader = false;
          this.saveLoader = false;
          this.$root.$emit("SHOW_SNACKBAR", {
            text: "something wrong",
            color: "error",
          });
        }
      }
    },
  },
};
</script>
const timeOfDayListMenu = [
    {
        module: "temporal_route",
        name: "Time Of Day",
        data: {},
        icon: "alarm",
        dropEnable: false
    },
    // {
    //     module: "temporal_route",
    //     name: "Time Based Conditions",
    //     data: { icon: "mdi-signal-cellular-outline" },
    // },
]


export default { timeOfDayListMenu };
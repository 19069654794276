<template>
  <section>
    <v-card :loading="loader">
      <v-card-title class="title">
        Call Recording
        <v-icon @click="closeCallRecordingDialog" class="ml-auto"
          >cancel</v-icon
        >
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="form" v-model="isFormValid" lazy-validation>
          <p>Start call recording</p>
          <v-select
            label="Format"
            :items="format"
            v-model="editedItem.data.format"
            outlined
            color="primary"
            :rules="formatRules"
          ></v-select>
          <!-- <template slot="item" slot-scope="data">
            <v-list-item-content @click="getItemText(data.item.name)">
              <v-list-item-title v-html="data.item.name"></v-list-item-title>
            </v-list-item-content>
          </template> -->
          <v-text-field
            label="URL"
            v-model="editedItem.data.url"
            placeholder="http://your.server.com/place/to/store/recordings"
            outlined
            color="primary"
            :rules="callRecordingRules"
          >
          </v-text-field>
          <v-text-field
            label="Time Limit (s):"
            type="number"
            v-model="editedItem.data.time_limit"
            outlined
            color="primary"
            :rules="timeLimitRules"
          >
          </v-text-field>
          <v-btn block color="primary" outlined large @click="save()"
            >Save
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </section>
</template>

<script>
// import callFlow from "@/services/callFlowAPI.js";

export default {
  props: ["editedItem", "selectedDragList", "editedIndex"],
  data() {
    return {
      format: ["mp3", "wav"],
      // callRecording: {
      //   timeLimit: "600",
      // },
      formatRules: [(v) => !!v || "Fields is required"],
      callRecordingRules: [(v) => !!v || "Fields is required"],
      timeLimitRules: [(v) => !!v || "Fields is required"],
      userDetail: {},
      loader: false,
      isFormValid: true,
      saveLoader: false,
      editedItemModal: false,
      byDefualtAddData: true,
      authToken: "",
      selectedValue: "",
    };
  },
  mounted() {
    this.userDetail = JSON.parse(localStorage.getItem("userDetail"));
    this.authToken = localStorage.getItem("authToken");
  },
  methods: {
    save() {
      // get the selected module properties from editedItem obj
      if (this.$refs.form.validate()) {
        // console.log("this.editedItem.data", this.editedItem.data);
        // var editedItem = {};
        // editedItem.data = {
        //   name: this.selectedValue,
        //   timeout: this.editedItem.data.timeout,
        //   can_call_self: this.editedItem.data.can_call_self,
        //   icon: this.editedItem.data.icon,
        //   id: this.editedItem.data.id,
        // };
        // this.editedItem.data = editedItem.data;
          //this.selectedDragList[this.editedIndex] = this.editedItem;
        this.editedItemModal = true;
        //storing the selected data into call flow API on didnpbx page
        this.$emit(
          "save-callRecording-data",
          this.selectedDragList,
          this.editedItem,
          this.editedIndex
        );
        this.closeOnSave();
      }
    },
    getItemText(selectText) {
      this.selectedValue = selectText;
    },
    closeOnSave() {
      this.$emit("close-on-save-callRecording-dialog");
    },
    closeCallRecordingDialog() {
      // if (this.editedItem.module === "record_call") {
      //   if (this.editedItemModal == true) {
      //     this.closeOnSave();
      //   }
      //   if (this.editedItemModal != true) {
      //     this.$emit("close-callRecording-dialog");
      //   }
      // }

      // if (this.byDefualtAddData) {
      //   this.editedItem.data.id = "";
      //   this.editedItem.data.name = "";
      //   this.selectedValue = "";
      // }
      this.closeOnSave();
    },
  },
};
</script>
<template>
  <section>
    <p class="pt-3"><b>Devices</b></p>
    <!-- alert content -->
    <v-alert outlined color="success" text class="body-2">
      <i>
        The following devices are mapped to this user. If you're creating a
        user, you can add a Device and it will be automatically assigned to this
        new user once the user creation is completed.
      </i>
    </v-alert>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        class="caption"
        outlined
        @click="openNewDeviceDialog"
        >Add a new device for this user</v-btn
      >
    </v-card-actions>
    <!-- satrt:data table -->
    <v-data-table
      :loading="loadingTable || loader"
      class="elevation-1"
      :headers="headers"
      :items="devices"
      :hide-default-footer="true"
    >
      <template v-slot:[`item.enabled`]="{ item }">
        <v-card-actions class="pa-0 ma-0">
          <v-spacer></v-spacer>
          <v-checkbox
            v-model="item.enabled"
            @click="getSelectData(item)"
            :disabled="item.disableSeleted"
          ></v-checkbox>
          <v-spacer></v-spacer>
        </v-card-actions>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <span v-if="item.device_type == 'softphone'">
          <v-icon :color="item.username | getDeviceStatus">call</v-icon>
        </span>
        <span v-else>
          <v-icon color="black">-</v-icon>
        </span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          class="mx-2"
          fab
          outlined
          dark
          small
          color="primary"
          @click="openParticularDevice(item.id)"
        >
          <v-icon dark size="18"> edit </v-icon>
        </v-btn>
        <v-btn
          class="mx-2"
          outlined
          fab
          dark
          small
          color="red"
          @click="openDeleteModal(item)"
        >
          <v-icon dark size="18"> delete </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <!-- action modal -->
    <v-dialog v-model="deleteDeviceModal" width="500" persistent>
      <v-card>
        <v-card-text class="text-center pb-0">
          <v-icon size="50" color="green" class="py-5">help</v-icon>
          <h2 class="black--text font-weight-regular">Confirmation</h2>
          <p class="subtitle-1 black--text font-weight-regular">
            Do you really want to delete this device?
          </p>
          <!-- </v-card-title> -->
        </v-card-text>
        <v-col sm="12">
          <v-row>
            <v-col sm="6">
              <v-btn
                color="error"
                outlined
                block
                @click="deleteDeviceModal = false"
              >
                Cancel
              </v-btn>
            </v-col>
            <v-col sm="6">
              <v-btn
                block
                color="green darken-1"
                :loading="deleteLoader"
                outlined
                @click="deleteDevice()"
              >
                Confirm
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-card>
    </v-dialog>
    <!-- Add new device Dialog -->
    <add-new-devices
      :addNewDeviceDialog="addNewDeviceDialog"
      :userDetail="userDetail"
      :authToken="authToken"
      :usersList="usersList"
      :showUser="showUser"
      :userId="userId"
      @getSoftPhoneData="getSoftPhoneDeviceData"
      @close-new-device-modal="closeNewDeviceDialog"
      @showUserEnable="showUserEnable"
      ref="openNewDeviceDialog"
    />
    <!-- end: Add new device Dialog  -->

    <!-- Edit device Dialog -->
    <edit-device-dialog
      :editCreatedDeviceDialog="editCreatedDeviceDialog"
      :userDetail="userDetail"
      :authToken="authToken"
      :usersList="usersList"
      :editDevice="editDevice"
      @close-edit-device-dialog="closeEditDeviceDialog"
      @close-on-delete-edit-device-dialog="closeEditDeviceDialog"
    />
    <!-- end: edit device Dialog  -->
  </section>
</template>

<script>
import callFlow from "@/services/callFlowAPI.js";
import AddNewDevices from "./addNewDevices.vue";
import editDeviceDialog from "./editDeviceDialog.vue";

export default {
  name: "userDevice",
  props: ["devices", "usersList", "loadingTable", "userId"],
  components: {
    AddNewDevices,
    editDeviceDialog,
  },
  data() {
    return {
      userDetail: {},
      addNewDeviceDialog: false,
      editCreatedDeviceDialog: false,
      deleteLoader: false,
      deleteDeviceModal: false,
      loader: false,
      showUser: "newDeviceOnUser",
      authToken: "",
      editDevice: {
        call_forward: {},
      },
      editDeviceData: {},
      headers: [
        {
          text: "Device Name",
          value: "name",
          sortable: false,
        },
        {
          text: "Type",
          value: "device_type",
          align: "center",
          sortable: false,
        },
        {
          text: "Status",
          value: "status",
          align: "center",
          sortable: false,
        },
        {
          text: "Enabled",
          value: "enabled",
          align: "center",
          sortable: false,
        },
        {
          text: "Actions",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.userDetail = JSON.parse(localStorage.getItem("userDetail"));
    this.authToken = localStorage.getItem("authToken");
  },
  filters: {
    getDeviceStatus(userId) {
      // console.log("userId", userId);
      let currentList = JSON.parse(localStorage.getItem("currentList"));
      // console.log("currentList", currentList);
      const getDeviceType = currentList.find(
        (moduleObject) => moduleObject.username == userId
      );
      // console.log("getDeviceT/ype", getDeviceType);
      if (getDeviceType) {
        return "green";
      } else {
        return "red";
      }
    },
  },
  methods: {
    async getSelectData(data) {
      //enable device
      var editFaxBoxData = {
        accountId: this.userDetail.accountId,
        authToken: this.authToken,
        deviceId: data.id,
        editData: { data: data },
      };
      try {
        let response = await callFlow.editDeviceDetails(editFaxBoxData);
        this.$root.$emit("SHOW_SNACKBAR", {
          text: response.messageDetail,
          color: "success",
        });
      } catch (error) {
        this.$root.$emit("SHOW_SNACKBAR", {
          text: "something wrong",
          color: "error",
        });
        console.log("====error===", error);
      }
    },
    openNewDeviceDialog() {
      this.addNewDeviceDialog = true;
      this.$refs.openNewDeviceDialog.setValue();
      this.showUser = "newDeviceOnUser";
      this.$emit("close-new-user-dialog");
    },
    closeNewDeviceDialog() {
      this.addNewDeviceDialog = false;
      this.addNewUserDialog = true;
      this.$emit("get-user-devices");
      this.$emit("open-device-modal");
    },
    getSoftPhoneDeviceData(deviceId) {
      this.editParticularDeviceDialog(deviceId);
    },
    openDeleteModal(device) {
      this.deleteDeviceModal = true;
      this.editDeviceData = device;
    },
    async deleteDevice() {
      this.deleteLoader = true;
      var deleteDeviceData = {
        accountId: this.userDetail.accountId,
        authToken: this.authToken,
        deviceId: this.editDeviceData.id,
      };
      try {
        let response = await callFlow.deleteDeviceDetails(deleteDeviceData);
        this.deleteLoader = false;
        this.deleteDeviceModal = false;
        this.$emit("get-user-devices");
        this.$root.$emit("SHOW_SNACKBAR", {
          text: response.messageDetail,
          color: "success",
        });
      } catch (error) {
        this.deleteLoader = false;
        this.$root.$emit("SHOW_SNACKBAR", {
          text: "something wrong",
          color: "error",
        });
      }
    },
    openParticularDevice(deviceId) {
      this.editParticularDeviceDialog(deviceId);
      this.editCreatedDeviceDialog = true;
    },
    async editParticularDeviceDialog(deviceId) {
      this.loader = true;
      var getDeviceData = {
        accountId: this.userDetail.accountId,
        authToken: this.authToken,
        deviceId: deviceId,
      };
      try {
        let response = await callFlow.getParticularDeviceDetails(getDeviceData);
        this.editDevice = response.deviceDetails;
        if (
          this.editDevice.caller_id &&
          Object.keys(this.editDevice.caller_id).length != 0
        ) {
          if (
            this.editDevice &&
            this.editDevice.caller_id &&
            this.editDevice.caller_id.external &&
            this.editDevice.caller_id.external.number
          ) {
            this.editDevice.number = this.editDevice.caller_id.external.number;
          }
        }
        this.loader = false;
        // this.closeEditDeviceDialog();
      } catch (error) {
        console.log("====error===", error);
        this.loader = false;
      }
    },
    closeEditDeviceDialog() {
      this.editCreatedDeviceDialog = false;
      this.$emit("get-user-devices");
    },
    showUserEnable() {
      this.showUser = "";
    },
  },
};
</script>
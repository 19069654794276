<template>
  <section>
    <v-card :loading="loader">
      <v-card-title class="title">
        Route Options
        <v-icon @click="closeRouteOptionDialog" class="ml-auto">cancel</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <!-- {{ routeOptionList }} -->
        <v-select
          label="Select Route Key"
          :items="routeOptionList"
          item-text="text"
          item-value="value"
          v-model="getOptionKey.value"
          @change="getOption(getOptionKey.value)"
          outlined
          color="primary"
          return-object
        >
        </v-select>
        <v-btn block color="primary" outlined large @click="save()"
          >Save
        </v-btn>
      </v-card-text>
    </v-card>
  </section>
</template>

<script>
export default {
  props: [
    "editedItem",
    "selectedDragList",
    "editedIndex",
    "getOptionKey",
    "optionModule",
    "routeOptionList",
    "selectedStaticKey",
  ],
  components: {},
  data() {
    return {
      loader: false,
      selectedOptionModule: {},
    };
  },
  mounted() {},
  methods: {
    getOption(getSelectedOptionKey) {
      let metaData = {};
      if (JSON.parse(localStorage.getItem("metaData"))) {
        metaData = JSON.parse(localStorage.getItem("metaData"));
      }
      metaData[getSelectedOptionKey.value] = {
        name: getSelectedOptionKey.text,
      };

      localStorage.setItem("metaData", JSON.stringify(metaData));

      this.selectedOptionModule = {};

      if (Object.keys(this.editedItem).length != 1) {
        delete Object.assign(this.editedItem, {
          [getSelectedOptionKey.value]: this.editedItem[this.selectedStaticKey],
        })[this.selectedStaticKey];
        this.selectedOptionModule = this.editedItem;
      } else {
        for (const key in this.editedItem) {
          if (this.editedItem.hasOwnProperty.call(this.editedItem, key)) {
            const element = this.editedItem[key];
            this.selectedOptionModule[getSelectedOptionKey.value] = element;
          }
        }
      }
    },
    save() {
      if (this.getOptionKey.value.value == undefined) {
        this.selectedOptionModule = {};
        if (Object.keys(this.editedItem).length != 1) {
          delete Object.assign(this.editedItem, {
            [this.getOptionKey.value]: this.editedItem[this.selectedStaticKey],
          })[this.selectedStaticKey];
          this.selectedOptionModule = this.editedItem;
        } else {
          for (const key in this.editedItem) {
            if (this.editedItem.hasOwnProperty.call(this.editedItem, key)) {
              const element = this.editedItem[key];
              this.selectedOptionModule[this.getOptionKey.value] = element;
            }
          }
        }
      }
      if (this.optionModule) {
        this.selectedDragList[this.editedIndex] = this.selectedOptionModule;
      } else {
        this.$set(
          this.selectedDragList,
          this.editedIndex,
          this.selectedOptionModule
        );
      }
      //storing the selected data into call flow API on didnpbx page
      this.$emit(
        "save-route-options-data",
        this.selectedDragList,
        this.selectedOptionModule,
        // this.editedItem,
        this.editedIndex
      );
      this.closeRouteOptionDialog();
    },
    closeRouteOptionDialog() {
      this.$emit("close-route-options-dialog");
    },
  },
};
</script>
<template>
  <section>
    <v-card :loading="loader">
      <v-card-title class="title">
        CID Check
        <v-icon @click="closeOnSave" class="ml-auto">cancel</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <!-- <h3 class="pt-5 pb-3">Select CID Check</h3> -->
        <v-select
          label="Select CID Check"
          :items="cidCheckList"
          item-text="name"
          item-value="value"
          v-model="editedItem.data.use_absolute_mode"
          outlined
          color="primary"
          class="pb-0 mb-0"
          @change="checkStatus()"
        >
        </v-select>
        <v-text-field
          v-if="editedItem.data.use_absolute_mode == false"
          v-model="editedItem.data.regex"
          placeholder="Regex pattern: ^\*24([0-9]*)$"
          color="primary"
          outlined
        ></v-text-field>
        <v-btn block color="primary" outlined large @click="save()"
          >Save
        </v-btn>
      </v-card-text>
    </v-card>
  </section>
</template>

<script>
export default {
  name: "addCheckCidDialog",
  props: ["editedItem", "selectedDragList", "editedIndex"],
  data() {
    return {
      //   durationRules: [(v) => !!v || "Field is required"],
      cidCheckList: [
        {
          name: "Exact numbers",
          value: true,
        },
        {
          name: "Regex match",
          value: false,
        },
      ],
      loader: false,
      isFormValid: true,
      editedItemModal: false,
    };
  },
  mounted() {},
  methods: {
    checkStatus() {
      if (this.editedItem.data.use_absolute_mode == true) {
        this.editedItem.data.regex = "";
        this.editedItem.data.name = "Exact numbers";
      } else {
        this.editedItem.data.name = "Regex match";
      }
    },
    save() {
      // get the selected module properties from editedItem obj
      var editedItem = {};
      editedItem.data = {
        use_absolute_mode: this.editedItem.data.use_absolute_mode,
        regex: this.editedItem.data.regex,
        icon: this.editedItem.data.icon,
      };
      this.editedItem.data = editedItem.data;
      if (this.editedItem.data.use_absolute_mode == true) {
        this.editedItem.data.regex = "";
        this.editedItem.data.name = "Exact numbers";
      } else {
        this.editedItem.data.name = "Regex match";
      }
      // console.log("this.editedItem=====", this.editedItem);
      this.editedItem.children = [];
        //this.selectedDragList[this.editedIndex] = this.editedItem;
      this.editedItemModal = true;
      //storing the selected data into call flow API on didnpbx page
      if (this.editedItem.data.name == "Regex match") {
        if (this.editedItem.data.regex) {
          this.$emit(
            "save-cid-check-data",
            this.selectedDragList,
            this.editedItem,
            this.editedIndex
          );
          this.closeOnSave();
        } else {
          this.$root.$emit("SHOW_SNACKBAR", {
            text: "Regex pattern field is required!",
            color: "error",
          });
        }
      } else {
        this.$emit(
          "save-cid-check-data",
          this.selectedDragList,
          this.editedItem,
          this.editedIndex
        );
        this.closeOnSave();
      }
    },
    closeOnSave() {
      this.$emit("close-on-save-cid-check-dialog");
    },
  },
};
</script>
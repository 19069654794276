<template>
  <v-card>
    <v-card-title class="title">
      Edit Time Of Day
      <v-icon @click="closeEditNewTimeOfDayDialog()" class="ml-auto"
        >cancel</v-icon
      >
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="form" v-model="isFormValid" lazy-validation>
        <v-row>
          <v-col cols="6">
            <v-text-field
              class="pt-3"
              label="Name"
              v-model="timeOfDay.name"
              color="primary"
              hint="Name of this Time of Day Rule"
              outlined
              :rules="nameRules"
            ></v-text-field>
            <v-select
              label="Repeat"
              :items="repeatList"
              v-model="timeOfDay.cycle"
              item-value="value"
              item-text="text"
              outlined
              clearable
              color="primary"
              @change="changeCycle()"
            >
            </v-select>
            <v-text-field
              label="Every"
              v-model="timeOfDay.interval"
              color="primary"
              outlined
              type="number"
              hint="month(s)"
              v-if="timeOfDay.cycle == 'monthly' || timeOfDay.cycle == 'weekly'"
            ></v-text-field>
            <v-select
              label="Every"
              :items="yearlyList"
              v-model="timeOfDay.interval"
              item-text="text"
              item-value="value"
              outlined
              color="primary"
              clearable
              v-if="timeOfDay.cycle == 'yearly'"
              hint="Depending on the cycle, define the recurrence of this rule  "
            >
            </v-select>
            <p class="mb-2"><b>On:</b></p>
            <v-row
              v-if="timeOfDay.cycle == 'yearly' || timeOfDay.cycle == 'monthly'"
            >
              <v-col cols="6">
                <v-select
                  label="Ordinal"
                  :items="ordinalList"
                  v-model="timeOfDay.ordinal"
                  @change="setWeekandDayOption"
                  outlined
                  color="primary"
                  hide-details
                >
                </v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                  v-if="timeOfDay.ordinal != 'every'"
                  label="Select Week Day"
                  :items="wdayList"
                  item-text="text"
                  item-value="value"
                  v-model="wdays"
                  outlined
                  color="primary"
                  hint="Depending on the cycle, define the recurrence of this rule  "
                  hide-details
                  @change="setWDay()"
                >
                </v-select>
                <v-select
                  label="Select Days"
                  :items="daysList"
                  v-model="wdays"
                  outlined
                  color="primary"
                  hint="Depending on the cycle, define the recurrence of this rule  "
                  hide-details
                  v-if="timeOfDay.ordinal == 'every'"
                  @change="setDays()"
                >
                </v-select>
              </v-col>
            </v-row>

            <v-btn-toggle
              v-model="weekDays"
              dense
              background-color="white"
              multiple
              color="info"
              v-if="timeOfDay.cycle == 'weekly'"
            >
              <v-btn small> S </v-btn>
              <v-btn small> M </v-btn>
              <v-btn small> T </v-btn>
              <v-btn small> W </v-btn>
              <v-btn small> T </v-btn>
              <v-btn small> F </v-btn>
              <v-btn small> S </v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col cols="6">
            <v-menu
              v-model="datePickerMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  placeholder="Start Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  class="mt-3"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                no-title
                @input="datePickerMenu = false"
              ></v-date-picker>
            </v-menu>
            <v-row>
              <v-col cols="4" class="pt-0 pb-0" v-if="timeOfDay.allDay != true">
                <v-autocomplete
                  :items="timeList"
                  v-model="fromTime"
                  outlined
                  placeholder="FROM"
                  :rules="fromTimeRule"
                  @change="sendTimeRuleError"
                ></v-autocomplete>
              </v-col>
              <v-col cols="4" class="pt-0 pb-0" v-if="timeOfDay.allDay != true">
                <v-autocomplete
                  :items="timeList"
                  v-model="toTime"
                  outlined
                  placeholder="TO"
                  :rules="toTimeRule"
                  @change="sendTimeRuleError"
                ></v-autocomplete>
              </v-col>
              <v-col cols="4" class="pb-0">
                <v-checkbox
                  class="mt-1 mb-5"
                  label="All Day"
                  v-model="timeOfDay.allDay"
                ></v-checkbox>
              </v-col>
            </v-row>

            <v-select
              label="Enable"
              :items="enableList"
              v-model="timeOfDay.enabled"
              item-value="value"
              item-text="text"
              outlined
              color="primary"
              clearable
              hint="Select when this rule should be enabled"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-card-actions class="mb-0 pb-0">
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            :loading="deleteLoader"
            outlined
            large
            @click="deleteTODDetails()"
            >Delete
          </v-btn>
          <v-btn
            color="primary"
            outlined
            class="px-5 right"
            large
            :loading="saveLoader"
            @click="saveNewTimeOfDay()"
            >Save
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card-text>
  </v-card>
</template>


<script>
import callFlow from "@/services/callFlowAPI.js";
import timer from "./jsFile/timer";
import getDaysList from "./jsFile/daysList";

export default {
  props: ["timeOfDay", "weekDays", "date", "fromTime", "toTime", "wdays"],
  data() {
    return {
      addNewTimeOfDayDialog: null,
      saveLoader: false,
      isFormValid: true,
      nameRules: [(v) => !!v || "Name is required"],
      toTimeRule: [(v) => !!v || "Field is required"],
      fromTimeRule: [(v) => !!v || "Field is required"],
      authToken: "",
      userDetail: "",
      datePickerMenu: false,
      dateFormatted: null,
      daysList: [],
      deleteLoader: false,
      ordinalList: [
        { text: "First", value: "first" },
        { text: "Second", value: "second" },
        { text: "Third", value: "third" },
        { text: "Fourth", value: "fourth" },
        { text: "Fifth", value: "fifth" },
        { text: "Last", value: "last" },
        { text: "Day", value: "every" },
      ],
      wdayList: [
        { text: "Sunday", value: "sunday" },
        { text: "Monday", value: "monday" },
        { text: "Tuesday", value: "tuesday" },
        { text: "Wensday", value: "wensday" },
        { text: "Thursday", value: "thursday" },
        { text: "Friday", value: "friday" },
        { text: "Saturday", value: "saturday" },
      ],
      timeList: [],
      repeatList: [
        {
          text: "Weekly",
          value: "weekly",
        },
        {
          text: "Monthly",
          value: "monthly",
        },
        {
          text: "Yearly",
          value: "yearly",
        },
      ],
      enableList: [
        {
          text: "Based on time",
          value: "",
        },
        {
          text: "Forced On",
          value: true,
        },
        {
          text: "Forced Off",
          value: false,
        },
      ],
      yearlyList: [
        { value: 1, text: "January" },
        { value: 2, text: "February" },
        { value: 3, text: "March" },
        { value: 4, text: "April" },
        { value: 5, text: "May" },
        { value: 6, text: "June" },
        { value: 7, text: "July" },
        { value: 8, text: "August" },
        { value: 9, text: "September" },
        { value: 10, text: "October" },
        { value: 11, text: "November" },
        { value: 12, text: "December" },
      ],
    };
  },
  mounted() {
    this.daysList = getDaysList.days;
    this.timeList = timer.timerList;
    this.userDetail = JSON.parse(localStorage.getItem("userDetail"));
    this.authToken = localStorage.getItem("authToken");
  },
  methods: {
    sendFromTimeError() {
      if (this.toTime) {
        this.fromTimeRule = [(v) => this.fromTime < this.toTime];
        if (this.fromTime > this.toTime) {
          this.$root.$emit("SHOW_SNACKBAR", {
            text: "The starting hour must be prior to the ending hour.All",
            color: "error",
          });
        }
      }
    },
    sendTimeRuleError() {
      if (this.fromTime && this.toTime) {
        if (this.fromTime > this.toTime) {
          this.fromTimeRule = [(v) => v];
        } else {
          this.fromTimeRule = [];
        }
        if (this.fromTime > this.toTime) {
          this.$root.$emit("SHOW_SNACKBAR", {
            text: "The starting hour must be prior to the ending hour.All",
            color: "error",
          });
        }
      }

      if (this.fromTime && this.toTime) {
        if (this.toTime < this.fromTime) {
          this.toTimeRule = [(v) => v];
        } else {
          this.toTimeRule = [];
        }
        if (this.toTime < this.fromTime) {
          this.$root.$emit("SHOW_SNACKBAR", {
            text: "The starting hour must be prior to the ending hour.All",
            color: "error",
          });
        }
      }
    },
    setWDay() {
      this.weekDays = [];
      this.timeOfDay.wdays = [];
      this.timeOfDay.wdays.push(this.wdays);
      delete this.timeOfDay.days;
      this.weekDays = this.timeOfDay.wdays;
    },
    setDays() {
      this.weekDays = [];
      this.timeOfDay.days = [];
      this.timeOfDay.days.push(this.wdays);
      delete this.timeOfDay.wdays;
      this.weekDays = this.timeOfDay.days;
    },
    changeCycle() {
      if (
        this.timeOfDay.cycle === "monthly" ||
        this.timeOfDay.cycle === "yearly"
      ) {
        this.weekDays = [];
        this.timeOfDay.wdays = [];
        if (!this.timeOfDay.ordinal) {
          this.timeOfDay.ordinal = "second";
          this.wdays = "thursday";
        }
        this.timeOfDay.wdays.push(this.wdays);
        this.weekDays = this.timeOfDay.wdays;
      } else {
        // if (this.timeOfDay.ordinal) {
        //   delete this.timeOfDay.ordinal;
        // }
        if (this.wdays) {
          delete this.wdays;
        }
      }
      if (this.timeOfDay.cycle === "yearly") {
        this.timeOfDay.interval = "2";
      } else {
        this.timeOfDay.interval = "1";
      }
    },
    getWeekDay() {
      let timeOfDay = this.weekDays;
      this.weekDays = [];
      this.timeOfDay.wdays = [];
      if (timeOfDay.length != 0) {
        for (let index = 0; index < timeOfDay.length; index++) {
          const element = timeOfDay[index];
          // console.log(element);
          if (element == 0 || element == "sunday") {
            this.timeOfDay.wdays.push("sunday");
            this.weekDays.push(0);
          } else if (element == 1 || element == "monday") {
            this.timeOfDay.wdays.push("monday");
            this.weekDays.push(1);
          } else if (element == 2 || element == "tuesday") {
            this.timeOfDay.wdays.push("tuesday");
            this.weekDays.push(2);
          } else if (element == 3 || element == "wednesday") {
            this.timeOfDay.wdays.push("wednesday");
            this.weekDays.push(3);
          } else if (element == 4 || element == "thursday") {
            this.timeOfDay.wdays.push("thirsday");
            this.weekDays.push(4);
          } else if (element == 5 || element == "friday") {
            this.timeOfDay.wdays.push("friday");
            this.weekDays.push(5);
          } else if (element == 6 || element == "saturday") {
            this.timeOfDay.wdays.push("saturday");
            this.weekDays.push(6);
          }
        }
      }
    },
    getStartTimeInSecond() {
      if (this.fromTime) {
        var hms = this.fromTime + ":00"; // your input string
        var a = hms.split(":"); // split it at the colons
        // minutes are worth 60 seconds. Hours are worth 60 minutes.
        var time_window_start = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
        this.timeOfDay.time_window_start = time_window_start;
        // console.log(this.timeOfDay.time_window_start);
      }
    },
    getStopTimeInSecond() {
      if (this.toTime) {
        var hms = this.toTime + ":00"; // your input string
        var a = hms.split(":"); // split it at the colons
        // minutes are worth 60 seconds. Hours are worth 60 minutes.
        var time_window_stop = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
        this.timeOfDay.time_window_stop = time_window_stop;
        // console.log(this.timeOfDay.time_window_stop);
      }
    },
    async saveNewTimeOfDay() {
      if (this.$refs.form.validate()) {
        var allDay = this.timeOfDay.allDay ? this.timeOfDay.allDay : "";
        if (this.weekDays.length != 0) {
          if (this.date) {
            var currentTime = new Date().toISOString().substr(10);
            var myDate = this.date + currentTime;
            var newDate = new Date(myDate);
            //set timestamp of start date
            var getstartDate =
              new Date(
                newDate.getFullYear(),
                newDate.getMonth(),
                newDate.getDate(),
                0,
                0,
                0,
                0
              ) /
                1000 +
              62167219200;
            this.timeOfDay.start_date = getstartDate;
            // console.log(this.timeOfDay.start_date);
          }
          if (this.timeOfDay.cycle == "weekly") {
            this.getWeekDay();
          }
          if (allDay) {
            this.timeOfDay.time_window_start = 0;
            this.timeOfDay.time_window_stop = 86400;
          } else {
            this.getStartTimeInSecond();
            this.getStopTimeInSecond();
          }
          if (
            this.timeOfDay.enabled == "" ||
            this.timeOfDay.enabled == null ||
            this.timeOfDay.enabled == undefined
          ) {
            delete this.timeOfDay.enabled;
          }
          var timeOfDayObj = {
            accountId: this.userDetail.accountId,
            authToken: this.authToken,
            todId: this.timeOfDay.id,
            editData: {
              data: this.timeOfDay,
            },
          };
          this.saveLoader = true;
          try {
            let response = await callFlow.editTODDetails(timeOfDayObj);
            this.saveLoader = false;
            this.closeEditNewTimeOfDayDialog();
            this.$root.$emit("SHOW_SNACKBAR", {
              text: response.responseMessage,
              color: "success",
            });
          } catch (error) {
            this.saveLoader = false;
            this.$root.$emit("SHOW_SNACKBAR", {
              text: "something wrong",
              color: "error",
            });
          }
        } else {
          this.$root.$emit("SHOW_SNACKBAR", {
            text: "Please select at least one day of the week",
            color: "error",
          });
        }
      }
    },
    async deleteTODDetails() {
      var timeOfDayObj = {
        accountId: this.userDetail.accountId,
        authToken: this.authToken,
        todId: this.timeOfDay.id,
      };
      this.deleteLoader = true;
      try {
        let response = await callFlow.deleteTODDetails(timeOfDayObj);
        this.deleteLoader = false;
        this.closeDeleteTimeOfDayDialog();
        this.$root.$emit("SHOW_SNACKBAR", {
          text: response.responseMessage,
          color: "success",
        });
      } catch (error) {
        this.deleteLoader = false;
        this.$root.$emit("SHOW_SNACKBAR", {
          text: "something wrong",
          color: "error",
        });
      }
    },
    closeDeleteTimeOfDayDialog() {
      this.$emit("close-delete-editNewTimeOfDay");
      this.saveLoader = false;
    },
    closeEditNewTimeOfDayDialog() {
      this.$emit("close-editNewTimeOfDay");
      this.saveLoader = false;
    },
    setWeekandDayOption() {
      if (this.timeOfDay.ordinal == "every") {
        // this.showDayOption = true;
        // this.showWeekOption = false;
        this.wdays = null;
      } else {
        // this.showDayOption = false;
        // this.showWeekOption = true;
        this.wdays = null;
      }
    },
  },
};
</script>
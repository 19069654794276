<template>
  <v-card :loading="getNumberCardLoader">
    <v-card-title class="title">
      Add Number
      <v-icon @click="closeNumberDialog" class="ml-auto">cancel</v-icon>
    </v-card-title>
    <v-divider></v-divider>
    <!-- {{subscriptionNewList}} -->
    <v-card-text class="pt-5">
      <!-- <v-text-field
        label="Type of number"
        v-model="selectCallFlowType"
        outlined
        color="primary"
        readonly
      >
      </v-text-field> -->

      <v-form ref="form" v-model="isFormValid" lazy-validation>
        <v-select
          label="Type of number"
          :items="editCallFlow"
          item-text="title"
          item-vlaue="value"
          v-model="selectCallFlowType"
          @change="selectedSubscriptionNumber = ''"
          outlined
          color="primary"
        ></v-select>
        <v-select
          label="Number list"
          :items="subscriptionNewList"
          item-text="didNumber"
          item-vlaue="didNumber"
          v-model="selectedSubscriptionNumber"
          :rules="subscriptionNumberRules"
          outlined
          color="primary"
          v-if="selectCallFlowType == 'spareNumber'"
        ></v-select>
        <v-text-field
          label="Extension"
          v-model="selectedSubscriptionNumber"
          type="number"
          outlined
          color="primary"
          placeholder="2001"
          :rules="extensionNumberRules"
          counter="5"
          v-if="selectCallFlowType == 'extension'"
        >
        </v-text-field>
        <v-btn
          :disabled="subscriptionNewList ? false : true"
          block
          color="primary"
          outlined
          large
          @click="addSubscriptionNumber()"
          >Add number
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>


<script>
// import callFlow from "@/services/callFlowAPI.js";

export default {
  props: ["subscriptionNewList", "getNumberCardLoader","selectedNumbers"],
  data() {
    return {
      editCallFlow: [
        {
          title: "Available Spare Numbers",
          value: "spareNumber",
        },
        {
          title: "Extension",
          value: "extension",
        },
      ],
      selectCallFlowType: "spareNumber",
      selectedSubscriptionNumber: "",
      userDetail: {},
      isFormValid: true,
      typeNumberRules: [(v) => !!v || "This fields is required"],
      subscriptionNumberRules: [(v) => !!v || "This fields is required"],
      extensionNumberRules: [
        (v) => (v && v.length <= 5) || "This field is required and you can enter max 5 digits.",
      ],
    };
  },
  mounted() {
    this.userDetail = JSON.parse(localStorage.getItem("userDetail"));
    // this.selectCallFlowType = "";
  },
  methods: {
    addSubscriptionNumber() {
      if (this.$refs.form.validate()) {
        var checkAlreadySelectedData = this.checkItem(
          this.selectedNumbers,
          this.selectedSubscriptionNumber
        );
        if (checkAlreadySelectedData == false) {
          this.selectedNumbers.push(this.selectedSubscriptionNumber);
          this.$emit(
            "add-Selected-Numbers",
            this.selectedNumbers,
            this.selectedSubscriptionNumber
          );
          this.closeNumberDialog();
        } else {
          this.$root.$emit("SHOW_SNACKBAR", {
            text: " Already Selected!",
            color: "error",
          });
        }
      }
    },
    closeNumberDialog() {
      this.$emit("close-number-Dialog");
      this.selectedSubscriptionNumber = "";
      (this.selectCallFlowType = "spareNumber"),
        this.$refs.form.reset();
    },
    checkItem(arrayItem, searchItem) {
      return (
        arrayItem.findIndex((element) => element.includes(searchItem)) >= 0
      );
    },
  },
};
</script>
<template>
  <section>
    <v-card :loading="cardLoading">
      <v-card-title class="title">
        User
        <v-icon @click="closeUserDialog" class="ml-auto">cancel</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <h3 class="pt-3 pb-5">Connect a caller to a user...</h3>
        <v-select
          label="Which User?"
          :items="usersList"
          item-text="name"
          item-value="id"
          v-model="editedItem.data.id"
          outlined
          color="primary"
        >
          <template slot="item" slot-scope="data">
            <v-list-item-content @click="getItemText(data.item.name)">
              <v-list-item-title v-html="data.item.name"></v-list-item-title>
            </v-list-item-content>
          </template>
        </v-select>
        <v-text-field
          hint="Ring this user for 20 seconds."
          outlined
          v-model="editedItem.data.timeout"
          color="primary"
          persistent-hint
        ></v-text-field>
        <v-checkbox
          label="Allow this user to call itself."
          v-model="editedItem.data.can_call_self"
        ></v-checkbox>
        <p class="text-center">
          <small
            >When you check this box, calls from the user's own extension to
            themselves will not ring the extension. This allows them to dial
            their own number to get to voicemail.</small
          >
        </p>
        <v-row>
          <v-col xs="6">
            <v-btn
              block
              color="green"
              outlined
              :disabled="disabledEditButton"
              @click="editParticularUserDialog()"
            >
              Edit user</v-btn
            >
          </v-col>
          <v-col xs="6">
            <v-btn block color="info" outlined @click="openAddNewUserDialog">
              Add new user
            </v-btn>
          </v-col>
        </v-row>
        <v-btn block color="primary" outlined large class="2" @click="save()"
          >Save
        </v-btn>
      </v-card-text>
    </v-card>

    <!-- Add new user Dialog -->
    <add-new-user-dialog
      :addNewUserDialog="addNewUserDialog"
      :usersList="usersList"
      @close-new-user-dialog="closeAddNewUserDialog"
    />

    <!-- Edit user Dialog -->
    <v-dialog v-model="editUserDialog" width="800" persistent>
      <v-card>
        <v-form ref="form" v-model="isFormValid" lazy-validation>
          <v-card-title class="title">
            Edit User
            <v-icon @click="editUserDialog = false" class="ml-auto"
              >cancel</v-icon
            >
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pb-0">
            <v-tabs right v-model="editTabs" class="pb-4">
              <v-tab> Basic</v-tab>
              <v-tab> Advanced</v-tab>
            </v-tabs>
            <v-tabs-items v-model="editTabs">
              <v-tab-item>
                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <v-text-field
                      label="First Name"
                      v-model="editUser.first_name"
                      color="primary"
                      :rules="firstNameRules"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <v-text-field
                      label="Last Name"
                      v-model="editUser.last_name"
                      :rules="lastNameRules"
                      color="primary"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <v-text-field
                      label="Email"
                      v-model="editUser.email"
                      :disabled="userAccountId === editUser.id"
                      :rules="emailRules"
                      color="primary"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <v-text-field
                      label="User privilege level"
                      v-model="editUser.priv_level"
                      disabled
                      outlined
                      color="primary"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <v-checkbox
                      class="pt-0 mt-0"
                      label="Email Notifications"
                      v-model="editUser.vm_to_email_enabled"
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <!-- user-devices table -->
                <user-devices-table
                  :devices="devices"
                  :usersList="usersList"
                  :loadingTable="loadingTable"
                  :userId="editedItem.data.id"
                  @get-user-devices="getUserDevices"
                />
              </v-tab-item>
              <v-tab-item>
                <p><b>Standard Options</b></p>
                <v-select
                  label="Time zone"
                  :items="countriesList"
                  v-model="editUser.timezone"
                  outlined
                  clearable
                  color="primary"
                >
                </v-select>
                <v-select
                  label="Music on Hold"
                  :items="mediaList"
                  v-model="editUser.music_on_hold.media_id"
                  hint="Select the media file that you want to be played by default when it 's not set on a user or a device."
                  persistent-hint
                  item-text="name"
                  item-value="id"
                  outlined
                  color="primary"
                  clearable
                >
                </v-select>
                <v-row class="text-right mx-right">
                  <v-col cols="12">
                    <v-btn
                      text
                      class="primary-btn"
                      @click="openNewAddMediaDialog"
                      >Add a media</v-btn
                    >
                  </v-col>
                </v-row>
                <!-- <v-checkbox
                class="pt-0 mt-0"
                label="User has been verified"
                v-model="editUser.verified"
              ></v-checkbox>
              <v-checkbox
                class="pt-0 mt-0"
                label="Hide from Contact List"
                v-model="editUser.contact_list.exclude"
              ></v-checkbox> -->
                <p><b>Queue Options</b></p>
                <v-checkbox
                  class="pt-0 mt-0"
                  label="Record this agent(user)"
                  disabled
                  v-model="editUser.record_call"
                ></v-checkbox>
              </v-tab-item>
            </v-tabs-items>
            <!--Action buttons -->
            <v-card-actions class="pb-4 pt-5">
              <v-spacer></v-spacer>
              <!-- <v-btn
              color="error"
              outlined
              large
              class="px-5"
              @click="deleteUser()"
              :loading="deleteLoader"
              :disabled="userAccountId === editUser.email"
              >Delete
            </v-btn> -->
              <v-btn
                color="error"
                outlined
                large
                class="px-5"
                @click="editUserDialog = false"
                :loading="deleteLoader"
                >Cancel
              </v-btn>
              <v-btn
                color="primary"
                outlined
                large
                class="px-5"
                :loading="editLoader"
                @click="saveEditUser()"
                >Save
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- Add media Dialog -->
    <add-new-media
      :addNewMediaDialogBySelect="addNewMediaDialogBySelect"
      @close-media="closeNewMedia"
    />
  </section>
</template>

<script>
import callFlow from "@/services/callFlowAPI.js";
import getCountriesList from "./jsFile/countryList";
import addNewMedia from "./addNewMedia.vue";
import addNewUserDialog from "./addNewUserDialog";
import userDevicesTable from "./userDevicesTable";

export default {
  props: ["editedItem", "selectedDragList", "editedIndex"],
  components: {
    addNewMedia,
    addNewUserDialog,
    userDevicesTable,
  },
  data() {
    return {
      usersList: [],
      devices: [],
      defaultUserCallflow: {},
      addNewUserDialog: false,
      editUserDialog: false,
      editUser: {
        music_on_hold: {},
        contact_list: {
          exclude: false,
        },
      },
      authToken: "",
      selectedValue: "",
      getSelectedUserId: "",
      editTabs: null,
      countriesList: [],
      mediaList: [],
      userLoader: false,
      cardLoading: false,
      disabledEditButton: false,
      editLoader: false,
      deleteLoader: false,
      userAccountId: "",
      editedItemModal: false,
      addNewMediaDialogBySelect: false,
      loadingTable: false,
      byDefualtAddData: true,
      firstNameRules: [(v) => !!v || "first name is required"],
      lastNameRules: [(v) => !!v || "Last name is required"],
      emailRules: [
        (v) =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "this value should be a valid email",
      ],
      isFormValid: true,
    };
  },
  mounted() {
    this.countriesList = getCountriesList.countryList;
    this.userDetail = JSON.parse(localStorage.getItem("userDetail"));
    this.authToken = localStorage.getItem("authToken");
    this.userAccountId = this.userDetail.userId;
    this.getUsersList();
    this.getMediaList();
    // console.log("editedItem", this.editedItem);
  },
  methods: {
    async getUsersList() {
      this.cardLoading = true;
      var getUsersData = {
        accountId: this.userDetail.accountId,
        userId: this.userDetail.userId,
        forwardType: "SOFTPHONE",
      };
      this.usersList = [];
      try {
        let response = await callFlow.getUsersList(getUsersData);
        var userData = {};
        for (let index = 0; index < response.usersList.length; index++) {
          const element = response.usersList[index];
          element.name = element.first_name + " " + element.last_name;
          userData = element;
          this.usersList.push(userData);
        }
        if (this.usersList.length != 0) {
          if (this.editUser.id) {
            this.editedItem.data.id = this.editUser.id;
            this.editedItem.data.name =
              this.editUser.first_name + " " + this.editUser.last_name;
            this.selectedValue = this.editedItem.data.name;
            this.byDefualtAddData = false;
          } else {
            if (this.editedItem.data.id) {
              this.editedItemModal = true;
              for (let index = 0; index < this.usersList.length; index++) {
                const usersList = this.usersList[index];
                if (usersList.id === this.editedItem.data.id) {
                  this.editedItem.data.id = usersList.id;
                  this.editedItem.data.name =
                    usersList.first_name + " " + usersList.last_name;
                  this.selectedValue = this.editedItem.data.name;
                  this.byDefualtAddData = false;
                }
              }
            } else {
              this.editedItem.data.id = this.usersList[0].id;
              this.editedItem.data.name = this.usersList[0].name;
              this.selectedValue = this.usersList[0].name;
              this.byDefualtAddData = true;
            }
          }
          this.disabledEditButton = false;
        } else {
          this.disabledEditButton = true;
        }
        this.cardLoading = false;
      } catch (error) {
        this.cardLoading = false;
        console.log("====error===", error);
      }
    },
    openNewAddMediaDialog() {
      this.addNewMediaDialogBySelect = true;
    },
    closeAddNewUserDialog(userId) {
      if (userId) {
        this.editedItem.data.id = userId;
        this.editParticularUserDialog();
      }
      this.addNewUserDialog = false;
      this.getUsersList();
    },
    async getMediaList() {
      var getUsersData = {
        accountId: this.userDetail.accountId,
      };
      try {
        let response = await callFlow.getMediaList(getUsersData);
        this.mediaList = response.mediaList;
      } catch (error) {
        console.log("====error===", error);
      }
    },
    getItemText(selectText) {
      // get the selected module name
      this.selectedValue = selectText;
    },
    openAddNewUserDialog() {
      this.addNewUserDialog = true;
    },
    save() {
      this.byDefualtAddData = false;
      // get the selected module properties from editedItem obj
      var editedItem = {};
      editedItem.data = {
        name: this.selectedValue,
        timeout: this.editedItem.data.timeout,
        can_call_self: this.editedItem.data.can_call_self,
        icon: this.editedItem.data.icon,
        id: this.editedItem.data.id,
      };
      this.editedItem.data = editedItem.data;
      this.editedItemModal = true;
      //storing the selected data into call flow API on didnpbx page
      this.$emit(
        "save-user-data",
        this.selectedDragList,
        this.editedItem,
        this.editedIndex
      );
      this.closeOnSave();
    },
    closeOnSave() {
      this.$emit("close-on-save-dialog");
    },
    closeUserDialog() {
      if (this.byDefualtAddData) {
        this.editedItem.data.id = "";
        this.editedItem.data.name = "";
        this.selectedValue = "";
      }
      this.closeOnSave();
    },

    async saveEditUser() {
      if (this.$refs.form.validate()) {
        this.editLoader = true;
        var editUserData = {
          accountId: this.userDetail.accountId,
          authToken: this.authToken,
          usersId: this.editedItem.data.id,
          editData: {
            data: {
              apps: {},
              call_forward: {
                substitute: false,
                enabled: false,
                failover: false,
                require_keypress: false,
                keep_caller_id: false,
                direct_calls_only: false,
                ignore_early_media: true,
              },
              call_restriction: {
                closed_groups: {
                  action: "inherit",
                },
                tollfree_us: {
                  action: "inherit",
                },
                toll_us: {
                  action: "inherit",
                },
                emergency: {
                  action: "inherit",
                },
                caribbean: {
                  action: "inherit",
                },
                did_us: {
                  action: "inherit",
                },
                international: {
                  action: "inherit",
                },
                unknown: {
                  action: "inherit",
                },
              },
              caller_id: {
                internal: {
                  name:
                    this.editUser.first_name + " " + this.editUser.last_name,
                },
                external: {
                  name:
                    this.editUser.first_name + " " + this.editUser.last_name,
                },
              },
              hotdesk: {
                enabled: false,
                id: "",
                require_pin: false,
                keep_logged_in_elsewhere: false,
              },
              contact_list: { exclude: this.editUser.contact_list.exclude },
              timezone: this.editUser.timezone,
              priv_level: this.editUser.priv_level,
              music_on_hold: { media_id: this.editUser.music_on_hold.media_id },
              username: this.editUser.username,
              first_name: this.editUser.first_name,
              last_name: this.editUser.last_name,
              email: this.editUser.email,
              vm_to_email_enabled: this.editUser.vm_to_email_enabled,
              verified: this.editUser.verified,
              record_call: this.editUser.record_call,
              ui_metadata: {
                version: "4.3-139",
                ui: "monster-ui",
                origin: "callflows",
              },
              enabled: true,
              require_password_update: false,
              ringtones: {},
              id: this.editedItem.data.id,
            },
          },
        };
        try {
          let response = await callFlow.editUsersDetails(editUserData);
          this.getUsersList();
          this.editUserDialog = false;
          this.editLoader = false;
          this.$root.$emit("SHOW_SNACKBAR", {
            text: response.messageDetail,
            color: "success",
          });
        } catch (error) {
          this.editLoader = false;
          this.$root.$emit("SHOW_SNACKBAR", {
            text: "something wrong",
            color: "error",
          });
        }
      }
    },
    async editParticularUserDialog() {
      this.getSelectedUserId = this.editedItem.data.id;
      this.cardLoading = true;
      var editUserData = {
        accountId: this.userDetail.accountId,
        authToken: this.authToken,
        usersId: this.editedItem.data.id,
      };
      try {
        let response = await callFlow.getUsersDetails(editUserData);
        this.editUser = response.usersDetails;
        this.editUserDialog = true;
        this.cardLoading = false;
        this.getUserDevices();
      } catch (error) {
        console.log("====error===", error);
        this.cardLoading = false;
      }
    },
    async getUserDevices() {
      this.loadingTable = true;
      var getUserDevicesData = {
        accountId: this.userDetail.accountId,
        userId: this.getSelectedUserId,
      };
      try {
        let response = await callFlow.getUserDevices(getUserDevicesData);
        this.devices = response.userDevices;
        this.loadingTable = false;
      } catch (error) {
        this.loader = false;
        this.loadingTable = false;
        console.log("====error===", error);
      }
    },
    // async deleteUser() {
    //   this.deleteLoader = true;
    //   var delteUserData = {
    //     accountId: this.userDetail.accountId,
    //     authToken: this.authToken,
    //     usersId: this.editedItem.data.id,
    //   };
    //   try {
    //     let response = await callFlow.deleteUsersDetails(delteUserData);
    //     this.editUser = {
    //       music_on_hold: {},
    //       contact_list: {
    //         exclude: false,
    //       },
    //     };
    //     this.editedItem.data.id = "";
    //     this.getUsersList();
    //     this.editUserDialog = false;
    //     this.$root.$emit("SHOW_SNACKBAR", {
    //       text: response.messageDetail,
    //       color: "success",
    //     });
    //     this.deleteLoader = false;
    //   } catch (error) {
    //     this.deleteLoader = false;
    //     this.$root.$emit("SHOW_SNACKBAR", {
    //       text: "something wrong",
    //       color: "error",
    //     });
    //   }
    // },
    closeNewMedia() {
      this.addNewMediaDialogBySelect = false;
      this.getMediaList();
    },
  },
};
</script>



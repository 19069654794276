<template>
  <section>
    <v-card :loading="loader">
      <v-card-title class="title">
        Time of Day
        <v-icon @click="closeOnSave" class="ml-auto">cancel</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="form" v-model="isFormValid" lazy-validation>
          <p>Select Time of day</p>
          <!-- {{ getOptionKey }} -->
          <v-select
            label="Timezone"
            :items="timeOfDayList"
            v-model="getOptionKey.value"
            item-text="name"
            item-value="id"
            outlined
            color="primary"
            :rules="formatRules"
            class="mb-1"
            @change="getTimeOfDay(getOptionKey.value)"
            return-object
            hide-details
          ></v-select>
          <v-row>
            <v-col xs="6" sm="6">
              <v-btn
                block
                color="green"
                outlined
                v-if="showEditTOD"
                @click="editParticularTimeOfDayDialog()"
              >
                Edit Time Of Day</v-btn
              >
            </v-col>
            <v-col xs="6" sm="6">
              <v-btn
                block
                color="info"
                outlined
                @click="openAddTimeOfDayDialog"
              >
                Add Time Of Day
              </v-btn>
            </v-col>
          </v-row>
          <v-btn block color="primary" outlined large @click.prevent="save()"
            >Save
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
    <v-dialog v-model="addNewTimeOfDay" width="800px">
      <addNewTimeOfDayDialog @close-newTimeOfDay="closeNewTimeOfDayDialog" />
    </v-dialog>
    <v-dialog v-model="editNewTimeOfDay" width="800px">
      <edit-new-time-of-day-dialog
        :timeOfDay="todDetails"
        :weekDays="weekDays"
        :date="date"
        :fromTime="fromTime"
        :toTime="toTime"
        :wdays="wdays"
        @close-editNewTimeOfDay="closeEditNewTimeOfDay"
        @close-delete-editNewTimeOfDay="closedDeleteEditNewTimeOfDay"
      />
    </v-dialog>
  </section>
</template>

<script>
// import getTimeZoneCuntryList from "./timeZoneCuntryList";
import callFlow from "@/services/callFlowAPI.js";
import addNewTimeOfDayDialog from "./addNewTimeOfDayDialog";
import EditNewTimeOfDayDialog from "./editNewTimeOfDayDialog.vue";
// import moment from "moment";

export default {
  props: [
    "editedItem",
    "selectedDragList",
    "editedIndex",
    "getOptionKey",
    "optionModule",
    "selectedStaticKey",
    "getSelectedOptionKeys",
  ],
  components: {
    addNewTimeOfDayDialog,
    EditNewTimeOfDayDialog,
  },
  data() {
    return {
      formatRules: [(v) => !!v || "Fields is required"],
      userDetail: {},
      loader: false,
      isFormValid: true,
      saveLoader: false,
      // editedItemModal: false,
      authToken: "",
      todId: "",
      selectedValue: "",
      addNewTimeOfDay: false,
      editNewTimeOfDay: false,
      todDetails: {},
      weekDays: [],
      date: null,
      fromTime: null,
      toTime: null,
      wdays: null,
      showEditTOD: false,
      selectedOptionModule: {},
      timeOfDayList: [],
    };
  },
  mounted() {
    this.userDetail = JSON.parse(localStorage.getItem("userDetail"));
    this.authToken = localStorage.getItem("authToken");
    this.getTODList();
  },
  methods: {
    // getTODList() {
    //   this.$emit("get-tod-list");
    // },
    closeAddNewTimeOfDayDialog() {
      this.addNewTimeOfDay = false;
    },
    getTimeOfDay(getSelectedOptionKey) {
      this.selectedOptionModule = {};

      if (getSelectedOptionKey.id == "_") {
        this.showEditTOD = false;
      } else {
        this.showEditTOD = true;
      }

      if (Object.keys(this.editedItem).length != 1) {
        delete Object.assign(this.editedItem, {
          [getSelectedOptionKey.id]: this.editedItem[this.selectedStaticKey],
        })[this.selectedStaticKey];
        this.selectedOptionModule = this.editedItem;
      } else {
        for (const key in this.editedItem) {
          if (this.editedItem.hasOwnProperty.call(this.editedItem, key)) {
            const element = this.editedItem[key];
            this.selectedOptionModule[getSelectedOptionKey.id] = element;
          }
        }
      }
    },
    async save() {
      let metaData = {};
      if (localStorage.getItem("metaData")) {
        metaData = JSON.parse(localStorage.getItem("metaData"));
      }
      metaData[this.getOptionKey.value.id] = {
        name: this.getOptionKey.value.name,
      };
      localStorage.setItem("metaData", JSON.stringify(metaData));

      // if user not selected any value
      if (this.getOptionKey.value == "_") {
        this.selectedOptionModule = {};
        if (Object.keys(this.editedItem).length != 1) {
          delete Object.assign(this.editedItem, {
            [this.getOptionKey.value]: this.editedItem[this.selectedStaticKey],
          })[this.selectedStaticKey];
          this.selectedOptionModule = this.editedItem;
        } else {
          for (const key in this.editedItem) {
            if (this.editedItem.hasOwnProperty.call(this.editedItem, key)) {
              const element = this.editedItem[key];
              this.selectedOptionModule[this.getOptionKey.value] = element;
            }
          }
        }
        this.saveOptionData();
      }

      if (this.getOptionKey.value == this.selectedStaticKey) {
        this.closeOnSave();
      } else {
        this.saveOptionData();
      }
    },

    async saveOptionData() {
      if (this.optionModule) {
        this.selectedDragList[this.editedIndex] = this.selectedOptionModule;
      } else {
        this.$set(
          this.selectedDragList,
          this.editedIndex,
          this.selectedOptionModule
        );
      }

      // storing the selected data into call flow API on didnpbx page
      await this.$emit(
        "save-timeOfDay-data",
        this.selectedDragList,
        this.editedItem,
        this.editedIndex
      );
      this.closeOnSave();
    },
    closeOnSave() {
      this.$emit("close-timeOfDay-dialog");
    },
    closeNewTimeOfDayDialog(todId) {
      this.addNewTimeOfDay = false;
      this.getTODList();
      if (todId) {
        this.todId = todId;
        //   console.log("todId", todId);
        // this.getOptionKey.value.id = "";
        // this.getOptionKey.value.id = todId;
      }
    },
    async editParticularTimeOfDayDialog() {
      var getTODData = {
        accountId: this.userDetail.accountId,
        todId: this.getOptionKey.value.id,
      };
      try {
        let response = await callFlow.getTODDetails(getTODData);
        this.weekDays = [];
        if (
          response.todDetails &&
          response.todDetails.wdays &&
          response.todDetails.wdays.length != 0
        ) {
          for (
            let index = 0;
            index < response.todDetails.wdays.length;
            index++
          ) {
            const element = response.todDetails.wdays[index];
            if (element == "sunday") {
              this.weekDays.push(0);
            } else if (element == "monday") {
              this.weekDays.push(1);
            } else if (element == "tuesday") {
              this.weekDays.push(2);
            } else if (element == "wensday") {
              this.weekDays.push(3);
            } else if (element == "thursday") {
              this.weekDays.push(4);
            } else if (element == "friday") {
              this.weekDays.push(5);
            } else if (element == "saturday") {
              this.weekDays.push(6);
            }
          }
        }
        if (response.todDetails.ordinal) {
          if (response.todDetails.wdays) {
            this.wdays = response.todDetails.wdays[0];
          } else if (response.todDetails.days) {
            this.wdays = response.todDetails.days[0];
            this.weekDays = this.wdays;
          }
        }
        // convert timestamp to date
        var timestamp = response.todDetails.start_date;
        var calculateDate = Number(timestamp) - 62167219200;
        var unixTimestamp = new Date(calculateDate);
        var date = new Date(unixTimestamp * 1000);
        var myDate = date.toISOString().substr(0, 10);
        this.date = myDate;
        if (
          response.todDetails.time_window_start == "0" ||
          response.todDetails.time_window_stop == "86400"
        ) {
          response.todDetails.allDay = true;
        } else {
          //  convert second to time
          var startDate = new Date(0);
          startDate.setSeconds(response.todDetails.time_window_start); // specify value for SECONDS here
          var time_window_start = startDate.toISOString().substr(12, 4);
          this.fromTime = time_window_start;

          var stopDate = new Date(0);
          stopDate.setSeconds(response.todDetails.time_window_stop); // specify value for SECONDS here
          var time_window_stop = stopDate.toISOString().substr(12, 4);
          this.toTime = time_window_stop;
        }
        if (!response.todDetails.enabled) {
          response.todDetails.enabled = "";
        }
        this.todDetails = response.todDetails;
        this.editNewTimeOfDay = true;
      } catch (error) {
        this.$root.$emit("SHOW_SNACKBAR", {
          text: "Some thing wrong!",
          color: "error",
        });
        console.log("====error===", error);
      }
    },
    openAddTimeOfDayDialog() {
      this.addNewTimeOfDay = true;
    },
    closeEditNewTimeOfDay() {
      this.editNewTimeOfDay = false;
      this.getTODList();
    },
    closedDeleteEditNewTimeOfDay() {
      this.editNewTimeOfDay = false;
      this.getOptionKey.value.id = "_";
      this.getTODList();
    },
    async getTODList() {
      this.timeOfDayList = [{ name: "All other times", id: "_" }];
      this.loader = true;
      var getTODData = {
        accountId: this.userDetail.accountId,
      };
      let defaultValue = {
        name: "All other times",
        id: "_",
      };
      try {
        let response = await callFlow.getTODList(getTODData);
        for (let index = 0; index < response.todList.length; index++) {
          const element = response.todList[index];
          this.timeOfDayList.push(defaultValue, element);
        }
        // console.log("this.getSelectedOptionKeys", this.getSelectedOptionKeys);
        // this.timeOfDayList = this.timeOfDayList.filter(
        //   (val) => !this.getSelectedOptionKeys.includes(val.id)
        // );
        // console.log("this.timeOfDayList", this.timeOfDayList);
        // routeOptionList.push(this.selectedOptionKey);

        if (this.todId) {
          // this.getOptionKey.value.id = "";
          this.getOptionKey.value.id = this.todId;
        }
        this.loader = false;
      } catch (error) {
        this.loader = false;
        console.log("====error===", error);
      }
    },
  },
};
</script>
<template>
  <!-- Edit device Dialog -->
  <v-dialog
    v-model="editCreatedDeviceDialog"
    :width="editDevice.device_type == 'cellphone' ? '400' : '800'"
    persistent
  >
    <v-card>
      <v-card-title class="title">
        Edit Device
        <v-icon @click="closeEditDeviceDialog()" class="ml-auto">cancel</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-form ref="form" v-model="formValid">
        <v-card-text v-if="editDevice.device_type == 'cellphone'" class="pb-0">
          <v-text-field
            class="pt-5"
            label="Name"
            v-model="editDevice.name"
            :rules="nameRules"
            color="primary"
            outlined
          ></v-text-field>
          <v-text-field
            label="Number"
            v-model="editDevice.number"
            type="number"
            maxlength="15"
            counter
            color="primary"
            outlined
          ></v-text-field>
          <v-text-field
            label="URL"
            v-model="editDevice.sip.route"
            color="primary"
            placeholder="sip.support@company.com"
            v-if="editDevice.device_type === 'sip_uri'"
            outlined
          ></v-text-field>
          <v-select
            label="Belongs To"
            :items="usersList"
            item-text="name"
            item-value="id"
            v-model="editDevice.owner_id"
            outlined
            color="primary"
            clearable
          >
          </v-select>
          <v-row class="text-right mx-right">
            <v-col cols="12">
              <v-btn text class="primary-btn" @click="openAddNewUserDialog"
                >Add a user</v-btn
              >
            </v-col>
          </v-row>
          <v-checkbox
            class="pt-0 mt-0"
            label="Enabled"
            v-model="editDevice.enabled"
          ></v-checkbox>
        </v-card-text>
        <v-card-text v-else class="pb-0">
          <v-row>
            <v-col cols="12" sm="6">
              <p class="pl-1"><b>Basic:</b></p>
              <v-text-field
                label="Name"
                :rules="nameRules"
                v-model="editDevice.name"
                hint="Friendly name for this device"
                color="primary"
                outlined
              ></v-text-field>
              <v-col
                cols="12"
                class="pa-0"
                v-if="editDevice.sip && editDevice.sip.username"
              >
                <v-row class="align-center">
                  <v-col cols="10" class="py-0 pr-0">
                    <v-text-field
                      label="Username"
                      v-if="editDevice.sip"
                      v-model="editDevice.sip.username"
                      hint="Username"
                      hide-details
                      outlined
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2" class="pa-0">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="copied"
                          v-clipboard:copy="editDevice.sip.username"
                          icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon class="mt-2 pl-2"> content_copy </v-icon>
                        </v-btn>
                      </template>
                      <span>Copy</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-col>
              <v-select
                class="pt-5"
                label="Media"
                :items="mediaList"
                item-text="name"
                item-value="id"
                v-if="editDevice.music_on_hold"
                v-model="editDevice.music_on_hold.media_id"
                outlined
                color="primary"
                hide-details
                clearable
              >
              </v-select>
              <v-row class="text-right mx-right">
                <v-col cols="12">
                  <v-btn text class="primary-btn" @click="openNewAddMediaDialog"
                    >Add a media</v-btn
                  >
                </v-col>
              </v-row>
              <v-col cols="12" class="pa-0">
                <v-row class="align-center">
                  <v-col cols="10" class="py-0 pr-0">
                    <v-text-field
                      label="Realm//Sip Address"
                      v-model="userDetail.realm"
                      color="primary"
                      hide-details
                      outlined
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2" class="pa-0">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="copied"
                          v-clipboard:copy="userDetail.realm"
                          icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon class="mt-2 pl-2"> content_copy </v-icon>
                        </v-btn>
                      </template>
                      <span>Copy</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-col>
              <v-checkbox
                class="pt-5 mt-0"
                label="Enabled"
                v-model="editDevice.enabled"
              ></v-checkbox>
            </v-col>
            <v-col sm="6">
              <p class="pl-1"><b>Enable outbound calls:</b></p>
              <v-select
                label="caller id number"
                :items="subscriptionNewList"
                item-text="didNumber"
                item-value="didNumber"
                v-model="editDevice.number"
                outlined
                color="primary"
                clearable
              >
              </v-select>
              <v-col
                cols="12"
                class="pa-0"
                v-if="editDevice.sip && editDevice.sip.password"
              >
                <v-row class="align-center">
                  <v-col cols="10" class="py-0 pr-0">
                    <v-text-field
                      label="Password"
                      v-model="editDevice.sip.password"
                      v-if="editDevice.sip"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPassword ? 'text' : 'password'"
                      @click:append="showPassword = !showPassword"
                      hint="Password"
                      color="primary"
                      hide-details
                      outlined
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2" class="pa-0">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="copied"
                          v-clipboard:copy="editDevice.sip.password"
                          icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon class="mt-2 pl-2"> content_copy </v-icon>
                        </v-btn>
                      </template>
                      <span>Copy</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-col>
              <v-select
                class="pt-5"
                label="Belongs To"
                :items="usersList"
                item-text="name"
                item-value="id"
                v-model="editDevice.owner_id"
                outlined
                color="primary"
                clearable
                hide-details
              >
              </v-select>
              <v-row class="text-right mx-right">
                <v-col cols="12">
                  <v-btn text class="primary-btn" @click="openAddNewUserDialog"
                    >Add a user</v-btn
                  >
                </v-col>
              </v-row>
              <v-col cols="12" class="py-0">
                <v-row class="align-center">
                  <p class="pr-1 pt-3"><b>Options:</b></p>
                  <v-checkbox
                    class=""
                    label="T.38"
                    v-model="editDevice.faxOption"
                  ></v-checkbox>
                </v-row>
              </v-col>
            </v-col>
          </v-row>
        </v-card-text>
        <!--Action buttons -->
        <v-col cols="12" xs="12" class="pt-0 px-6">
          <v-row>
            <v-col xs="6">
              <v-btn
                color="error"
                :loading="deleteLoader"
                outlined
                large
                @click="deleteDevice()"
                block
                >Delete
              </v-btn>
            </v-col>
            <v-col xs="6">
              <v-btn
                color="primary"
                outlined
                block
                large
                class="px-5"
                :loading="editLoading"
                @click="saveEditDevice()"
                >Save
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-form>
    </v-card>
    <!-- Add media Dialog -->
    <add-new-media
      :addNewMediaDialogBySelect="addNewMediaDialogBySelect"
      @close-media="closeNewMedia"
    />
    <!-- Add new user Dialog -->
    <add-new-user-dialog
      :addNewUserDialog="addNewUserDialog"
      @close-new-user-dialog="closeAddNewUserDialog"
    />
  </v-dialog>
</template>


<script>
import callFlow from "@/services/callFlowAPI.js";
import addNewMedia from "./addNewMedia.vue";
import addNewUserDialog from "./addNewUserDialog";
import numberManagerAPI from "@/services/numberManagerAPI.js";

export default {
  props: ["usersList", "editCreatedDeviceDialog", "editDevice", "editedItem"],
  components: {
    addNewMedia,
    addNewUserDialog,
  },
  data() {
    return {
      deleteLoader: false,
      editLoading: false,
      mediaList: [],
      subscriptionNewList: [],
      addNewMediaDialogBySelect: false,
      addNewUserDialog: false,
      userDetail: {},
      authToken: "",
      showPassword: false,
      formValid: true,
      nameRules: [(v) => !!v || "Name is required"],
    };
  },
  mounted() {
    this.userDetail = JSON.parse(localStorage.getItem("userDetail"));
    this.authToken = localStorage.getItem("authToken");
    this.getMediaList();
    this.getUsersList();
    this.getSubscriptionNew();
  },
  methods: {
    async getSubscriptionNew() {
      var payload = {
        accountId: this.userDetail.accountId,
      };
      try {
        let response = await numberManagerAPI.getDidNumberDetailSubscriptionNew(
          payload
        );
        if (response) {
          var subscriptionNewList = [];
          for (const key in response.spareNumbers.numbers) {
            subscriptionNewList.push({ didNumber: key });
          }
          this.subscriptionNewList = subscriptionNewList;
        }
      } catch (error) {
        console.log("====error===", error);
      }
    },
    async getUsersList() {
      var getUsersData = {
        accountId: this.userDetail.accountId,
        userId: this.userDetail.userId,
        forwardType: "SOFTPHONE",
      };
      try {
        let response = await callFlow.getUsersList(getUsersData);
        // console.log("response ", response);
        var userData = {};
        for (let index = 0; index < response.usersList.length; index++) {
          const element = response.usersList[index];
          if (element.last_name === undefined || element.last_name === null) {
            element.name = element.first_name;
          } else {
            element.name = element.first_name + " " + element.last_name;
          }
          userData = element;
          this.usersList.push(userData);
        }
      } catch (error) {
        console.log("====error===", error);
      }
    },
    async getMediaList() {
      this.mediaData = [];
      var getUsersData = {
        accountId: this.userDetail.accountId,
      };
      try {
        let response = await callFlow.getMediaList(getUsersData);
        this.mediaList.push({ name: "Silence" });
        this.mediaList = response.mediaList;
      } catch (error) {
        console.log("====error===", error);
      }
    },
    async saveEditDevice() {
      if (this.editDevice.call_forward.number != undefined) {
        if (this.editDevice.call_forward.number.length > 15) {
          this.$root.$emit("SHOW_SNACKBAR", {
            text: "Max 15 characters",
            color: "error",
          });
        } else {
          this.submitEditDevice();
        }
      } else {
        this.submitEditDevice();
      }
    },
    async submitEditDevice() {
      if (this.$refs.form.validate()) {
        this.editLoading = true;
        if (this.editDevice.number) {
          if (this.editDevice.device_type == "cellphone") {
            this.editDevice.call_forward.number = this.editDevice.number;
          } else {
            this.editDevice.caller_id = {
              external: {
                number: this.editDevice.number,
                name: this.editDevice.number,
              },
            };
          }
        }
        var editDeviceData = {
          accountId: this.userDetail.accountId,
          authToken: this.authToken,
          deviceId: this.editDevice.id,
          editData: { data: this.editDevice },
        };
        try {
          let response = await callFlow.editDeviceDetails(editDeviceData);
          this.editLoading = false;
          this.closeEditDeviceDialog();
          this.$root.$emit("SHOW_SNACKBAR", {
            text: response.messageDetail,
            color: "success",
          });
        } catch (error) {
          this.editLoading = false;
          this.$root.$emit("SHOW_SNACKBAR", {
            text: "something wrong",
            color: "error",
          });
          console.log("====error===", error);
        }
      }
    },
    async deleteDevice() {
      this.deleteLoader = true;
      var deleteDeviceData = {
        accountId: this.userDetail.accountId,
        authToken: this.authToken,
        deviceId: this.editDevice.id,
      };
      try {
        let response = await callFlow.deleteDeviceDetails(deleteDeviceData);
        this.closeOnDeleteEditDeviceDialog();
        this.deleteLoader = false;
        this.$root.$emit("SHOW_SNACKBAR", {
          text: response.messageDetail,
          color: "success",
        });
      } catch (error) {
        this.deleteLoader = false;
        this.$root.$emit("SHOW_SNACKBAR", {
          text: "something wrong",
          color: "error",
        });
      }
    },
    closeEditDeviceDialog() {
      this.$emit("close-edit-device-dialog");
    },
    closeOnDeleteEditDeviceDialog() {
      this.$emit("close-on-delete-edit-device-dialog");
    },
    closeNewMedia() {
      this.addNewMediaDialogBySelect = false;
      this.getMediaList();
    },
    closeAddNewUserDialog() {
      this.addNewUserDialog = false;
      this.getUsersList();
    },
    openNewAddMediaDialog() {
      this.addNewMediaDialogBySelect = true;
    },
    openAddNewUserDialog() {
      this.addNewUserDialog = true;
      this.$emit("openAddNewUserData", this.addNewUserDialog);
    },
    copied() {
      this.$root.$emit("SHOW_SNACKBAR", {
        text: "Copied",
        color: "success",
      });
    },
  },
};
</script>
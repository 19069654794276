const speechType = [
    "female/en-US",
    "male/en-US",
    "female/en-CA",
    "female/en-AU",
    "female/en-GB",
    "male/en-GB",
    "female/es-US",
    "male/es-US",
    "female/us-US",
    "female/zh-CN",
    "male/zh-CN",
    "female/zh-HK",
    "female/zh-TW",
    "female/ja-JP",
    "male/ja-JP",
    "female/ko-KR",
    "male/ko-KR",
    "female/da-DK",
    "female/de-DE",
    "male/de-DE",
    "female/ca-ES",
    "female/es-ES",
    "male/es-ES",
    "female/fi-FI",
    "female/fr-CA",
    "male/fr-CA",
    "female/fr-FR",
    "male/fr-FR",
    "female/it-IT",
    "male/it-IT",
    "female/nb-NO",
    "female/nl-NL",
    "female/pl-PL",
    "female/pt-BR",
    "female/pt-PT",
    "male/pt-PT",
    "female/ru-RU",
    "male/ru-RU",
    "female/sv-SE",
    "female/hu-HU",
    "female/cs-CZ",
    "female/tr-TR",
    "male/tr-TR",
]

export default { speechType };

<template>
  <section>
    <v-card :loading="loader">
      <v-card-title class="title">
        Menu
        <v-icon @click="closeMenuDialog" class="ml-auto">cancel</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <p>Connect caller to menu...</p>
        <v-select
          label="Which Menu?"
          :items="menuList"
          item-text="name"
          item-value="id"
          v-model="editedItem.data.id"
          outlined
          color="primary"
        >
          <template slot="item" slot-scope="data">
            <v-list-item-content @click="getItemText(data.item.name)">
              <v-list-item-title v-html="data.item.name"></v-list-item-title>
            </v-list-item-content>
          </template>
        </v-select>
        <v-row>
          <v-col cols="12" sm="6">
            <v-btn
              block
              color="green"
              outlined
              large
              :disabled="disabledEditButton"
              @click="editParticularMenuDialog(editedItem)"
            >
              Edit</v-btn
            >
          </v-col>
          <v-col cols="12" sm="6">
            <v-btn
              block
              color="info"
              outlined
              large
              @click="addNewMenuDialog = true"
            >
              Add
            </v-btn>
          </v-col>
        </v-row>
        <v-btn block color="primary" outlined large @click="save()"
          >Save
        </v-btn>
      </v-card-text>
    </v-card>
    <!-- Add menu Dialog -->
    <v-dialog v-model="addNewMenuDialog" width="400">
      <v-card>
        <v-card-title class="title">
          Create Menu
          <v-icon @click="closeAddNewMenuDialog" class="ml-auto">cancel</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="form" v-model="isFormValid" lazy-validation>
            <v-text-field
              class="pt-5"
              label="Name"
              v-model="menu.name"
              color="primary"
              hint="Friendly name for this menu file"
              :rules="nameRules"
              outlined
            ></v-text-field>
            <v-text-field
              label="Prompt Record PIN"
              placeholder="Record Pin"
              v-model="menu.record_pin"
              maxlength="6"
              minlength="3"
              counter
              hint="PIN to enter in order to allow users to record their own greetings"
              :rules="recordPinRules"
              type="number"
              color="primary"
              outlined
            ></v-text-field>
            <v-select
              label="Greeting Message"
              :items="mediaList"
              v-model="menu.greeting"
              hint="Select the Greeting message that you want to use for this menu. If you select - Not set -, then no media file will be played."
              persistent-hint
              item-text="name"
              item-value="id"
              outlined
              color="primary"
              clearable
            >
            </v-select>
            <v-row class="text-right mx-right">
              <v-col cols="12">
                <v-btn text class="primary-btn" @click="openNewAddMediaDialog"
                  >Add a media</v-btn
                >
              </v-col>
            </v-row>
            <v-checkbox
              class="pt-0 mt-0"
              label="Allow caller to dial extensions"
              v-model="menu.hunt"
            >
            </v-checkbox>
            <v-btn
              color="primary"
              outlined
              class="px-5"
              large
              block
              :loading="saveLoader"
              @click="saveNewMenu()"
              >Save
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Edit menu Dialog -->
    <v-dialog v-model="editMenuDialog" width="400">
      <v-card>
        <v-card-title class="title">
          Edit Menu
          <v-icon @click="editMenuDialog = false" class="ml-auto"
            >cancel</v-icon
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-text-field
            class="pt-5"
            label="Name"
            v-model="editMenu.name"
            hint="Friendly name for this menu file"
            color="primary"
            outlined
          ></v-text-field>
          <v-text-field
            label="Prompt Record PIN"
            v-model="editMenu.record_pin"
            :rules="editNumberRules"
            color="primary"
            type="number"
            outlined
            maxlength="6"
            minlength="3"
            hint="The value for the field must not be more than 6 and less than 3"
          ></v-text-field>
          <v-select
            label="Greeting Message"
            :items="mediaList"
            v-model="editMenu.media.greeting"
            item-text="name"
            item-value="id"
            outlined
            color="primary"
            clearable
            hint="Select the Greeting message that you want to use for this menu. If you select - Not set -, then no media file will be played."
            persistent-hint
          >
          </v-select>
          <v-checkbox
            class="pt-0 mt-0"
            label="Allow caller to dial extensions"
            v-model="editMenu.hunt"
          >
          </v-checkbox>
          <v-row>
            <v-col xs="6">
              <v-btn
                color="error"
                outlined
                large
                :loading="deleteLoader"
                @click="deleteMenusDetails()"
                block
                >Delete
              </v-btn>
            </v-col>
            <v-col xs="6">
              <v-btn
                color="primary"
                outlined
                class="px-5"
                large
                block
                :loading="editLoader"
                @click="editMenuData()"
                >Save
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Add media Dialog -->
    <add-new-media
      :addNewMediaDialogBySelect="addNewMediaDialogBySelect"
      @close-media="closeNewMedia"
    />
  </section>
</template>

<script>
import callFlow from "@/services/callFlowAPI.js";
import addNewMedia from "./addNewMedia.vue";

export default {
  props: ["editedItem", "selectedDragList", "editedIndex"],
  components: {
    addNewMedia,
  },
  data() {
    return {
      menuList: [],
      menu: {},
      mediaList: [],
      editMenu: {
        media: {},
      },
      nameRules: [(v) => !!v || "Name is required"],
      recordPinRules: [],
      editNumberRules: [],
      addNewMenuDialog: false,
      editMenuDialog: false,
      userDetail: {},
      loader: false,
      isFormValid: true,
      editLoader: false,
      disabledEditButton: false,
      saveLoader: false,
      deleteLoader: false,
      editedItemModal: false,
      addNewMediaDialogBySelect: false,
      byDefualtAddData: true,
      selectedValue: "",
      authToken: "",
    };
  },
  mounted() {
    this.userDetail = JSON.parse(localStorage.getItem("userDetail"));
    this.authToken = localStorage.getItem("authToken");
    this.getMenusList();
    this.getMediaList();
  },
  methods: {
    async getMediaList() {
      this.mediaList = [];
      var getUsersData = {
        accountId: this.userDetail.accountId,
      };
      try {
        let response = await callFlow.getMediaList(getUsersData);
        for (let index = 0; index < response.mediaList.length; index++) {
          const element = response.mediaList[index];
          element.name = "[UPL]" + " " + element.name;
          this.mediaList.push(element);
        }
      } catch (error) {
        console.log("====error===", error);
      }
    },
    async getMenusList() {
      this.loader = true;

      this.mediaData = [];
      var getUsersData = {
        accountId: this.userDetail.accountId,
      };
      try {
        let response = await callFlow.getMenusList(getUsersData);
        this.menuList = response.menusList;
        if (this.menuList.length != 0) {
          if (this.editMenu.id) {
            this.editedItem.data.id = this.editMenu.id;
            this.editedItem.data.name = this.editMenu.name;
            this.selectedValue = this.editedItem.data.name;
            this.byDefualtAddData = false;
          } else {
            if (this.editedItem.data.id) {
              this.editedItemModal = true;
              for (let index = 0; index < this.menuList.length; index++) {
                const menuList = this.menuList[index];
                if (menuList.id === this.editedItem.data.id) {
                  this.editedItem.data.id = menuList.id;
                  this.editedItem.data.name = menuList.name;
                  this.selectedValue = this.editedItem.data.name;
                  this.byDefualtAddData = false;
                }
              }
            } else {
              this.editedItem.data.id = this.menuList[0].id;
              this.editedItem.data.name = this.menuList[0].name;
              this.selectedValue = this.editedItem.data.name;
              this.byDefualtAddData = true;
            }
          }
          this.disabledEditButton = false;
        } else {
          this.disabledEditButton = true;
        }
        this.loader = false;
      } catch (error) {
        this.loader = false;
        console.log("====error===", error);
      }
    },
    getItemText(selectText) {
      // get the selected module name
      this.selectedValue = selectText;
    },
    closeAddNewMenuDialog() {
      this.addNewMenuDialog = false;
      this.resetFormData();
    },
    save() {
      this.byDefualtAddData = false;
      // get the selected module properties from editedItem obj
      var editedItem = {};
      editedItem.data = {
        name: this.selectedValue,
        timeout: this.editedItem.data.timeout,
        can_call_self: this.editedItem.data.can_call_self,
        icon: this.editedItem.data.icon,
        id: this.editedItem.data.id,
      };
      this.editedItem.data = editedItem.data;

      // this.selectedDragList[this.editedIndex] = this.editedItem;
      this.editedItemModal = true;
      //storing the selected data into call flow API on didnpbx page
      this.$emit(
        "save-menu-data",
        this.selectedDragList,
        this.editedItem,
        this.editedIndex
      );
      this.closeOnSave();
    },
    closeOnSave() {
      this.$emit("close-on-save-menu-dialog");
    },
    closeMenuDialog() {
      if (this.byDefualtAddData) {
        this.editedItem.data.id = "";
        this.editedItem.data.name = "";
        this.selectedValue = "";
      }
      this.closeOnSave();
    },

    async saveNewMenu() {
      if (this.menu.record_pin) {
        this.recordPinRules = [
          (v) => (v && v.length >= 3) || "Min 3 characters",
          (v) => (v && v.length <= 6) || "Max 6 characters",
        ];
      }
      if (this.$refs.form.validate()) {
        this.loader = true;
        this.saveLoader = true;
        var voiceMailData = {
          accountId: this.userDetail.accountId,
          authToken: this.authToken,
          menusData: {
            data: {
              timeout: 10000,
              interdigit_timeout: 2000,

              media: {
                greeting: this.menu.greeting,
                invalid_media: true,
                transfer_media: true,
                exit_media: true,
              },
              name: this.menu.name,
              hunt: this.menu.hunt,
              allow_record_from_offnet: false,
              suppress_media: false,
              retries: 3,
              ui_metadata: {
                version: "4.3-139",
                ui: "monster-ui",
                origin: "callflows",
              },
            },
          },
        };
        if (this.menu.record_pin) {
          voiceMailData.menusData.data.max_extension_length =
            this.menu.record_pin.length;
          voiceMailData.menusData.data.record_pin = this.menu.record_pin;
        } else {
          voiceMailData.menusData.data.max_extension_length = "4";
        }
        try {
          let response = await callFlow.addMenus(voiceMailData);
          this.loader = false;
          // console.log("response ===>", response);
          this.getMenusList();
          this.editedItem.data.id = response.responseData.data.id;
          this.addNewMenuDialog = false;
          this.saveLoader = false;
          this.resetFormData();
          this.$root.$emit("SHOW_SNACKBAR", {
            text: response.messageDetail,
            color: "success",
          });
        } catch (error) {
          this.saveLoader = false;
          this.loader = false;

          this.$root.$emit("SHOW_SNACKBAR", {
            text: "something wrong",
            color: "error",
          });
        }
      }
    },
    resetFormData() {
      this.$refs.form.reset();
    },
    async editMenuData() {
      if (this.editMenu.record_pin) {
        this.editNumberRules = [
          (v) => (v && v.length >= 3) || "Min 3 characters",
          (v) => (v && v.length <= 6) || "Max 6 characters",
        ];
      }
      this.editLoader = true;
      var voiceMailData = {
        accountId: this.userDetail.accountId,
        authToken: this.authToken,
        menusId: this.editedItem.data.id,
        editData: {
          data: this.editMenu,
        },
      };
      try {
        let response = await callFlow.editMenusDetails(voiceMailData);
        this.getMenusList();
        this.editMenuDialog = false;
        this.editLoader = false;
        this.$root.$emit("SHOW_SNACKBAR", {
          text: response.messageDetail,
          color: "success",
        });
      } catch (error) {
        this.editLoader = false;
        this.saveLoader = false;
        this.$root.$emit("SHOW_SNACKBAR", {
          text: "something wrong",
          color: "error",
        });
        console.log("====error===", error);
      }
    },

    async editParticularMenuDialog() {
      // this.editMenuDialog = true;
      var getMenuData = {
        accountId: this.userDetail.accountId,
        menusId: this.editedItem.data.id,
      };
      try {
        let response = await callFlow.getMenusDetails(getMenuData);
        this.editMenu = response.menusDetails;
        this.editMenuDialog = true;
      } catch (error) {
        console.log("====error===", error);
        this.editMenuDialog = true;
      }
    },
    async deleteMenusDetails() {
      this.deleteLoader = true;
      var delteMenuData = {
        accountId: this.userDetail.accountId,
        authToken: this.authToken,
        menusId: this.editedItem.data.id,
      };
      try {
        let response = await callFlow.deleteMenusDetails(delteMenuData);
        this.editMenu = {
          media: {},
        };
        this.editedItem.data.id = "";
        this.getMenusList();
        this.editMenuDialog = false;
        this.deleteLoader = false;
        this.$root.$emit("SHOW_SNACKBAR", {
          text: response.messageDetail,
          color: "success",
        });
      } catch (error) {
        this.deleteLoader = false;
        this.$root.$emit("SHOW_SNACKBAR", {
          text: "something wrong",
          color: "error",
        });
      }
    },
    openNewAddMediaDialog() {
      this.addNewMediaDialogBySelect = true;
    },
    closeNewMedia() {
      this.addNewMediaDialogBySelect = false;
      this.getMediaList();
    },
  },
};
</script>
<template>
  <section>
    <v-card :loading="loader">
      <v-card-title class="title">
        Select Fax Detect
        <v-icon @click="closeOnSave" class="ml-auto">cancel</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <h3 class="pb-3">Fax Detect Duration</h3>
        <v-select
          label="Select Fax Detect Duration"
          :items="faxBoxList"
          item-text="name"
          item-value="id"
          v-model="editedItem.data.duration"
          outlined
          color="primary"
          class="pb-0 mb-0"
          :rules="durationRules"
        >
        </v-select>
        <v-btn block color="primary" outlined large @click="save()"
          >Save
        </v-btn>
      </v-card-text>
    </v-card>
  </section>
</template>

<script>
export default {
  name: "addCheckCidDialog",
  props: ["editedItem", "selectedDragList", "editedIndex"],
  data() {
    return {
      durationRules: [(v) => !!v || "Field is required"],
      faxBoxList: [3, 4, 5, 6, 7],
      loader: false,
      // isFormValid: true,
      // editedItemModal: false,
    };
  },
  mounted() {},
  methods: {
    save() {
      // get the selected module properties from editedItem obj
      this.editedItem.data.name = this.editedItem.data.duration;
      //storing the selected data into call flow API on didnpbx page
      this.$emit(
        "save-faxbox-detect-data",
        this.selectedDragList,
        this.editedItem,
        this.editedIndex
      );
      this.closeOnSave();
    },
    closeOnSave() {
      this.$emit("close-on-save-faxbox-detect-dialog");
    },
    closeFaxboxDialog() {
      this.closeOnSave();
    },
  },
};
</script>


<template>
  <section>
    <v-progress-linear
      :active="isBarLoading"
      :indeterminate="isBarLoading"
      absolute
      top
      color="primary"
    ></v-progress-linear>

    <v-row>
      <v-col md="2" class="pt-0">
        <!-- start:Action card data -->
        <div class="action-side-bar">
          <div class="action-side-bar-height">
            <v-expansion-panels accordion v-model="panel" class="mx-auto">
              <v-toolbar dense flat>
                <v-toolbar-title class="title font-weight-medium"
                  >Actions</v-toolbar-title
                >
                <!-- <v-spacer></v-spacer> -->
              </v-toolbar>
              <v-expansion-panel
                v-for="(expansionActionModule, i) in expansionActionModulelist"
                :key="i"
              >
                <v-expansion-panel-header class="my-2 font-weight-medium">{{
                  expansionActionModule.moduleTypeName
                }}</v-expansion-panel-header>
                <v-expansion-panel-content class="pa-0 ma-0">
                  <draggable
                    :list="expansionActionModule.actionList"
                    :group="{ name: 'module', pull: 'clone', put: true }"
                    class="action-list px-0 mx-0"
                    :clone="cloneCallflow"
                    :sort="false"
                  >
                    <div
                      class="pa-0 py-2 text-center action-card"
                      v-for="(element, i) in expansionActionModule.actionList"
                      :key="i"
                      @click="element.showTooltip = true"
                    >
                      <!-- show-menu of module detail -->
                      <v-menu
                        v-model="element.showTooltip"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on">
                            <v-avatar color="blue" size="34">
                              <v-icon size="18" dark>{{ element.icon }}</v-icon>
                            </v-avatar>
                            <v-card-subtitle
                              class="py-1 px-0 font-weight-medium action-subtitle"
                            >
                              {{ element.name }}
                            </v-card-subtitle>
                          </div>
                        </template>

                        <v-card>
                          <v-list>
                            <v-list-item>
                              <v-list-item-avatar>
                                <v-avatar color="blue" size="34">
                                  <v-icon size="18" dark>{{
                                    element.icon
                                  }}</v-icon>
                                </v-avatar>
                              </v-list-item-avatar>

                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ element.name }}</v-list-item-title
                                >
                                <v-list-item-subtitle class="pa-0">{{
                                  element.tooltip
                                }}</v-list-item-subtitle>
                              </v-list-item-content>

                              <v-icon
                                color="error"
                                @click="element.showTooltip = false"
                                >cancel</v-icon
                              >
                            </v-list-item>
                          </v-list>
                        </v-card>
                      </v-menu>
                    </div>
                  </draggable>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </div>
      </v-col>
      <v-col md="10" class="pt-0">
        <v-row>
          <v-col sm="12" xs="12" lg="12" md="12" class="text-center pt-0">
            <!-- start: call flow toolbar -->
            <v-col cols="12" class="mx-auto pa-0 callflow-toolbar">
              <v-toolbar
                dense
                flat
                outlined
                rounded
                shaped
                class="rounded toolbar-callflow"
              >
                <!-- edit and view call flow -->
                <v-autocomplete
                  label="View/Edit call flow"
                  :items="getCallFlowData"
                  :item-text="
                    (item) =>
                      item.name
                        ? item.name + ' - ' + item.numbers
                        : item.numbers
                  "
                  item-value="id"
                  v-model="selectCallFlowId"
                  outlined
                  color="primary"
                  class="select"
                  @change="slectedCallFlowList(selectCallFlowId)"
                  hide-details
                  dense
                  hide-no-data
                  flat
                ></v-autocomplete>

                <v-spacer></v-spacer>
                <!-- callflow name -->
                <v-toolbar-title class="font-weight-medium call-flow-title">
                  <span v-if="callFlowName">{{ callFlowName }}</span>
                  <span v-else> Enter name of call flow </span>
                  <v-btn
                    color="blue"
                    outlined
                    class="ml-3"
                    small
                    @click="setCallflowNameDialog = true"
                  >
                    <v-icon dark> edit_square </v-icon>
                  </v-btn>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <!-- add new -->
                <v-hover v-slot="{ hover }">
                  <v-fade-transition>
                    <v-btn
                      color="info"
                      outlined
                      @click="addNewCallFLow"
                      small
                      class="mr-3"
                    >
                      <span v-if="hover" class="pr-2"> Add New </span>
                      <v-icon dark class="" color="info"> add </v-icon>
                    </v-btn>
                  </v-fade-transition>
                </v-hover>
                <!-- reload the page -->
                <v-hover v-slot="{ hover }">
                  <v-btn
                    color="purple"
                    outlined
                    class="mr-3"
                    small
                    @click="reloadPage()"
                  >
                    <span v-if="hover" class="pr-2"> Reload </span>
                    <v-icon dark> refresh </v-icon>
                  </v-btn>
                </v-hover>

                <!-- add new number -->
                <v-hover v-slot="{ hover }">
                  <v-btn
                    outlined
                    small
                    @click="opneAddNumberDialog()"
                    color="primary"
                  >
                    <span v-if="hover" class="pr-2"> Add Number </span>
                    <v-icon color="primary">add_ic_call</v-icon>
                  </v-btn>
                </v-hover>

                <!-- Save the Call Flow -->
                <v-hover v-slot="{ hover }">
                  <v-btn
                    color="green"
                    outlined
                    class="ml-3"
                    small
                    :loading="saveCallFlowLoader"
                    @click="saveCallFlow()"
                  >
                    <span v-if="hover" class="pr-2"> save </span>
                    <v-icon dark> save </v-icon>
                  </v-btn>
                </v-hover>

                <!-- Delete exist call flow-->
                <v-hover v-slot="{ hover }">
                  <v-btn
                    color="error"
                    outlined
                    class="mx-3"
                    small
                    :loading="deleteLoader"
                    @click="confirmDelete()"
                  >
                    <span v-if="hover" class="pr-2"> delete </span>
                    <v-icon dark> delete </v-icon>
                  </v-btn>
                </v-hover>
              </v-toolbar>
              <!-- end: call flow toolbar -->
              <!-- {{ selectedNumbers.length }} -->
              <!-- selected call flow number list -->
              <v-col cols="12" class="py-0 solid-bottom-br">
                <v-row class="justify-center align-center">
                  <v-col
                    :sm="selectedNumber.length == 0 ? '12' : '3'"
                    v-for="(selectedNumber, i) in selectedNumbers"
                    :key="i"
                    class="text-center solid-br"
                  >
                    <p class="mb-0">
                      {{ selectedNumber }}

                      <!-- add new number -->
                      <!-- <v-hover v-slot="{ hover }">
                        <v-btn
                          outlined
                          small
                          @click="opneAddNumberDialog()"
                          color="primary"
                          class="ml-2"
                          v-if="getSelectedSubscriptionNumber == selectedNumber"
                        >
                          <div v-if="hover">
                            <span class="pr-2"> Add Number </span>
                          </div>
                          <v-icon color="primary">add_ic_call</v-icon>
                        </v-btn>
                      </v-hover> -->

                      <!-- removed selected number -->
                      <v-icon
                        color="error"
                        class="float-right mt-1"
                        size="20"
                        @click="closeSelectedNumber(selectedNumber)"
                        >highlight_off</v-icon
                      >
                    </p>
                  </v-col>
                  <!-- add new number -->
                  <p
                    class="py-3 text-center mb-0"
                    :class="selectedNumbers.length != 0 ? 'ml-5' : ''"
                  >
                    <v-btn
                      outlined
                      small
                      @click="opneAddNumberDialog()"
                      color="primary"
                    >
                      <span class="pr-2"> Add Number </span>
                      <v-icon color="primary">add_ic_call</v-icon>
                    </v-btn>
                  </p>
                </v-row>
              </v-col>
            </v-col>

            <!-- selected call flow module list -->
            <v-row class="my-3 drag-overflow" v-if="timeOfDayData.length != 0">
              <nested-draggable
                :tasks="actionList"
                :dropEnable="dropEnable"
                :metaData="metaData"
                class="mx-auto"
              />
            </v-row>
            <!-- <pre>  {{ valueString }} </pre> -->
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-dialog v-model="openAddNumberDialog" width="400">
      <!-- Add number dialog-->
      <AddNumberDialog
        :subscriptionNewList="subscriptionNewList"
        :getNumberCardLoader="getNumberCardLoader"
        :selectedNumbers="selectedNumbers"
        @close-number-Dialog="closeNumberDialog"
        @add-Selected-Numbers="addSelectedNumbers"
      />
    </v-dialog>

    <!-- delete action modal -->
    <v-dialog v-model="confirmModal" width="400">
      <v-card>
        <v-card-title class="title">
          <span>Are you sure,you want to Delete?</span>
          <br /><br />
        </v-card-title>
        <v-card-actions class="px-4">
          <v-spacer></v-spacer>
          <v-btn color="error" small outlined @click="confirmModal = false">
            Cancel
          </v-btn>
          <v-btn
            color="green darken-1"
            outlined
            small
            :loading="isLoading"
            @click="deleteCallflowsDetails()"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- start:Set Call Flow Name Dialog -->
    <v-dialog v-model="setCallflowNameDialog" width="400">
      <v-card>
        <v-card-title class="title">
          Edit Callflow Name
          <v-icon
            @click="setCallflowNameDialog = false"
            color="error"
            class="ml-auto"
            >cancel</v-icon
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-text-field
            label="Callflow Name"
            v-model="callFlowName"
            outlined
            color="primary"
            hide-details
            class="mt-4"
          >
          </v-text-field>
          <v-card-subtitle class="px-1">
            Giving a name to a callflow isn't mandatory. Leave the field blank
            and the callflow will be displayed in the left listing as the list
            of numbers used in this callflow.
          </v-card-subtitle>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-4">
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            outlined
            :loading="isLoading"
            @click="setCallflowNameDialog = false"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end:Set Call Flow Name Dialog  -->
  </section>
</template>

<script>
import callFlow from "@/services/callFlowAPI.js";
import numberManagerAPI from "@/services/numberManagerAPI.js";
import nestedDraggable from "./nestedDragle.vue";
import basicActionList from "./jsFile/basicActionList";
import advanceActionsList from "./jsFile/advanceActionList";
import timeOfDayList from "./jsFile/timeOfDayList";
import faxModuleList from "./jsFile/faxModuleList";
import voiceMailList from "./jsFile/voiceMailList";
import CallFlowList from "./jsFile/callFlow";

import AddNumberDialog from "./addNumberDialog";
import liveCallList from "@/services/liveCallAPI.js";

import draggable from "vuedraggable";
import { json } from "body-parser";
// const outPutData = [];

export default {
  name: "nested-example",
  display: "Nested",
  order: 15,
  components: {
    nestedDraggable,
    draggable,
    AddNumberDialog,
  },
  data() {
    return {
      // string
      selectedCallFlowId: "",
      selectCallFlowId: "",
      tooltip: "",
      getSelectedSubscriptionNumber: "",
      // selectedCallFloweName: "",
      callFlowName: "",

      // objects
      editContactList: {
        exclude: false,
      },
      getSelectedModuleData: {},
      metaData: {},
      // boolean
      isBarLoading: false,
      isLoading: false,
      addNumberDialog: false,
      getNumberCardLoader: false,
      openAddNumberDialog: false,
      deleteLoader: false,
      tooltipAlert: false,
      confirmModal: false,
      setCallflowNameDialog: false,
      dropEnable: false,
      saveCallFlowLoader: false,

      // array
      panel: [0],
      expansionActionModulelist: [
        {
          moduleTypeName: "",
          actionList: [],
        },
      ],
      actionList: [],
      getCallFlowData: [],
      selectedNumbers: [],
      subscriptionNewList: [],
      timeOfDayData: [],
      targetKeysSet: null,
      outputKeys: [],
      checkParentKey: [],
      deviceList: [],
      // menuOptionKey: ["*", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
    };
  },
  created() {
    // get right side drag data
    this.basicActionList = basicActionList.actionListMenu;
    this.advanceActionList = advanceActionsList.actionListMenu;
    this.timOfDayList = timeOfDayList.timeOfDayListMenu;
    this.faxModuleList = faxModuleList.faxModuleMenu;
    this.voiceMailList = voiceMailList.voiceMailListMenu;

    //set data for right side drag module
    this.expansionActionModulelist = [
      {
        moduleTypeName: "Basic",
        actionList: this.basicActionList,
      },
      {
        moduleTypeName: "Advance",
        actionList: this.advanceActionList,
      },
      {
        moduleTypeName: "Voice Mail",
        actionList: this.voiceMailList,
      },
      {
        moduleTypeName: "Fax Box",
        actionList: this.faxModuleList,
      },
      {
        moduleTypeName: "Time Of Day",
        actionList: this.timOfDayList,
      },
      {
        moduleTypeName: "CallFlow",
        actionList: CallFlowList.callFLow,
      },
    ];
    // console.log(this.expansionActionModulelist);
  },
  mounted() {
    var userData = localStorage.getItem("userDetail");
    // console.log("userData", userData);
    // get user data
    this.userDetail = JSON.parse(localStorage.getItem("userDetail"));
    // get all callflow data
    this.getCallflowsData();
    //get time of day list
    this.getTODList();
    //getALlDeviceList
    this.getAllDevices();
    // getLiveCall
    this.geLiveDevices();
  },
  methods: {
    reloadPage() {
      if (this.selectCallFlowId) {
        this.slectedCallFlowList(this.selectCallFlowId);
        //get time of day list
        this.getTODList();
        //getALlDeviceList
        this.getAllDevices();
        // getLiveCall
        this.geLiveDevices();
      } else {
        location.reload();
      }
    },
    cloneCallflow(evt) {
      this.setCallflowData = {
        _: [
          {
            data: evt.data,
            children: [],
            module: evt.module,
          },
        ],
      };
      return this.setCallflowData;
    },
    async saveCallFlow() {
      let getSelectedCallFlowList = JSON.stringify(this.actionList);
      // console.log("getSelectedCallFlowList==", getSelectedCallFlowList);

      let replacedCommasCallFLowList = getSelectedCallFlowList
        .split("},{")
        .join(",");

      // console.log("replacedCommasCallFLowList", replacedCommasCallFLowList);

      // replace ARRAY with OBJECT sign
      let replacedLeftArrayObjectSign = replacedCommasCallFLowList
        .split("[{")
        .join("{");
      let replacedRightArrayObjectSign = replacedLeftArrayObjectSign
        .split("}]")
        .join("}");

      // replace ARRAY sign of empty children object
      let replacedArraySignToObject = replacedRightArrayObjectSign
        .split('"children":[]')
        .join('"children":{}');

      let finalCallFlow = replacedArraySignToObject;

      let saveSelectedCallFlowData = JSON.parse(finalCallFlow);
      // console.log("saveSelectedCallFlowData========", saveSelectedCallFlowData);
      if (this.selectedNumbers.length != 0) {
        this.saveCallFlowLoader = true;
        //   // EDIT CALL FLOW
        if (this.selectedCallFlowId) {
          var editCallflowsData = {
            accountId: this.userDetail.accountId,
            authToken: this.userDetail.authToken,
            callflowId: this.selectedCallFlowId,
            editData: {
              data: {
                flow: saveSelectedCallFlowData._,
                name: this.callFlowName,
                ui_metadata: {
                  version: "4.3-140",
                  ui: "monster-ui",
                  origin: "callflows",
                },
                contact_list: this.editContactList,
                patterns: [],
                numbers: this.selectedNumbers,
                id: this.selectedCallFlowId,
              },
            },
          };
          this.isLoading = true;
          try {
            let response = await callFlow.editCallflowsDetails(
              editCallflowsData
            );
            this.isLoading = false;
            this.saveCallFlowLoader = false;
            this.getCallflowsData();
            this.getSpareNumbers();
            this.getTODList();
            this.slectedCallFlowList(response.responseData.data.id);
            this.$root.$emit("SHOW_SNACKBAR", {
              text: response.responseMessage,
              color: "success",
            });
          } catch (error) {
            this.saveCallFlowLoader = false;
            this.isLoading = false;
            this.$root.$emit("SHOW_SNACKBAR", {
              text: "something wrong!",
              color: "error",
            });
          }
        } else {
          this.isLoading = true;
          this.saveCallFlowLoader = true;

          // ADD NEWCALL FLOW
          var CallflowsData = {
            accountId: this.userDetail.accountId,
            authToken: this.userDetail.authToken,
            callflowsData: {
              data: {
                flow: saveSelectedCallFlowData._,
                name: this.callFlowName,
                ui_metadata: {
                  version: "4.3-140",
                  ui: "monster-ui",
                  origin: "callflows",
                },
                numbers: this.selectedNumbers,
              },
            },
          };
          try {
            let response = await callFlow.addCallflows(CallflowsData);
            this.saveCallFlowLoader = false;
            this.getCallflowsData();
            this.getSpareNumbers();
            this.getTODList();
            this.slectedCallFlowList(response.responseData.data.id);
            this.selectedCallFlowId = response.responseData.data.id;
            this.isLoading = false;
            this.$root.$emit("SHOW_SNACKBAR", {
              text: response.responseMessage,
              color: "success",
            });
          } catch (error) {
            this.isLoading = false;
            this.saveCallFlowLoader = false;
            this.$root.$emit("SHOW_SNACKBAR", {
              text: "something wrong!",
              color: "error",
            });
          }
        }
      } else {
        this.saveCallFlowLoader = false;
        this.$root.$emit("SHOW_SNACKBAR", {
          text: "Please add number!",
          color: "error",
        });
      }
    },
    async slectedCallFlowList(callflowId) {
      this.isBarLoading = true;
      this.actionList = [];
      this.selectedCallFlowId = "";
      var callflowsList = {
        accountId: this.userDetail.accountId,
        callflowId: callflowId,
      };
      try {
        let response = await callFlow.getCallflowsDetails(callflowsList);
        this.selectedNumbers = response.callflowsDetails.numbers;
        let callFLow = [];
        // callFLow.push(
        //   this.getCallFlowData.find(
        //     (moduleObject) => moduleObject.id != callflowId
        //   )
        // );
        for (let index = 0; index < this.getCallFlowData.length; index++) {
          const element = this.getCallFlowData[index];
          if (element.id != callflowId) {
            callFLow.push(element);
          }
        }
        // console.log("callFLow", callFLow);
        localStorage.setItem("callFlowList", JSON.stringify(callFLow));
        // localStorage.setItem("callFLow", callFLow);
        if (this.selectedNumbers) {
          this.getSelectedSubscriptionNumber = this.selectedNumbers
            .slice(-1)
            .pop();
        }
        this.callFlowName = response.callflowsDetails.name;
        this.selectedCallFlowId = response.callflowsDetails.id;
        this.editContactList = response.callflowsDetails.contact_list;
        this.metaData = response.callflowsDetails.metadata;
        if (this.metaData) {
          localStorage.setItem("metaData", JSON.stringify(this.metaData));
        }
        let getSelectedCallFlowList = JSON.stringify(
          response.callflowsDetails.flow
        );
        let replacedLeftArrayObjectSign = getSelectedCallFlowList
          .split("{")
          .join("[{");
        let replacedRightArrayObjectSign = replacedLeftArrayObjectSign
          .split("}")
          .join("}]");

        let replacedArraySignToObject = replacedRightArrayObjectSign
          .split('"children":[{}]')
          .join('"children":[]');

        // console.log("replacedArraySignToObject", replacedArraySignToObject);

        let finalCallFLow = replacedArraySignToObject;

        let finalCallFLowArray = [
          {
            _: JSON.parse(finalCallFLow),
          },
        ];
        this.actionList = finalCallFLowArray;
        // console.log(JSON.stringify(this.actionList))
        this.isBarLoading = false;
      } catch (error) {
        this.isBarLoading = false;
        console.log("====error===", error);
      }
    },
    opneAddNumberDialog() {
      this.openAddNumberDialog = true;
      // this.addNumberDialog = true;
      this.selectedModule = "addNumber";
      this.getSpareNumbers();
    },
    closeNumberDialog() {
      this.openAddNumberDialog = false;
    },
    // get all spare number data
    async getSpareNumbers() {
      this.subscriptionNewList = [];
      this.getNumberCardLoader = true;
      var payload = {
        accountId: this.userDetail.accountId,
      };
      try {
        let response = await numberManagerAPI.getDidNumberDetailSubscriptionNew(
          payload
        );
        var subscriptionNewList = [];
        for (const key in response.spareNumbers.numbers) {
          subscriptionNewList.push({ didNumber: key });
        }
        // removed if already created callflow in given numbers
        this.subscriptionNewList = subscriptionNewList.filter(
          (obj1) =>
            !this.getCallFlowData.some(
              (obj2) => obj2.numbers[0] === obj1.didNumber
            )
        );
        this.getNumberCardLoader = false;
      } catch (error) {
        this.getNumberCardLoader = false;
        console.log("====error===", error);
      }
    },
    // get call flow
    async getCallflowsData() {
      this.isBarLoading = true;
      var CallflowsData = {
        accountId: this.userDetail.accountId,
      };
      try {
        let response = await callFlow.getCallflowsList(CallflowsData);
        this.getCallFlowData = this.removeObjectWithId(
          response.callflowsList,
          "no_match"
        );
        localStorage.setItem(
          "callFlowList",
          JSON.stringify(this.getCallFlowData)
        );
        this.isBarLoading = false;
      } catch (error) {
        this.isBarLoading = false;
        // console.log("====error===", error);
      }
    },
    // removed no_match object from callflow list
    removeObjectWithId(callflowsList, id) {
      // Making a copy with the Array from() method
      const callflowsListCopy = Array.from(callflowsList);

      const objWithIdIndex = callflowsListCopy.findIndex(
        (obj) => obj.numbers[0] === id
      );
      callflowsListCopy.splice(objWithIdIndex, 1);
      return callflowsListCopy;
    },

    addSelectedNumbers(getSelectedNumbers, selectedSubscriptionNumber) {
      this.selectedNumbers = getSelectedNumbers;
      this.getSelectedSubscriptionNumber = selectedSubscriptionNumber;
    },
    closeSelectedNumber(item) {
      const index = this.selectedNumbers.indexOf(item);
      this.selectedNumbers.splice(index, 1);
      if (this.selectedNumbers) {
        this.getSelectedSubscriptionNumber = this.selectedNumbers
          .slice(-1)
          .pop();
      }
    },
    addNewCallFLow() {
      this.selectedNumbers = [];
      this.actionList = [];
      this.selectCallFlowId = "";
      this.selectedCallFlowId = "";
      this.getSelectedSubscriptionNumber = "";
      this.callFlowName = "";
      // this.openSelectedModuleModal = false;
      this.getCallflowsData();
      this.getSpareNumbers();
    },
    async deleteCallflowsDetails() {
      this.deleteLoader = true;
      var deleteSelectedCallflow = {
        accountId: this.userDetail.accountId,
        authToken: this.userDetail.authToken,
        callflowId: this.selectedCallFlowId,
      };
      try {
        let response = await callFlow.deleteCallflowsDetails(
          deleteSelectedCallflow
        );
        this.selectedNumbers = [];
        this.actionList = [];
        this.selectCallFlowId = "";
        this.selectedCallFlowId = "";
        this.getSelectedSubscriptionNumber = "";
        this.callFlowName = "";
        this.confirmModal = false;
        this.getCallflowsData();
        this.getSpareNumbers();
        // this.showMenuChildModule = false;
        this.isLoading = false;
        this.deleteLoader = false;
        this.$root.$emit("SHOW_SNACKBAR", {
          text: response.messageDetail,
          color: "success",
        });
      } catch (error) {
        this.deleteLoader = false;
        this.isLoading = false;
        this.$root.$emit("SHOW_SNACKBAR", {
          text: "something wrong!",
          color: "error",
        });
      }
    },
    async getTODList() {
      this.timeOfDayData = [];
      var getTODData = {
        accountId: this.userDetail.accountId,
      };
      let defaultValue = {
        id: "_",
      };
      try {
        let response = await callFlow.getTODList(getTODData);
        for (let index = 0; index < response.todList.length; index++) {
          const element = response.todList[index];
          this.timeOfDayData.push(element.id);
        }
        this.timeOfDayData.push(defaultValue.id);
      } catch (error) {
        console.log("====error===", error);
      }
    },
    // show tooltip
    showtooltip(tooltip) {
      this.tooltip = tooltip;
    },
    // confirm delete action
    confirmDelete() {
      if (this.selectedCallFlowId) {
        this.confirmModal = true;
      } else {
        this.$root.$emit("SHOW_SNACKBAR", {
          text: "Please Select Call Flow!",
          color: "error",
        });
      }
    },
    async getAllDevices() {
      this.loader = true;
      this.deviceList = [];
      var getFilteredDeviceData = {
        accountId: this.userDetail.accountId,
      };
      try {
        let response = await callFlow.getDeviceList(getFilteredDeviceData);
        this.deviceList = response.deviceList;
        localStorage.setItem("getDeviceList", JSON.stringify(this.deviceList));
        // console.log("this.deviceList=======", this.deviceList);
      } catch (error) {
        // this.loader = false;
        console.log("====error===", error);
      }
    },
    async geLiveDevices() {
      var currentRegistrationListJoson = {
        accountId: this.userDetail.accountId,
        authToken: this.userDetail.authToken,
      };
      try {
        let response = await liveCallList.getRegistrationsData(
          currentRegistrationListJoson
        );
        if (response) {
          localStorage.setItem(
            "currentList",
            JSON.stringify(response.registrations)
          );
        }
      } catch (error) {
        this.isLoading = false;
      }
    },
  },
  computed: {
    valueString() {
      return JSON.stringify(this.actionList, null, 2);
      // const data = this.actionList;
    },
  },
};
</script>


<style>
.multiple-options
  .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(
    .v-input--has-state
  )
  > .v-input__control
  > .v-input__slot
  fieldset {
  color: #1976d2 !important;
}
.multiple-options .theme--light.v-label {
  color: #1976d2 !important;
}

.select {
  width: 180px !important;
  flex: initial;
  font-size: 10px !important;
}

.multiple-options
  .v-text-field--outlined.v-input--dense.v-text-field--outlined
  > .v-input__control
  > .v-input__slot {
  min-height: 36px !important;
}

.v-btn {
  transition: all 10s !important;
}

.toolbar-callflow .v-text-field--outlined legend {
  line-height: 2;
}

.toolbar-callflow .v-label {
  color: #1976d2 !important;
  font-size: 14px !important;
}

.toolbar-callflow .v-input__slot {
  min-height: 34px !important;
}

.toolbar-callflow .v-text-field--outlined fieldset {
  border-color: #1976d2;
}

.call-flow-title {
  /* position: absolute; */
  /* right: 50%; */
  font-size: 16px !important;
}

.callflow-toolbar .v-toolbar--flat .v-toolbar__content {
  height: 44px !important;
}

.v-expansion-panel::before {
  box-shadow: none !important;
}

.action-side-bar {
  position: fixed;
  max-width: 14%;
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%),
    0px 0px 0px 0px rgb(0 0 0 / 12%) !important;
  border: thin solid rgba(0, 0, 0, 0.12);
}

.action-side-bar .action-side-bar-height {
  overflow: auto;
  max-height: 680px;
}

.action-side-bar .action-side-bar-height .action-card {
  width: 42%;
  /* height: 76px; */
  background-color: #fff;
  border: 2px solid #fff;
  border-radius: 7px;
  cursor: grab;
  transition: all 0.1s ease-in-out;
  margin: 8px;
}

.action-subtitle {
  line-height: 1rem !important;
  font-size: 12px;
}

.action-list {
  background-color: rgb(246, 248, 253) !important;
}

.drag-overflow {
  overflow: auto;
  /* transform: scale(0.1); */
  /* transform-origin: 0% 0% 0px; */
}

@media screen and (max-width: 1568px) {
  .action-side-bar .action-side-bar-height .action-card {
    width: 100%;
  }
}
</style>
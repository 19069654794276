<template>
  <section>
    <v-card :loading="loader">
      <v-card-title class="title">
        Select Faxbox
        <v-icon @click="closeFaxboxDialog" class="ml-auto">cancel</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <h3 class="pt-5 pb-3">Connect call to faxbox</h3>
        <v-select
          label="Which Faxbox?"
          :items="faxBoxList"
          item-text="name"
          item-value="id"
          v-model="editedItem.data.id"
          outlined
          color="primary"
          class="pb-0 mb-0"
        >
          <template slot="item" slot-scope="data">
            <v-list-item-content @click="getItemText(data.item.name)">
              <v-list-item-title v-html="data.item.name"></v-list-item-title>
            </v-list-item-content>
          </template>
        </v-select>
        <v-row>
          <v-col cols="12" sm="6">
            <v-btn
              block
              color="green"
              outlined
              :disabled="disabledEditButton"
              @click="editParticularFaxBoxDialog()"
            >
              Edit</v-btn
            >
          </v-col>
          <v-col cols="12" sm="6">
            <v-btn
              block
              color="info"
              outlined
              @click="addNewFaxBoxDialog = true"
            >
              Add
            </v-btn>
          </v-col>
        </v-row>
        <v-btn block color="primary" outlined large @click="save()"
          >Save
        </v-btn>
      </v-card-text>
    </v-card>
    <!-- Add new faxbox Dialog -->
    <v-dialog v-model="addNewFaxBoxDialog" width="400">
      <v-card>
        <v-card-title class="title">
          Create Fax Box
          <v-icon @click="closeAddNewFaxBoxDialog()" class="ml-auto"
            >cancel</v-icon
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="form" v-model="isFormValid" lazy-validation>
            <v-select
              class="pt-3"
              label="Assign To"
              :items="assignTo"
              item-text="name"
              item-value="id"
              v-model="faxbox.id"
              @change="getParticularUserData(faxbox.id)"
              outlined
              clearable
              color="primary"
            >
            </v-select>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  label="Name"
                  :rules="nameRules"
                  v-model="faxbox.name"
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                ></v-text-field>
              </template>
              <span>Friendly name for this faxbox</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  label="Inbound faxes"
                  v-model="faxbox.inboundMail"
                  color="primary"
                  placeholder="mail@domain1.com"
                  :rules="inboundMailRules"
                  persistent-hint
                  v-bind="attrs"
                  v-on="on"
                  outlined
                ></v-text-field>
              </template>
              <span
                >List of email addresses separated by a <br />
                comma that will receive a notification <br />
                when a fax is received by the faxbox</span
              >
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  label="Fax sent receipts"
                  v-model="faxbox.outboundMail"
                  :rules="outboundMailRules"
                  color="primary"
                  placeholder="mail@domain1.com"
                  persistent-hint
                  outlined
                  v-on="on"
                  v-bind="attrs"
                ></v-text-field>
              </template>
              <span
                >List of email addresses separated by a <br />
                comma that will receive a notification <br />
                when a fax is received by the faxbox</span
              >
            </v-tooltip>
            <v-btn
              color="primary"
              outlined
              large
              block
              class="px-5"
              :loading="loader"
              @click="saveNewFaxBox()"
              >Save
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Edit faxbox Dialog -->
    <v-dialog v-model="editFaxBoxDialog" width="400">
      <v-card>
        <v-card-title class="title">
          Edit Fax Box
          <v-icon @click="editFaxBoxDialog = false" class="ml-auto"
            >cancel</v-icon
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="editForm" v-model="editIsFormValid">
            <v-select
              class="mt-5"
              label="Assign To"
              :items="assignTo"
              item-text="name"
              item-value="id"
              v-model="editFaxbox.owner_id"
              @change="getParticularUserData(editFaxbox.owner_id)"
              outlined
              color="primary"
              clearable
            >
            </v-select>
            <v-text-field
              label="Faxbox name"
              v-model="editFaxbox.name"
              :rules="nameRules"
              color="primary"
              outlined
            ></v-text-field>
            <v-text-field
              label="Inbound faxes"
              v-model="editFaxbox.inboundMail"
              :rules="inboundMailRules"
              color="primary"
              placeholder="mail@domain1.com, mail@domain2.com"
              persistent-hint
              outlined
            ></v-text-field>
            <v-text-field
              label="Fax sent receipts"
              v-model="editFaxbox.outboundMail"
              color="primary"
              :rules="outboundMailRules"
              placeholder="mail@domain1.com, mail@domain2.com"
              persistent-hint
              outlined
            ></v-text-field>
            <v-row>
              <v-col xs="6">
                <v-btn
                  color="error"
                  outlined
                  large
                  :loading="deleteLoader"
                  @click="deleteFaxboxes()"
                  block
                  >Delete
                </v-btn>
              </v-col>
              <v-col xs="6">
                <v-btn
                  color="primary"
                  outlined
                  large
                  class="px-5"
                  block
                  :loading="editLoader"
                  @click="saveEditFaxbox()"
                  >Save
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import numberManagerAPI from "@/services/numberManagerAPI.js";
import callFlow from "@/services/callFlowAPI.js";

export default {
  props: ["editedItem", "selectedDragList", "editedIndex"],
  data() {
    return {
      faxbox: {
        name: "",
        outboundMail: [],
        inboundMail: [],
      },
      nameRules: [(v) => !!v || "Name is required"],
      inboundMailRules: [
        (v) =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "this value should be a valid email",
      ],
      outboundMailRules: [
        (v) =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "this value should be a valid email",
      ],
      editFaxbox: {
        inboundMail: [],
        outboundMail: [],
        name: "",
        notifications: {
          outbound: {
            email: {},
          },
        },
        notifications: {
          inbound: {
            email: {},
          },
        },
      },
      faxBoxList: [],
      defaultFaxBoxCallflow: {},
      addNewFaxBoxDialog: false,
      editFaxBoxDialog: false,
      assignTo: [],
      loader: false,
      authToken: "",
      selectedValue: "",
      isFormValid: true,
      disabledEditButton: false,
      deleteLoader: false,
      editLoader: false,
      editedItemModal: false,
      byDefualtAddData: true,
      editIsFormValid: true,
    };
  },
  mounted() {
    this.userDetail = JSON.parse(localStorage.getItem("userDetail"));
    this.authToken = localStorage.getItem("authToken");
    this.getFaxboxes();
    this.getUsersList();
  },
  methods: {
    async getFaxboxes() {
      this.loader = true;
      this.faxBoxList = [];
      this.getFaxboxesData = {
        accountId: this.userDetail.accountId,
      };
      try {
        let responseFaxboxes = await numberManagerAPI.getFaxboxesList(
          this.getFaxboxesData
        );
        this.faxBoxList = responseFaxboxes.faxboxesList;
        if (this.faxBoxList.length != 0) {
          if (this.editFaxbox.id) {
            this.editedItem.data.id = this.editFaxbox.id;
            this.editedItem.data.name = this.editFaxbox.name;
            this.selectedValue = this.editedItem.data.name;
            this.byDefualtAddData = false;
          } else {
            if (this.editedItem.data.id) {
              this.editedItemModal = true;
              for (let index = 0; index < this.faxBoxList.length; index++) {
                const faxBoxList = this.faxBoxList[index];
                if (faxBoxList.id === this.editedItem.data.id) {
                  this.editedItem.data.id = faxBoxList.id;
                  this.editedItem.data.name = faxBoxList.name;
                  this.selectedValue = this.editedItem.data.name;
                  this.byDefualtAddData = false;
                }
              }
            } else {
              this.editedItem.data.id = this.faxBoxList[0].id;
              this.editedItem.data.name = this.faxBoxList[0].name;
              this.selectedValue = this.editedItem.data.name;
              this.byDefualtAddData = true;
            }
          }
          this.disabledEditButton = false;
        } else {
          this.disabledEditButton = true;
        }
        this.loader = false;
      } catch (error) {
        this.loader = false;
        // console.log("====error===", error);
      }
    },
    async getUsersList() {
      this.cardLoading = true;
      var getUsersData = {
        accountId: this.userDetail.accountId,
        userId: this.userDetail.userId,
        forwardType: "SOFTPHONE",
      };
      this.usersList = [];
      try {
        let response = await callFlow.getUsersList(getUsersData);
        // console.log("response ", response);
        // this.assignTo = response.usersList;
        var userData = {};
        for (let index = 0; index < response.usersList.length; index++) {
          const element = response.usersList[index];
          element.name = element.first_name + " " + element.last_name;
          userData = element;
          this.assignTo.push(userData);
        }
      } catch (error) {
        console.log("====error===", error);
      }
    },
    getItemText(selectText) {
      this.selectedValue = selectText;
    },
    save() {
      this.byDefualtAddData = false;
      // get the selected module properties from editedItem obj
      var editedItem = {};
      editedItem.data = {
        name: this.selectedValue,
        timeout: this.editedItem.data.timeout,
        can_call_self: this.editedItem.data.can_call_self,
        icon: this.editedItem.data.icon,
        id: this.editedItem.data.id,
      };
      this.editedItem.data = editedItem.data;

      // this.selectedDragList[this.editedIndex] = this.editedItem;
      this.editedItemModal = true;
      //storing the selected data into call flow API on didnpbx page
      this.$emit(
        "save-faxbox-data",
        this.selectedDragList,
        this.editedItem,
        this.editedIndex
      );
      this.closeOnSave();
    },
    async getParticularUserData(editFaxboxId) {
      if (editFaxboxId != undefined) {
        var userData = {
          accountId: this.userDetail.accountId,
          authToken: this.authToken,
          usersId: editFaxboxId,
        };
        // console.log("userData", userData);
        try {
          let response = await callFlow.getUsersDetails(userData);
          var faxbox = response.usersDetails;
          if (faxbox.last_name) {
            this.faxbox.name = faxbox.first_name + " " + faxbox.last_name;
            this.faxbox.outboundMail = faxbox.email;
            this.faxbox.inboundMail = faxbox.email;
            this.faxbox.id = faxbox.id;
          } else {
            this.faxbox.name = faxbox.first_name;
            this.faxbox.outboundMail.push(faxbox.email);
            this.faxbox.inboundMail.push(faxbox.email);
            this.faxbox.id = faxbox.id;
          }
          var editFaxbox = response.usersDetails;
          // console.log("this.editFaxbox=====", editFaxbox);
          if (!this.editFaxbox.name) {
            if (faxbox.last_name) {
              this.editFaxbox.name =
                editFaxbox.first_name + " " + editFaxbox.last_name;
            } else {
              this.editFaxbox.name = editFaxbox.first_name;
            }
          }
          this.editFaxbox.outboundMail = editFaxbox.email;
          this.editFaxbox.inboundMail = editFaxbox.email;
          // console.log("this.editFaxbox=====BBBB", this.editFaxbox);
        } catch (error) {
          console.log("====error===", error);
        }
      } else {
        this.faxbox = {};
        this.editFaxbox = {};
      }
    },
    closeOnSave() {
      this.$emit("close-on-save-faxbox-dialog");
    },
    closeFaxboxDialog() {
      // console.log("this.byDefualtAddData",this.byDefualtAddData)
      if (this.byDefualtAddData) {
        this.editedItem.data.id = "";
        this.editedItem.data.name = "";
        this.selectedValue = "";
      }
      this.closeOnSave();
    },
    closeAddNewFaxBoxDialog() {
      this.resetFormData();
      this.addNewFaxBoxDialog = false;
    },
    async saveNewFaxBox() {
      if (this.$refs.form.validate()) {
        this.loader = true;
        var faxBox = {
          accountId: this.userDetail.accountId,
          authToken: this.authToken,
          faxboxesData: {
            data: {
              name: this.faxbox.name,
              caller_name: "",
              fax_header: "",
              retries: "3",
              notifications: {
                inbound: {
                  email: {},
                },
                outbound: {
                  email: {},
                },
              },
              owner_id: this.faxbox.id,
              fax_identity: "",
              ui_metadata: {
                version: "4.3-139",
                ui: "monster-ui",
                origin: "callflows",
              },
            },
          },
        };
        if (this.faxbox.inboundMail.length != 0) {
          faxBox.faxboxesData.data.notifications.inbound.email.send_to = [];
          faxBox.faxboxesData.data.notifications.inbound.email.send_to =
            this.faxbox.inboundMail;
        }
        if (this.faxbox.outboundMail.length != 0) {
          faxBox.faxboxesData.data.notifications.outbound.email.send_to = [];
          faxBox.faxboxesData.data.notifications.outbound.email.send_to =
            this.faxbox.outboundMail;
        }
        try {
          let response = await callFlow.addFaxboxes(faxBox);
          this.loader = false;
          this.editedItem.data.id = response.responseData.data.id;
          this.getFaxboxes();
          this.resetFormData();
          this.addNewFaxBoxDialog = false;
          this.$root.$emit("SHOW_SNACKBAR", {
            text: response.messageDetail,
            color: "success",
          });
        } catch (error) {
          // this.isFormValid = false;
          this.$root.$emit("SHOW_SNACKBAR", {
            text: "something wrong",
            color: "error",
          });
          this.loader = false;
          console.log("====error===", error);
        }
      }
    },
    async saveEditFaxbox() {
      if (this.$refs.editForm.validate()) {
        this.loader = true;
        this.editLoader = true;
        var editFaxBoxData = {
          accountId: this.userDetail.accountId,
          authToken: this.authToken,
          faxboxesId: this.editedItem.data.id,
          editData: {
            data: {
              name: this.editFaxbox.name,
              caller_name: "",
              fax_header: "",
              retries: "3",
              notifications: {
                inbound: {
                  email: {},
                },
                outbound: {
                  email: {},
                },
              },
              fax_identity: "",
              ui_metadata: {
                version: "4.3-139",
                ui: "monster-ui",
                origin: "callflows",
              },
              attempts: 0,
              media: {},
              owner_id: this.editFaxbox.owner_id,
              id: this.editFaxbox.id,
              smtp_email_address: "2346b70b.fax.kazoo.io",
            },
          },
        };
        // console.log("this.editFaxbox.inboundMail", this.editFaxbox.inboundMail);
        if (this.editFaxbox.inboundMail.length != 0) {
          editFaxBoxData.editData.data.notifications.inbound.email.send_to = [];
          editFaxBoxData.editData.data.notifications.inbound.email.send_to =
            this.editFaxbox.inboundMail;
        }
        // console.log("this.editFaxbox.outboundMail", this.editFaxbox.outboundMail);
        if (this.editFaxbox.outboundMail.length != 0) {
          editFaxBoxData.editData.data.notifications.outbound.email.send_to =
            [];
          editFaxBoxData.editData.data.notifications.outbound.email.send_to =
            this.editFaxbox.outboundMail;
        }
        try {
          let response = await callFlow.editFaxboxesDetails(editFaxBoxData);
          this.getFaxboxes();
          this.loader = false;
          this.editLoader = false;
          this.editFaxBoxDialog = false;
          this.$root.$emit("SHOW_SNACKBAR", {
            text: response.messageDetail,
            color: "success",
          });
        } catch (error) {
          this.loader = false;
          this.editLoader = false;
          console.log("====error===", error);
          this.$root.$emit("SHOW_SNACKBAR", {
            text: "something wrong",
            color: "error",
          });
        }
      }
    },
    async editParticularFaxBoxDialog() {
      this.loader = true;
      var faxboxesData = {
        accountId: this.userDetail.accountId,
        authToken: this.authToken,
        faxboxesId: this.editedItem.data.id,
      };
      // console.log("faxboxesData", faxboxesData);
      try {
        let response = await callFlow.getFaxboxesDetails(faxboxesData);
        // console.log("response", response);
        var editFaxbox = response.faxboxesDetails;
        this.editFaxbox = editFaxbox;
        if (Object.keys(editFaxbox.notifications.inbound.email).length != 0) {
          this.editFaxbox.inboundMail =
            editFaxbox.notifications.inbound.email.send_to;
        } else {
          this.editFaxbox.inboundMail = [];
        }
        if (Object.keys(editFaxbox.notifications.outbound.email).length != 0) {
          this.editFaxbox.outboundMail =
            editFaxbox.notifications.outbound.email.send_to;
        } else {
          this.editFaxbox.outboundMail = [];
        }
        this.editFaxBoxDialog = true;
        this.loader = false;
      } catch (error) {
        console.log("====error===", error);
        this.loader = false;
      }
    },
    async deleteFaxboxes() {
      this.deleteLoader = true;
      var deleteMediaData = {
        accountId: this.userDetail.accountId,
        authToken: this.authToken,
        faxboxesId: this.editedItem.data.id,
      };
      try {
        let response = await callFlow.deleteFaxboxesDetails(deleteMediaData);
        this.editFaxbox = {
          notifications: {
            outbound: {
              email: {
                send_to: [],
              },
            },
          },
          notifications: {
            inbound: {
              email: {
                send_to: [],
              },
            },
          },
        };
        this.editedItem.data.id = "";
        // this.resetFormData();
        this.getFaxboxes();
        this.$root.$emit("SHOW_SNACKBAR", {
          text: response.messageDetail,
          color: "success",
        });
        this.editFaxBoxDialog = false;
        this.deleteLoader = false;
      } catch (error) {
        this.deleteLoader = false;
        console.log("====error===", error);
        this.$root.$emit("SHOW_SNACKBAR", {
          text: "something wrong",
          color: "error",
        });
      }
    },
    resetFormData() {
      this.$refs.form.reset();
    },
  },
};
</script>
<template>
  <section>
    <v-dialog v-model="addNewDeviceDialog" width="580" persistent>
      <v-card min-height="560">
        <v-card-title class="title">
          Create Device
          <v-icon @click="closeNewDeviceDialog" class="ml-auto">cancel</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="py-3">
            <v-chip
              class="mt-2 mb-2 mr-2"
              color="primary"
              :outlined="tab == 0 ? false : true"
              @click="tab = 0"
            >
              Cell/Landline Number
            </v-chip>
            <v-chip
              class="ma-2"
              color="primary"
              :outlined="tab == 1 ? false : true"
              @click="tab = 1"
            >
              SIP Devices
            </v-chip>
            <v-chip
              disabled
              class="ma-2"
              color="primary"
              :outlined="tab == 2 ? false : true"
              @click="tab = 2"
            >
              Smart pbx
            </v-chip>
          </div>
          <!-- cell phone -->
          <v-form
            ref="formCellPhone"
            v-model="isCellPhoneFormValid"
            lazy-validation
            v-if="tab == 0"
          >
            <v-row>
              <v-col cols="12" sm="12">
                <v-text-field
                  label="Name"
                  v-model="device.name"
                  hint="Friendly name for this sip device name"
                  color="primary"
                  :rules="cellPhoneRules"
                  outlined
                ></v-text-field>
                <v-select
                  v-if="showUser != 'newDeviceOnUser'"
                  label="Belongs To"
                  :items="usersList"
                  item-text="name"
                  item-value="id"
                  v-model="device.id"
                  outlined
                  color="primary"
                  clearable
                  hint="Select the owner of this device"
                  persistent-hint
                  hide-details
                >
                </v-select>
                <v-row
                  class="text-right mx-right"
                  v-if="showUser != 'newDeviceOnUser'"
                >
                  <v-col cols="12">
                    <v-btn
                      outlined
                      class="primary-btn"
                      small
                      @click="openAddNewUserDialog"
                      >Add a user</v-btn
                    >
                  </v-col>
                </v-row>
                <v-text-field
                  label="Number"
                  v-model="device.number"
                  placeholder="Enter Complete number with country code"
                  hint="Outside number you wish to reach"
                  type="number"
                  maxlength="15"
                  counter
                  color="primary"
                  outlined
                ></v-text-field>
                <v-checkbox
                  class="pt-0 mt-0"
                  label="Enabled"
                  v-model="device.enabled"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-card-actions class="pa-0">
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                outlined
                large
                class="px-5 mt-3"
                :loading="saveLoader"
                @click="saveNewDevice()"
                >Save
              </v-btn>
            </v-card-actions>
          </v-form>
          <!-- Soft phone -->
          <v-form
            ref="formSipPhone"
            v-model="isSoftPhoneFormValid"
            lazy-validation
            v-if="tab == 1"
          >
            <!-- <v-col cols="12"> -->
            <v-row>
              <v-col cols="12" sm="6">
                <p class="pl-1"><b>Basic:</b></p>
                <v-text-field
                  label="Name"
                  v-model="device.name"
                  hint="Friendly name for this device"
                  color="primary"
                  :rules="sipNameRule"
                  outlined
                ></v-text-field>
                <v-col cols="12" class="pl-0 py-0">
                  <v-row class="align-center">
                    <v-col cols="11" class="py-0 pr-0">
                      <v-text-field
                        label="Username"
                        v-model="device.username"
                        hint="Username"
                        color="primary"
                        hide-details
                        outlined
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="1" class="pa-0">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click="copied"
                            v-clipboard:copy="device.username"
                            icon
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon class="mt-2 pl-2"> content_copy </v-icon>
                          </v-btn>
                        </template>
                        <span>Copy</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-col>
                <v-select
                  class="pt-5"
                  label="Media"
                  :items="mediaList"
                  item-text="name"
                  item-value="id"
                  v-model="device.mediaId"
                  outlined
                  color="primary"
                  clearable
                  hide-details
                >
                </v-select>
                <v-row class="text-right mx-right">
                  <v-col cols="12">
                    <v-btn
                      outlined
                      class="primary-btn"
                      small
                      @click="openNewAddMediaDialog"
                      >Add a media</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
              <v-col sm="6">
                <p class="pl-1"><b>Enable outbound calls:</b></p>
                <v-select
                  label="Caller id number"
                  :items="subscriptionNewList"
                  item-text="didNumber"
                  item-value="didNumber"
                  v-model="device.callerIdNumber"
                  outlined
                  color="primary"
                  clearable
                >
                </v-select>
                <v-col cols="12" class="pa-0">
                  <v-row class="align-center">
                    <v-col cols="11" class="py-0 pr-0">
                      <v-text-field
                        label="Password"
                        v-model="device.password"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showPassword ? 'text' : 'password'"
                        @click:append="showPassword = !showPassword"
                        hint="Password"
                        color="primary"
                        hide-details
                        outlined
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="1" class="px-0">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click="copied"
                            v-clipboard:copy="device.password"
                            icon
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon class="mt-2 pl-2"> content_copy </v-icon>
                          </v-btn>
                        </template>
                        <span>Copy</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" class="px-0 pt-5">
                  <v-row class="align-center">
                    <v-col cols="11" class="py-0 pr-0">
                      <v-text-field
                        label="Realm//Sip Address"
                        v-model="userDetail.realm"
                        color="primary"
                        hide-details
                        outlined
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="1" class="pa-0">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click="copied"
                            v-clipboard:copy="userDetail.realm"
                            icon
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon class="mt-2 pl-2"> content_copy </v-icon>
                          </v-btn>
                        </template>
                        <span>Copy</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-col>
              </v-col>
            </v-row>
            <!-- </v-col> -->
            <v-row>
              <v-col cols="6" class="py-0" v-if="showUser != 'newDeviceOnUser'">
                <v-select
                  label="Belongs To"
                  :items="usersList"
                  item-text="name"
                  item-value="id"
                  v-model="device.id"
                  outlined
                  color="primary"
                  clearable
                  hide-details
                >
                </v-select>
                <v-row class="text-right mx-right">
                  <v-col cols="12">
                    <v-btn
                      outlined
                      class="primary-btn"
                      small
                      @click="openAddNewUserDialog"
                      >Add a user</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-row
                  ><v-col cols="6" class="py-0">
                    <p class="pl-1 mb-2"><b>Options:</b></p>
                    <v-checkbox
                      class="pt-0 mt-0"
                      label="T.38"
                      v-model="device.faxOption"
                    ></v-checkbox></v-col
                  ><v-col cols="6" class="py-0">
                    <!-- enable / disble -->
                    <v-checkbox
                      class="pt-4"
                      label="Enabled"
                      v-model="device.enabled"
                    ></v-checkbox></v-col
                ></v-row>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-card-actions class="pa-0">
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                outlined
                large
                class="px-5 mt-4"
                :loading="saveLoader"
                @click="saveNewDevice()"
                >Save
              </v-btn>
            </v-card-actions>
          </v-form>
          <!-- Smart pbx -->
          <v-form
            ref="formSmartPbx"
            v-model="isSmartPbxFormValid"
            lazy-validation
            v-if="tab == 2"
          >
            <v-card min-height="480" elevation="0">
              <v-row>
                <v-col cols="12">
                  <p class="pl-1"><b>Basic:</b></p>
                  <v-text-field
                    label="Server Name"
                    v-model="device.serverName"
                    hint="Friendly name for this device"
                    color="primary"
                    :rules="serverNameRule"
                    outlined
                  ></v-text-field>
                  <v-text-field
                    label="IP"
                    v-model="device.ip"
                    color="primary"
                    hide-details
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card>
            <v-card-actions class="pa-0">
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                outlined
                large
                class="px-5 mt-4"
                :loading="saveLoader"
                @click="saveNewDevice()"
                >Save
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Add media Dialog -->
    <add-new-media
      :addNewMediaDialogBySelect="addNewMediaDialogBySelect"
      @close-media="closeNewMedia"
    />
  </section>
</template>


<script>
import callFlow from "@/services/callFlowAPI.js";
import numberManagerAPI from "@/services/numberManagerAPI.js";
import addNewMedia from "./addNewMedia.vue";

export default {
  props: ["addNewDeviceDialog", "usersList", "showUser", "userId"],
  components: {
    addNewMedia,
  },
  data() {
    return {
      tab: 0,
      device: {
        enabled: true,
        number: "",
        faxOption: false,
      },
      cellPhoneRules: [(v) => !!v || "Name is required"],
      landlineNameRule: [(v) => !!v || "Name is required"],
      sipNameRule: [(v) => !!v || "Name is required"],
      serverNameRule: [(v) => !!v || "Name is required"],
      isCellPhoneFormValid: true,
      // isFormLandlineValid: true,
      isSmartPbxFormValid: true,
      isSoftPhoneFormValid: true,
      addNewUserDialog: false,
      saveLoader: false,
      addNewMediaDialogBySelect: false,
      showPassword: false,
      mediaData: [],
      mediaList: [],
      subscriptionNewList: [],
      userDetail: {},
      deviceData: {},
      authToken: "",
    };
  },
  mounted() {
    this.userDetail = JSON.parse(localStorage.getItem("userDetail"));
    this.authToken = localStorage.getItem("authToken");
    this.getMediaList();
    this.getUsernameForDevice();
    this.getSubscriptionNew();
  },
  methods: {
    setValue: function () {
      this.userDetail = JSON.parse(localStorage.getItem("userDetail"));
      this.tab = 0;
      this.getUsernameForDevice();
    },
    async getSubscriptionNew() {
      var payload = {
        accountId: this.userDetail.accountId,
      };
      try {
        let response = await numberManagerAPI.getDidNumberDetailSubscriptionNew(
          payload
        );
        if (response) {
          var subscriptionNewList = [];
          for (const key in response.spareNumbers.numbers) {
            subscriptionNewList.push({ didNumber: key });
          }
          this.subscriptionNewList = subscriptionNewList;
        }
      } catch (error) {
        console.log("====error===", error);
      }
    },
    async getMediaList() {
      this.mediaData = [];
      var getUsersData = {
        accountId: this.userDetail.accountId,
      };
      try {
        let response = await callFlow.getMediaList(getUsersData);
        this.mediaList.push({ name: "Silence" });
        this.mediaList = response.mediaList;
      } catch (error) {
        console.log("====error===", error);
      }
    },
    async getUsernameForDevice() {
      var getUsersData = {
        accountId: this.userDetail.accountId,
      };
      try {
        let response = await callFlow.getUsernameForDevice(getUsersData);
        this.device.username = response.usernameDetails.userName;
        this.device.password = response.usernameDetails.password;
        // console.log("this.device.username",this.device.username)
      } catch (error) {
        console.log("====error===", error);
      }
    },
    resetUserData() {
      if (this.tab === 0) {
        this.$refs.formCellPhone.reset();
      } else if (this.tab === 1) {
        this.$refs.formSipPhone.reset();
      }
    },
    closeNewDeviceDialog() {
      this.$emit("close-new-device-modal");
      this.$emit("showUserEnable");
      this.resetUserData();
    },
    async saveNewDevice() {
      // if (this.$refs.form.validate()) {
      this.getUsernameForDevice();
      if (this.showUser === "newDeviceOnUser") {
        if (this.userId) {
          this.device.id = this.userId;
        }
      }
      if (this.device.username) {
        var deviceJson = {
          data: {
            enabled: this.device.enabled,
            ringtones: {},
            ignore_completed_elsewhere: false,
            media: {
              audio: {
                codecs: ["PCMU", "PCMA"],
              },
              video: {
                codecs: [],
              },
              fax: {
                option: "false",
              },
              fax_option: false,
            },
            sip: {
              method: "password",
              invite_format: "username",
              username: this.device.username,
              password: this.device.password,
              expire_seconds: "360",
            },
            contact_list: {
              exclude: false,
            },
            call_forward: {
              enabled: this.device.enabled,
              require_keypress: false,
              number: this.device.number,
            },
            music_on_hold: {},
            name: this.device.name,
            owner_id: this.device.id,
            suppress_unregister_notifications: true,
            ui_metadata: {
              version: "4.3-139",
              ui: "monster-ui",
              origin: "callflows",
            },
          },
        };
        if (this.tab === 0) {
          deviceJson.data.device_type = "cellphone";
          this.deviceData = {
            accountId: this.userDetail.accountId,
            authToken: this.authToken,
            deviceData: deviceJson,
          };
          if (this.$refs.formCellPhone.validate()) {
            if (this.device.number.length != 0) {
              if (this.device.number.length > 12) {
                this.$root.$emit("SHOW_SNACKBAR", {
                  text: "Max 15 characters",
                  color: "error",
                });
              } else {
                this.submitDevice();
              }
            } else {
              this.submitDevice();
            }
          }
        } else if (this.tab === 1) {
          this.deviceData = {
            accountId: this.userDetail.accountId,
            authToken: this.authToken,
            deviceData: {
              data: {
                enabled: this.device.enabled,
                ringtones: {},
                ignore_completed_elsewhere: false,
                media: {
                  audio: {
                    codecs: [
                      "G7221@32000h",
                      "G7221@16000h",
                      "G722",
                      "speex@32000h",
                      "speex@16000h",
                      "PCMU",
                      "PCMA",
                      "G729",
                      "GSM",
                    ],
                  },
                  video: {
                    codecs: ["VP8", "H264", "H263", "H261"],
                  },
                  fax: {
                    option: this.device.faxOption,
                  },
                  fax_option: this.device.faxOption,
                  webrtc: false,
                  encryption: {
                    methods: [],
                    enforce_security: false,
                  },
                },
                // call_restriction: {
                //   closed_groups: {
                //     action: "inherit",
                //   },
                //   tollfree_us: {
                //     action: "inherit",
                //   },
                //   toll_us: {
                //     action: "inherit",
                //   },
                //   emergency: {
                //     action: "inherit",
                //   },
                //   caribbean: {
                //     action: "inherit",
                //   },
                //   did_us: {
                //     action: "inherit",
                //   },
                //   international: {
                //     action: "inherit",
                //   },
                //   unknown: {
                //     action: "inherit",
                //   },
                // },
                sip: {
                  method: "password",
                  invite_format: "username",
                  username: this.device.username,
                  password: this.device.password,
                  expire_seconds: "360",
                  // route: this.device.route,
                },
                contact_list: {
                  exclude: false,
                },
                call_forward: {
                  enabled: this.device.enabled,
                  require_keypress: false,
                  // number: this.device.number,
                },
                music_on_hold: {},
                device_type: "softphone",
                name: this.device.name,
                owner_id: this.device.id,
                suppress_unregister_notifications: true,
                ui_metadata: {
                  version: "4.3-139",
                  ui: "monster-ui",
                  origin: "callflows",
                },
              },
            },
          };
          if (this.device.callerIdNumber) {
            this.deviceData.deviceData.data.caller_id = {
              external: {},
            };
            this.deviceData.deviceData.data.caller_id.external = {
              name: this.device.callerIdNumber,
              number: this.device.callerIdNumber,
            };
          }
          if (this.device.mediaId) {
            this.deviceData.deviceData.data.music_on_hold = {
              media_id: this.device.mediaId,
            };
          }
          if (this.$refs.formSipPhone.validate()) {
            this.submitDevice();
          }
        }
      }
    },
    async submitDevice() {
      this.saveLoader = true;
      try {
        let response = await callFlow.addDevices(this.deviceData);
        this.saveLoader = false;
        var deviceId = response.responseData.data.id;
        this.$emit("getSoftPhoneData", deviceId);
        this.resetUserData();
        this.closeNewDeviceDialog();
        this.$root.$emit("SHOW_SNACKBAR", {
          text: response.messageDetail,
          color: "success",
        });
      } catch (error) {
        console.log("====error===", error);
        this.saveLoader = false;
        this.$root.$emit("SHOW_SNACKBAR", {
          text: "something wrong",
          color: "error",
        });
      }
    },
    openAddNewUserDialog() {
      this.addNewUserDialog = true;
      this.$emit("openAddNewUserData", this.addNewUserDialog);
    },
    openNewAddMediaDialog() {
      this.addNewMediaDialogBySelect = true;
    },
    closeNewMedia() {
      this.addNewMediaDialogBySelect = false;
      this.getMediaList();
    },
    getAllSubscriptionNumber() {
      this.getUsernameForDevice();
      this.getSpareNumbers();
    },
    copied() {
      this.$root.$emit("SHOW_SNACKBAR", {
        text: "Copied",
        color: "success",
      });
    },
  },
};
</script>
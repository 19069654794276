<template>
  <section>
    <v-card :loading="loader">
      <v-card-title class="title">
        Ring Group
        <v-icon @click="closeRingGroupDialog" class="ml-auto">cancel</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="py-0 pt-4">
        <v-form ref="form" v-model="isFormValid" lazy-validation>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                label="Name this ring group"
                v-model.trim="ringGroup.name"
                outlined
                color="primary"
                @keyup="setHeaderRingGroupName"
                dense
              >
                <!-- :rules="nameRules" -->
              </v-text-field>
              <v-select
                label="Ring Strategy"
                :items="ringStrategyList"
                v-model.trim="ringGroup.strategy"
                @change="setStrategy"
                item-text="text"
                item-value="value"
                outlined
                color="primary"
                hide-details
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Number of times to ring members"
                type="number"
                v-model.trim="ringGroup.repeats"
                outlined
                color="primary"
                dense
              >
              </v-text-field>
              <v-select
                label="Ringback"
                :items="mediaList"
                v-model.trim="ringGroup.ringback"
                item-text="name"
                item-value="id"
                outlined
                color="primary"
                clearable
                hide-details
                dense
              >
              </v-select>
            </v-col>
          </v-row>
        </v-form>

        <v-row class="text-right mx-right">
          <v-col cols="12">
            <v-btn
              outlined
              small
              class="primary-btn"
              @click="openNewAddMediaDialog"
              >Add a media</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>

      <!-- device,users,gruop card -->
      <v-col cols="12" class="py-0">
        <v-row>
          <v-col cols="12" sm="5">
            <v-card :loading="cardLoading">
              <v-tabs
                background-color="primary accent-4"
                dark
                :class="cardLoading ? 'pt-1' : 'pt-0'"
              >
                <v-tab class="caption">
                  <v-icon left size="18"> mdi-account-group-outline </v-icon>
                  Users
                </v-tab>
                <v-tab class="caption">
                  <v-icon left size="18"> person </v-icon> Devices
                </v-tab>
                <v-tab class="caption">
                  <v-icon left size="18"> mdi-phone-classic </v-icon> Groups
                </v-tab>
                <v-tab-item
                  ><v-card height="400" class="list-scroll" elevation="0">
                    <v-list>
                      <draggable
                        :list="usersList"
                        :group="{
                          name: 'ringGroup',
                          pull: 'clone',
                          put: false,
                        }"
                        @change="log"
                        class="action-list white px-0 mx-0"
                        :clone="cloneFlow"
                      >
                        <v-list-item
                          v-for="(users, i) in usersList"
                          :key="i"
                          class="pa-0 mx-0"
                        >
                          <v-list-item-content class="py-0">
                            <v-list-item-title
                              class="pl-3 py-3"
                              v-text="users.name"
                            ></v-list-item-title>
                            <v-divider></v-divider>
                          </v-list-item-content>
                        </v-list-item>
                      </draggable>
                    </v-list> </v-card
                ></v-tab-item>
                <v-tab-item
                  ><v-card
                    height="400"
                    class="list-scroll"
                    :loading="cardLoading"
                  >
                    <v-list>
                      <draggable
                        :list="deviceList"
                        :group="{
                          name: 'ringGroup',
                          pull: 'clone',
                          put: false,
                        }"
                        @change="log"
                        class="action-list white px-0 mx-0"
                        :clone="cloneFlow"
                      >
                        <v-list-item
                          v-for="(device, i) in deviceList"
                          :key="i"
                          class="pa-0 mx-0"
                        >
                          <v-list-item-content class="py-0">
                            <v-list-item-title
                              class="pl-3 py-3"
                              v-text="device.name"
                            ></v-list-item-title>
                            <v-divider></v-divider>
                          </v-list-item-content>
                        </v-list-item>
                      </draggable>
                    </v-list>
                  </v-card>
                </v-tab-item>
                <v-tab-item><v-card height="400"></v-card></v-tab-item>
              </v-tabs>
            </v-card>
          </v-col>
          <!-- {{ getDragData }} -->
          <!-- drag selected ring group values -->
          <v-col sm="7" class="ring-group-table">
            <v-card height="448" class="list-scroll" :loading="cardLoading">
              <draggable :list="getDragData" group="ringGroup" @change="log">
                <v-data-table
                  :headers="header"
                  :items="getDragData"
                  hide-default-footer
                >
                  <!-- <template v-slot:[`item.name`]="{ item }">
                    {{ item.name ? item.name : item.id | getModuleName  }}
                  </template> -->
                  <template
                    v-slot:[`item.delay`]="{ item }"
                    v-if="ringGroup.strategy != 'single'"
                  >
                    <v-text-field
                      v-model="item.delay"
                      dense
                      hide-details
                      type="number"
                    ></v-text-field>
                  </template>
                  <template v-slot:[`item.timeout`]="{ item }">
                    <v-text-field
                      v-model="item.timeout"
                      dense
                      hide-details
                      type="number"
                    ></v-text-field>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon color="red" @click="getSelectedRingGroupData(item)"
                      >delete</v-icon
                    >
                  </template>
                </v-data-table>
                <div>
                  <!-- {{ getDragData }} -->
                  <p
                    class="text-center pt-15 subtitle-1"
                    v-if="getDragData.length == 0"
                  >
                    Drag an endpoint here to <br />add it to this group
                  </p>
                </div>
              </draggable>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="py-1">
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined large @click="save()">Save </v-btn>
        </v-card-actions>
      </v-col>
    </v-card>

    <!-- assigned owner action modal -->
    <v-dialog v-model="showAlreadyAssignOwner" width="500">
      <v-card>
        <v-card-title class="grey lighten-2"> Confirmation </v-card-title>
        <v-card-title>
          <span
            >This user has already some devices belonging to them in this ring
            group. By adding them to the ring group, you will remove devices
            that were already in the ring group. Would you like to continue
            anyway?</span
          >
          <br />
        </v-card-title>
        <v-card-actions class="px-4">
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            outlined
            @click="showAlreadyAssignOwner = false"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Add media Dialog -->
    <add-new-media
      :addNewMediaDialogBySelect="addNewMediaDialogBySelect"
      @close-media="closeNewMedia"
    />
    <!-- end: Add media Dialog  -->
  </section>
</template>

<script>
import callFlow from "@/services/callFlowAPI.js";
import addNewMedia from "./addNewMedia.vue";
import draggable from "vuedraggable";

export default {
  props: ["editedItem", "selectedDragList", "editedIndex"],
  components: {
    addNewMedia,
    draggable,
  },
  data() {
    return {
      ringGroupList: [],
      ringStrategyList: [
        {
          text: "At the same time",
          value: "simultaneous",
        },
        {
          text: "In order",
          value: "single",
        },
      ],
      ringGroup: {},
      mediaList: [],
      recordPinRules: [],
      editNumberRules: [],
      userDetail: {},
      loader: false,
      isFormValid: true,
      saveLoader: false,
      tableIndex: -1,
      editedItemModal: false,
      addNewMediaDialogBySelect: false,
      cardLoading: false,
      showAlreadyAssignOwner: false,
      selectedValue: "",
      authToken: "",
      usersList: [],
      deviceList: [],
      getDragData: [],
      controlOnStart: true,
      header: [],
      ringGroupData: {},
      ringGroupEndPoints: [],
      userRingGroupEndPoints: [],
      defaultHeader: [
        {
          text: "Ring Group",
          value: "name",
          width: "40%",
          sortable: false,
        },
        {
          text: "Delay(s)",
          value: "delay",
          sortable: false,
        },
        {
          text: "Timeout(s)",
          value: "timeout",
          sortable: false,
        },
        {
          text: "Actions",
          value: "actions",
          // align: "center",
          sortable: true,
        },
      ],
      singleHeader: [
        {
          text: "Ring Group",
          value: "name",
          width: "40%",
          sortable: false,
        },
        {
          text: "Timeout(s)",
          value: "timeout",
          sortable: false,
        },
        {
          text: "Actions",
          value: "actions",
          align: "center",
          sortable: true,
        },
      ],
    };
  },
  mounted() {
    this.userDetail = JSON.parse(localStorage.getItem("userDetail"));
    this.authToken = localStorage.getItem("authToken");
    this.header = this.defaultHeader;
    this.getMediaList();
  },
  methods: {
    setValue: function (ringGroupData) {
      this.ringGroupData = {};
      this.ringGroup = {};
      this.ringGroupData = ringGroupData;
      this.ringGroup = this.ringGroupData;
      if (
        this.ringGroupData &&
        this.ringGroupData.endpoints &&
        this.ringGroupData.endpoints[0]
      ) {
        this.ringGroupData.endpoints = this.ringGroupData.endpoints;
      } else {
        this.ringGroupData.endpoints = [];
      }

      if (this.ringGroup.ringback == undefined) {
        this.ringGroup.ringback = "default";
      }
      this.getUsersList();
    },
    async getUsersList() {
      // let ringGroup = {};
      this.cardLoading = true;
      var getUsersData = {
        accountId: this.userDetail.accountId,
        userId: this.userDetail.userId,
        forwardType: "SOFTPHONE",
      };
      this.usersList = [];
      var usersList = [];
      var userRingGroupEndPoints = [];
      this.userRingGroupEndPoints = [];
      var editedIndex = -1;
      var userData = {};

      // this.getDragData = [];
      try {
        let response = await callFlow.getUsersList(getUsersData);
        // var unselectedUserList = {};
        for (let index = 0; index < response.usersList.length; index++) {
          const element = response.usersList[index];
          if (element.last_name === undefined || element.last_name === null) {
            element.name = element.first_name;
          } else {
            element.name = element.first_name + " " + element.last_name;
          }
          element.endpoint_type = "user";
          element.delay = 0;
          element.timeout = 20;
          userData = element;
          usersList.push(userData);
          if (
            this.ringGroupData &&
            this.ringGroupData.endpoints &&
            this.ringGroupData.endpoints.length != 0
          ) {
            for (
              let index = 0;
              index < this.ringGroupData.endpoints.length;
              index++
            ) {
              // ringGroup = {};
              let ringGroup = this.ringGroupData.endpoints[index][0];
              if (ringGroup.endpoint_type == "user") {
                // userData = {};
                if (ringGroup.id == element.id) {
                  ringGroup.name = element.name ? element.name : "-";
                  userRingGroupEndPoints.push(ringGroup);
                  // if selected device in endpoints then deleted element
                  editedIndex = usersList.indexOf(element);
                  usersList.splice(editedIndex, 1);
                }
              }
            }
          }
        }
        this.userRingGroupEndPoints = userRingGroupEndPoints;
        this.usersList = usersList;
        this.getDeviceList();
        // this.cardLoading = false;
      } catch (error) {
        this.cardLoading = false;
      }
    },
    async getMediaList() {
      this.mediaList = [];
      var defaultMedia = [
        {
          name: "Default",
          id: "default",
        },
        {
          name: "Silence",
          id: "silence_stream://300000",
        },
      ];
      var getUsersData = {
        accountId: this.userDetail.accountId,
      };

      try {
        let response = await callFlow.getMediaList(getUsersData);
        this.mediaList = [...defaultMedia, ...response.mediaList];
      } catch (error) {}
    },
    async getDeviceList() {
      this.cardLoading = true;
      this.deviceList = [];
      var deviceList = [];
      var deviceData = {};
      var ringGroupEndPoints = [];
      var editedIndex = -1;

      var getFilteredDeviceData = {
        accountId: this.userDetail.accountId,
      };
      try {
        let response = await callFlow.getDeviceList(getFilteredDeviceData);

        for (let index = 0; index < response.deviceList.length; index++) {
          const element = response.deviceList[index];
          element.endpoint_type = "device";
          element.delay = 0;
          element.timeout = 20;
          deviceData = element;
          deviceList.push(deviceData);
          if (
            this.ringGroupData &&
            this.ringGroupData.endpoints &&
            this.ringGroupData.endpoints.length != 0
          ) {
            for (
              let index = 0;
              index < this.ringGroupData.endpoints.length;
              index++
            ) {
              // ringGroup = {};
              let ringGroup = this.ringGroupData.endpoints[index][0];
              if (ringGroup.endpoint_type == "device") {
                if (ringGroup.id == element.id) {
                  ringGroup.name = element.name ? element.name : "-";
                  ringGroupEndPoints.push(ringGroup);
                  // if selected device in endpoints then deleted element
                  editedIndex = deviceList.indexOf(element);
                  deviceList.splice(editedIndex, 1);
                }
              }
            }
          }
        }

        this.getDragData =
          this.userRingGroupEndPoints.concat(ringGroupEndPoints);
        this.deviceList = deviceList;

        if (this.ringGroupData.strategy == "single") {
          this.header = this.singleHeader;
        } else {
          this.header = this.defaultHeader;
        }
        if (this.ringGroup && this.ringGroup.name) {
          this.header[0].text = "";
          this.setHeaderRingGroupName();
        }
        this.cardLoading = false;
      } catch (error) {
        this.cardLoading = false;
      }
    },
    closeAddNewRingGroupDialog() {
      this.addNewRingGroupDialog = false;
      // this.resetFormData();
    },
    save() {
      if (this.ringGroup.name) {
        var endPointsArray = [];
        for (let index = 0; index < this.getDragData.length; index++) {
          var endPointsData = {};
          const element = this.getDragData[index];
          endPointsData = [
            {
              id: element.id,
              endpoint_type: element.endpoint_type,
              timeout: element.timeout,
              // name: element.name,
              delay: element.delay,
            },
          ];
          if (element.delay != undefined) {
            endPointsData.delay = element.delay;
          }
          endPointsArray.push(endPointsData);
        }
        // get the selected module properties from editedItem obj
        var editedItem = {};
        editedItem = this.editedItem;
        editedItem.data = {
          name: this.ringGroup.name,
          timeout: 20,
          endpoints: [],
          // icon: this.editedItem.data.icon,
          // ignore_forward: true,
        };
        if (this.ringGroup.strategy) {
          editedItem.data.strategy = this.ringGroup.strategy;
        }
        if (this.ringGroup.repeats) {
          editedItem.data.repeats = this.ringGroup.repeats.toString();
        }
        if (endPointsArray.length != 0) {
          editedItem.data.endpoints = endPointsArray;
        }
        if (this.ringGroup.ringback) {
          if (this.ringGroup.ringback == "default") {
            delete this.ringGroup.ringback;
          } else {
            editedItem.data.ringback = this.ringGroup.ringback;
          }
        }
        this.editedItem.data = editedItem.data;
        //this.selectedDragList[this.editedIndex] = this.editedItem;
        this.editedItemModal = true;
        //storing the selected data into call flow API on didnpbx page
        this.$emit(
          "save-ringGroup-data",
          this.selectedDragList,
          this.editedItem,
          this.editedIndex
        );
        this.closeOnSave();
      } else {
        this.$root.$emit("SHOW_SNACKBAR", {
          text: "Name is required!",
          color: "error",
        });
      }
    },
    closeOnSave() {
      this.$emit("close-on-save-ringGroup-dialog");
    },
    closeRingGroupDialog() {
      // this.ringGroup = {};
      this.closeOnSave();
    },
    openNewAddMediaDialog() {
      this.addNewMediaDialogBySelect = true;
    },
    closeNewMedia() {
      this.addNewMediaDialogBySelect = false;
      this.getMediaList();
    },
    cloneFlow(evt) {
      const getOwnerId = this.getDragData.find(
        (dragData) => dragData.id == evt.owner_id
      );
      if (getOwnerId) {
        this.showAlreadyAssignOwner = true;
      } else {
        return evt;
      }
    },
    log: function (evt) {
      var getFlowData = {};
      getFlowData = evt.added.element;
      if (getFlowData.endpoint_type === "user") {
        this.tableIndex = this.usersList.indexOf(getFlowData);
        this.usersList.splice(this.tableIndex, 1);
      }
      if (getFlowData.endpoint_type === "device") {
        this.tableIndex = this.deviceList.indexOf(getFlowData);
        this.deviceList.splice(this.tableIndex, 1);
      }
    },
    getSelectedRingGroupData(item) {
      var selectedValue = {};
      selectedValue = item;
      var index = -1;
      if (selectedValue.endpoint_type === "user") {
        this.usersList.push(selectedValue);
        index = this.getDragData.indexOf(selectedValue);
        this.getDragData.splice(index, 1);
      }
      if (selectedValue.endpoint_type === "device") {
        this.deviceList.push(selectedValue);
        index = this.getDragData.indexOf(selectedValue);
        this.getDragData.splice(index, 1);
      }
    },
    setHeaderRingGroupName() {
      this.header[0].text = "Ring Group" + " - " + this.ringGroup.name;
    },
    setStrategy() {
      if (this.ringGroup.strategy === "single") {
        this.header = this.singleHeader;
        var deviceArray = [];
        var userArray = [];
        var getDragDataArray = [];
        for (let index = 0; index < this.deviceList.length; index++) {
          const element = this.deviceList[index];
          delete element.delay;
          deviceArray.push(element);
        }
        this.deviceList = deviceArray;
        for (let index = 0; index < this.usersList.length; index++) {
          const userObj = this.usersList[index];
          delete userObj.delay;
          userArray.push(userObj);
        }
        this.usersList = userArray;
        for (let index = 0; index < this.getDragData.length; index++) {
          const dragDataObj = this.getDragData[index];
          delete dragDataObj.delay;
          getDragDataArray.push(dragDataObj);
        }
        this.getDragData = getDragDataArray;
      } else {
        this.header = this.defaultHeader;
        var deviceArray = [];
        var userArray = [];
        var getDragDataArray = [];
        for (let index = 0; index < this.deviceList.length; index++) {
          const element = this.deviceList[index];
          element.delay = 0;
          deviceArray.push(element);
        }
        this.deviceList = deviceArray;
        for (let index = 0; index < this.usersList.length; index++) {
          const userObj = this.usersList[index];
          userObj.delay = 0;
          userArray.push(userObj);
        }
        this.usersList = userArray;
        for (let index = 0; index < this.getDragData.length; index++) {
          const dragDataObj = this.getDragData[index];
          dragDataObj.delay = 0;
          getDragDataArray.push(dragDataObj);
        }
        this.getDragData = getDragDataArray;
      }
    },
  },
};
</script>

<style>
.ring-group-table,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-size: 12px !important;
  font-weight: 500 !important;
}

.ring-group-table,
.v-list-item {
  min-height: 0 !important;
}
.list-scroll {
  overflow: auto;
}
</style>
const faxModuleMenu = [
    {
        module: "fax_detect",
        name: "Fax Detect",
        data: {},
        icon: "mdi-email-outline",
        tooltip: "Detects fax on incoming call",
        dropEnable: false
    },
    {
        module: "faxbox",
        name: "Fax Boxes ",
        data: {},
        icon: "mdi mdi-printer",
        tooltip: "Answer and keep silence",
        dropEnable: false
    },
]


export default { faxModuleMenu };
<template>
  <section>
    <v-card :loading="loader">
      <v-card-title class="title">
        Menu
        <v-icon @click="closeOnSave" class="ml-auto">cancel</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <!-- <p>Menu Options</p> -->
        <!-- {{ getOptionKey }} -->
        <v-form ref="form" v-model="isFormValid" lazy-validation>
          <v-select
            label="Select Menu Options"
            :items="menuList"
            item-text="text"
            item-value="value"
            v-model="getOptionKey.value"
            @change="getMenuOption(getOptionKey.value)"
            outlined
            color="primary"
            return-object
            :rules="nameRules"
          >
          </v-select>
          <v-btn block color="primary" outlined large @click="save()"
            >Save
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </section>
</template>

<script>
export default {
  props: [
    "editedItem",
    "selectedDragList",
    "editedIndex",
    "selectMenuOption",
    "getOptionKey",
    "optionModule",
    "menuList",
    "selectedStaticKey",
  ],
  data() {
    return {
      // getMenuData:{}
      isFormValid: true,
      loader: false,
      selectedOptionModule: {},
      nameRules: [(v) => !!v || "Field is required"],
    };
  },
  mounted() {},
  methods: {
    closeAddNewMenuDialog() {
      this.addNewMenuDialog = false;
      this.resetFormData();
    },
    getMenuOption(getSelectedOptionKey) {
      // let metaData = {};
      // if (JSON.parse(localStorage.getItem("metaData"))) {
      //   metaData = JSON.parse(localStorage.getItem("metaData"));
      // }
      // metaData[this.getOptionKey.value.value] = {
      //   name: this.getOptionKey.value.text,
      // };

      // localStorage.setItem("metaData", JSON.stringify(metaData));

      // if user selected any value
      this.selectedOptionModule = {};
      // console.log(
      //   "Object.keys(this.editedItem).length ",
      //   Object.keys(this.editedItem).length,
      //   "this.editedItem",
      //   this.editedItem
      // );

      if (Object.keys(this.editedItem).length != 1) {
        delete Object.assign(this.editedItem, {
          [getSelectedOptionKey.value]: this.editedItem[this.selectedStaticKey],
        })[this.selectedStaticKey];
        this.selectedOptionModule = this.editedItem;
      } else {
        // console.log("this.editedItem", this.editedItem);
        for (const key in this.editedItem) {
          if (this.editedItem.hasOwnProperty.call(this.editedItem, key)) {
            const element = this.editedItem[key];
            // console.log("element", element);
            this.selectedOptionModule[getSelectedOptionKey.value] = element;
          }
        }
      }
    },
    save() {
      if (this.$refs.form.validate()) {
        // console.log(
        //   "Object.keys(this.editedItem).length ",
        //   Object.keys(this.editedItem).length,
        //   "this.editedItem",
        //   this.editedItem
        // );

        // console.log("this.getOptionKey.value ", this.getOptionKey.value);

        // if user not selected any value
        if (this.getOptionKey.value == "_") {
          this.selectedOptionModule = {};
          if (Object.keys(this.editedItem).length != 1) {
            delete Object.assign(this.editedItem, {
              [this.getOptionKey.value]:
                this.editedItem[this.selectedStaticKey],
            })[this.selectedStaticKey];
            this.selectedOptionModule = this.editedItem;
          } else {
            // console.log("this.editedItem", this.editedItem);
            for (const key in this.editedItem) {
              if (this.editedItem.hasOwnProperty.call(this.editedItem, key)) {
                const element = this.editedItem[key];
                // console.log("element", element);
                this.selectedOptionModule[this.getOptionKey.value] = element;
              }
            }
          }
          this.saveOptionData();
        }

        if (this.getOptionKey.value == this.selectedStaticKey) {
          this.closeOnSave();
        } else {
          this.saveOptionData();
        }
      }
    },
    async saveOptionData() {
      if (this.optionModule) {
        this.selectedDragList[this.editedIndex] = this.selectedOptionModule;
      } else {
        this.$set(
          this.selectedDragList,
          this.editedIndex,
          this.selectedOptionModule
        );
      }
      // get the selected module properties from editedItem obj
      //storing the selected data into call flow API on didnpbx page
      this.$emit(
        "save-menu-option-data",
        this.selectedDragList,
        this.editedItem,
        this.editedIndex
      );
      this.closeOnSave();
    },
    closeOnSave() {
      this.$emit("close-on-save-menu-option-dialog");
    },
  },
};
</script>
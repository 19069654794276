const callFLow = [
    {
        module: "callflow",
        name: "Link to another callflow",
        data: {},
        children: {},
        icon: "call_merge",
        tooltip: "Transfer the call to another call flow",
        dropEnable: false
    },
]


export default { callFLow };
<template>
  <v-dialog v-model="addNewMediaDialogBySelect" width="400" persistent>
    <v-card>
      <v-card-title class="title">
        Create Media
        <v-icon @click="closeAddNewMediaDialog()" class="ml-auto"
          >cancel</v-icon
        >
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="form" v-model="isFormValid" lazy-validation>
          <p class="pt-3"><b>Basic</b></p>
          <v-text-field
            label="Name"
            v-model="media.name"
            color="primary"
            hint="Friendly name for this media file"
            outlined
            :rules="nameRules"
          ></v-text-field>
          <v-select
            label="Media Type"
            :items="mediaTypeList"
            v-model="media.media_source"
            outlined
            clearable
            color="primary"
          >
          </v-select>
          <v-file-input
            v-if="media.media_source != 'tts'"
            label="File input"
            outlined
            show-size
            v-model="media.upload"
            placeholder="No file selected"
            append-icon="mdi mdi-paperclip"
            @change="createBinaryData"
            color="primary"
          ></v-file-input>
          <span v-if="media.media_source == 'tts'">
            <v-textarea
              label="Text"
              v-model="media.tts.text"
              placeholder="Text to speech"
              outlined
              color="primary"
            ></v-textarea>
            <v-select
              label="Select"
              :items="selectSpeechType"
              v-model="media.tts.voice"
              outlined
              color="primary"
              clearable
            >
            </v-select>
          </span>
          <!-- Streamable -->
          <p><b>Advanced Options</b></p>
          <v-checkbox
            class="pt-0 mt-0"
            label="Streamable"
            v-model="media.streamable"
          ></v-checkbox>
          <v-btn
            color="primary"
            outlined
            class="px-5"
            large
            :loading="saveLoader"
            @click="saveNewMedia()"
            block
            >Save
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>


<script>
import getSpechType from "./jsFile/speechType";
import callFlow from "@/services/callFlowAPI.js";

export default {
  props: {
    addNewMediaDialogBySelect: Boolean,
  },
  data() {
    return {
      addNewMediaDialog: null,
      saveLoader: false,
      isFormValid: true,
      nameRules: [(v) => !!v || "Name is required"],
      authToken: "",
      userDetail: "",
      media: {
        tts: {},
        streamable: true,
      },
      rawData: [],
      mediaType: "",
      mediaId: "",
      selectSpeechType: [],
      mediaTypeList: [
        {
          text: "File",
          value: "upload",
        },
        {
          text: "TTS",
          value: "tts",
        },
      ],
    };
  },
  mounted() {
    this.selectSpeechType = getSpechType.speechType;
    this.media.tts.voice = getSpechType.speechType[0];
    this.userDetail = JSON.parse(localStorage.getItem("userDetail"));
    this.authToken = localStorage.getItem("authToken");
  },
  methods: {
    createBinaryData() {
      this.rawData = [];
      var this_ = this;
      const fileSize = Math.round(this.media.upload.size / 1024);
      if (this.media.upload) {
        if (
          this.media.upload.type === "audio/mp3" ||
          this.media.upload.type === "audio/mpeg"
          // this.media.upload.type === "audio/mpeg3" ||
          // this.media.upload.type === "audio/x-wav" ||
          // this.media.upload.type === "audio/wav" ||
          // this.media.upload.type === "audio/ogg" ||
          // this.media.upload.type === "video/x-flv" ||
          // this.media.upload.type === "video/h264" ||
          // this.media.upload.type === "video/mpeg" ||
          // this.media.upload.type === "video/quicktime" ||
          // this.media.upload.type === "video/mp4" ||
          // this.media.upload.type === "video/webm"
        ) {
          if (fileSize <= 3072) {
            this.mediaType = "success";
            if (this.media.upload) {
              var files = [];
              files.push(this.media.upload);
              if (files.length > 0) {
                var reader = new FileReader();
                var file = "updating";
                reader.onloadend = function (evt) {
                  var data = evt.target.result;
                  file = data;
                  this_.rawData = file;
                };
                reader.readAsDataURL(files[0]);
              }
            }
          } else {
            this.mediaType = "error";
            this.media.upload = {};
            this.$root.$emit("SHOW_SNACKBAR", {
              text: "Only Upload Mp3 Format under 3 MB",
              color: "error",
            });
          }
        } else {
          this.mediaType = "error";
          this.media.upload = {};
          this.$root.$emit("SHOW_SNACKBAR", {
            text: "Only Upload Mp3 Format under 3 MB",
            color: "error",
          });
        }
      }
    },
    async saveNewMedia() {
      if (this.$refs.form.validate()) {
        this.saveLoader = true;
        var mediaData = {
          accountId: this.userDetail.accountId,
          authToken: this.authToken,
          mediaData: {
            data: {
              streamable: this.media.streamable,
              name: this.media.name,
              media_source: this.media.media_source,
              authToken: "",
              tts: {
                text: this.media.tts.text,
                voice: this.media.tts.voice,
              },
              ui_metadata: {
                version: "4.3-139",
                ui: "monster-ui",
                origin: "callflows",
              },
            },
          },
        };

        if (this.media.upload) {
          mediaData.mediaData.data.description =
            "C:\\fakepath\\" + this.media.upload.name;
        }
        if (this.rawData) {
          mediaData.rawData = this.rawData;
        }
        if (this.mediaType === "error") {
          this.$root.$emit("SHOW_SNACKBAR", {
            text: "This is an invalid upload file.",
            color: "error",
          });
          this.saveLoader = false;
        } else {
          try {
            let response = await callFlow.addMedia(mediaData);
            if (
              response &&
              response.responseData &&
              response.responseData.data &&
              response.responseData.data.id
            ) {
              this.mediaId = response.responseData.data.id;
            }
            this.saveLoader = false;
            this.closeAddNewMediaDialog();
            this.mediaType = "";
            this.$root.$emit("SHOW_SNACKBAR", {
              text: response.responseMessage,
              color: "success",
            });
          } catch (error) {
            this.saveLoader = true;
            this.$root.$emit("SHOW_SNACKBAR", {
              text: "something wrong",
              color: "error",
            });
          }
        }
      }
    },
    closeAddNewMediaDialog() {
      this.$emit("close-media", this.mediaId);
      this.saveLoader = false;
      this.resetFormData();
    },
    resetFormData() {
      this.$refs.form.reset();
    },
  },
};
</script>
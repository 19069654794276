const actionListMenu = [
    {
        module: "device",
        name: "Device",
        data: {
            can_call_self: false,
            timeout: "20",
            id: "",
        },
        children: {},
        icon: "phone_iphone",
        tooltip: "Ring a VoIP or cell phone or other device",
        dropEnable: false
    },
    {
        name: "User",
        module: "user",
        data: {
            can_call_self: false,
            timeout: "20",
            id: "",
        },
        tooltip: "Direct a caller to a specific user",
        icon: "mdi-account-group-outline",
        showTooltip: false,
        dropEnable: false
    },
    {
        module: "play",
        name: "Media",
        data: {},
        tooltip: "Play an audio file such as a greeting",
        icon: "mdi mdi-music-note",
        showTooltip: false,
        dropEnable: false

    },
    {
        module: "menu", name: "Menu",
        data: {},
        icon: "mdi-view-comfy",
        tooltip: "Ask a caller to push a menu option <br /> or dial an extension number",
        showTooltip: false,
        dropEnable: false

    },
    {
        module: "conference",
        name: "Conference",
        data: {},
        icon: "mdi-wechat",
        tooltip: "Connect a caller to a Meet-Me <br /> conference bridge",
        showTooltip: false,
        dropEnable: false


    },
    {
        module: "ring_group",
        name: "Ring Group",
        data: {},
        icon: "ring_volume",
        tooltip: "Ring several VoIP or cell phones in order <br /> or at the same time",
        showTooltip: false,
        dropEnable: false

    },
]


export default { actionListMenu };
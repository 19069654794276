<template>
  <section>
    <v-card :loading="loader">
      <v-card-title class="title">
        Conference
        <v-icon @click="closeConferenceDialog" class="ml-auto">cancel</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <p>Connect caller to conference...</p>
        <v-select
          label="Which Conference?"
          :items="conferenceList"
          item-text="name"
          item-value="id"
          v-model="editedItem.data.id"
          outlined
          color="primary"
        >
          <template slot="item" slot-scope="data">
            <v-list-item-content @click="getItemText(data.item.name)">
              <v-list-item-title v-html="data.item.name"></v-list-item-title>
            </v-list-item-content>
          </template>
        </v-select>
        <v-row>
          <v-col cols="12" sm="6">
            <v-btn
              block
              color="green"
              outlined
              large
              :disabled="disabledEditButton"
              @click="editParticularConferenceDialog(editedItem)"
            >
              Edit</v-btn
            >
          </v-col>
          <v-col cols="12" sm="6">
            <v-btn
              block
              color="info"
              outlined
              large
              @click="addNewConferenceDialog = true"
            >
              Add
            </v-btn>
          </v-col>
        </v-row>
        <v-btn block color="primary" outlined large @click="save()"
          >Save
        </v-btn>
      </v-card-text>
    </v-card>
    <!-- Add conference Dialog -->
    <v-dialog v-model="addNewConferenceDialog" width="400" persistent>
      <v-card>
        <v-card-title class="title">
          Create Conference
          <v-icon @click="closeAddNewConferenceDialog()" class="ml-auto"
            >cancel</v-icon
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="form" v-model="isFormValid" lazy-validation>
            <v-text-field
              class="pt-5"
              label="Conference Name"
              hint="Friendly name for this conference"
              v-model="conference.name"
              color="primary"
              :rules="nameRules"
              outlined
            ></v-text-field>
            <v-text-field
              label="PINs"
              v-model="conference.pins"
              color="primary"
              hint="A comma separated list of pins, that can be entered to join the conference"
              outlined
              type="text"
            ></v-text-field>
            <v-select
              label="Assign To"
              :items="usersList"
              item-text="name"
              item-value="id"
              v-model="conference.owner_id"
              outlined
              color="primary"
              clearable
              hint="Select the user who owns this conference"
              persistent-hint
            >
            </v-select>
            <v-row class="text-right mx-right">
              <v-col cols="12">
                <v-btn text class="primary-btn" @click="openAddNewUserDialog"
                  >Add a user</v-btn
                >
              </v-col>
            </v-row>
            <v-btn
              color="primary"
              outlined
              class="px-5"
              large
              block
              :loading="saveLoader"
              @click="saveNewConference()"
              >Save
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Edit conference Dialog -->
    <v-dialog v-model="editConferenceDialog" width="400" persistent>
      <v-card>
        <v-card-title class="title">
          Edit Conference
          <v-icon @click="editConferenceDialog = false" class="ml-auto"
            >cancel</v-icon
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-text-field
            class="pt-5"
            label="Conference Name"
            v-model="editConference.name"
            hint="Friendly name for this conference"
            color="primary"
            outlined
          ></v-text-field>
          <v-text-field
            label="PINs"
            v-model="editConference.member.pins"
            hint="A comma separated list of pins, that can be entered to join the conference"
            type="text"
            color="primary"
            outlined
          ></v-text-field>
          <v-select
            label="Assign To"
            :items="usersList"
            item-text="name"
            item-value="id"
            v-model="editConference.owner_id"
            outlined
            color="primary"
            clearable
          >
          </v-select>
          <v-row>
            <v-col xs="6">
              <v-btn
                color="error"
                outlined
                large
                @click="deleteConferencesDetails()"
                block
                :loading="deleteLoader"
                >Delete
              </v-btn>
            </v-col>
            <v-col xs="6">
              <v-btn
                color="primary"
                outlined
                class="px-5"
                large
                block
                :loading="editLoader"
                @click="editConferenceData()"
                >Save
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Add new user Dialog -->
    <add-new-user-dialog
      :addNewUserDialog="addNewUserDialog"
      @close-new-user-dialog="closeAddNewUserDialog"
    />
  </section>
</template>

<script>
import callFlow from "@/services/callFlowAPI.js";
import addNewUserDialog from "./addNewUserDialog";

export default {
  props: ["editedItem", "selectedDragList", "editedIndex"],
  components: {
    addNewUserDialog,
  },
  data() {
    return {
      conferenceList: [],
      conference: {
        pins: [],
      },
      usersList: [],
      editConference: {
        member: {
          pins: "",
        },
      },
      authToken: "",
      selectedValue: "",
      addNewConferenceDialog: false,
      editConferenceDialog: false,
      conferenceTypeList: [],
      loader: false,
      editLoader: false,
      isFormValid: true,
      disabledEditButton: false,
      saveLoader: false,
      deleteLoader: false,
      nameRules: [(v) => !!v || "Name is required"],
      editedItemModal: false,
      addNewUserDialog: false,
      byDefualtAddData: true,
    };
  },
  mounted() {
    this.userDetail = JSON.parse(localStorage.getItem("userDetail"));
    this.authToken = localStorage.getItem("authToken");
    this.getUsersList();
    this.getConferencesList();
  },
  methods: {
    async getUsersList() {
      var getUsersData = {
        accountId: this.userDetail.accountId,
        userId: this.userDetail.userId,
        forwardType: "SOFTPHONE",
      };
      try {
        let response = await callFlow.getUsersList(getUsersData);
        var userData = {};
        for (let index = 0; index < response.usersList.length; index++) {
          const element = response.usersList[index];
          element.name = element.first_name + " " + element.last_name;
          userData = element;
          this.usersList.push(userData);
        }
      } catch (error) {
        console.log("====error===", error);
      }
    },
    async getConferencesList() {
      this.loader = true;
      this.conferenceList = [];
      var getConferencesData = {
        accountId: this.userDetail.accountId,
      };
      try {
        let response = await callFlow.getConferencesList(getConferencesData);
        // console.log("response----getMediaList ", response);
        this.conferenceList = response.conferencesList;
        if (this.conferenceList.length != 0) {
          if (this.editConference.id) {
            this.editedItem.data.id = this.editConference.id;
            this.editedItem.data.name = this.editConference.name;
            this.selectedValue = this.editedItem.data.name;
            this.byDefualtAddData = false;
          } else {
            if (this.editedItem.data.id) {
              this.editedItemModal = false;
              for (let index = 0; index < this.conferenceList.length; index++) {
                const conferenceList = this.conferenceList[index];
                if (conferenceList.id === this.editedItem.data.id) {
                  this.editedItem.data.id = conferenceList.id;
                  this.editedItem.data.name = conferenceList.name;
                  this.selectedValue = this.editedItem.data.name;
                  this.byDefualtAddData = false;
                }
              }
            } else {
              this.editedItem.data.id = this.conferenceList[0].id;
              this.editedItem.data.name = this.conferenceList[0].name;
              this.selectedValue = this.editedItem.data.name;
              this.byDefualtAddData = true;
            }
            this.disabledEditButton = false;
          }
        } else {
          this.disabledEditButton = true;
        }
        this.loader = false;
      } catch (error) {
        this.loader = false;

        console.log("====error===", error);
      }
    },
    getItemText(selectText) {
      // get the selected module name
      this.selectedValue = selectText;
    },
    save() {
      this.byDefualtAddData = false;
      // get the selected module properties from editedItem obj
      var editedItem = {};
      editedItem.data = {
        name: this.selectedValue,
        timeout: this.editedItem.data.timeout,
        can_call_self: this.editedItem.data.can_call_self,
        icon: this.editedItem.data.icon,
        id: this.editedItem.data.id,
      };
      this.editedItem.data = editedItem.data;

        //this.selectedDragList[this.editedIndex] = this.editedItem;
      this.editedItemModal = true;
      //storing the selected data into call flow API on didnpbx page
      this.$emit(
        "save-conference-data",
        this.selectedDragList,
        this.editedItem,
        this.editedIndex
      );
      this.closeOnSave();
    },
    closeOnSave() {
      this.$emit("close-on-save-conference-dialog");
    },
    closeConferenceDialog() {
      // if (this.editedItem.module === "conference") {
      //   if (this.editedItemModal == true) {
      //     this.closeOnSave();
      //   }
      //   if (this.editedItemModal != true) {
      //     this.$emit("close-conference-dialog");
      //   }
      // }
      if (this.byDefualtAddData) {
        this.editedItem.data.id = "";
        this.editedItem.data.name = "";
        this.selectedValue = "";
      }
      this.closeOnSave();
    },
    closeAddNewConferenceDialog() {
      this.addNewConferenceDialog = false;
      this.resetFormData();
    },
    async saveNewConference() {
      if (this.$refs.form.validate()) {
        this.saveLoader = true;
        this.loader = true;
        var voiceMailData = {
          accountId: this.userDetail.accountId,
          authToken: this.authToken,
          conferencesData: {
            data: {
              member: {
                join_muted: false,
                join_deaf: false,
              },
              play_entry_tone: true,
              play_exit_tone: true,
              name: this.conference.name,
              owner_id: this.conference.owner_id,
              conference_numbers: [],
              ui_metadata: {
                version: "4.3-139",
                ui: "monster-ui",
                origin: "callflows",
              },
            },
          },
        };
        if (this.conference.pins.length != 0) {
          var pins = [];
          pins = this.conference.pins;
          // console.log("pins", pins);
          voiceMailData.conferencesData.data.member.pins = pins.split(",");
          // console.log(
          //   "voiceMailData.conferencesData.data.member.pins",
          //   voiceMailData.conferencesData.data.member.pins
          // );
        }
        try {
          let response = await callFlow.addConferences(voiceMailData);
          this.loader = false;
          this.editedItem.data.id = response.responseData.data.id;
          this.getConferencesList();
          this.resetFormData();
          this.addNewConferenceDialog = false;
          this.saveLoader = false;
          this.$root.$emit("SHOW_SNACKBAR", {
            text: response.messageDetail,
            color: "success",
          });
        } catch (error) {
          this.loader = false;
          this.saveLoader = false;
          this.$root.$emit("SHOW_SNACKBAR", {
            text: "something wrong",
            color: "error",
          });
        }
      }
    },
    async editConferenceData() {
      if (this.editConference.member.pins.length != 0) {
        console.log("this.editConference.member.pins",this.editConference.member.pins)
        var pins = [];
        pins = this.editConference.member.pins[0];
        console.log("pins",pins)
        this.editConference.member.pins = pins.split(",");
      }
      console.log("this.editConference.member.pins======",this.editConference.member.pins)
      this.editLoader = true;
      var voiceMailData = {
        accountId: this.userDetail.accountId,
        authToken: this.authToken,
        conferencesId: this.editedItem.data.id,
        editData: {
          data: this.editConference,
        },
      };

      try {
        let response = await callFlow.editConferencesDetails(voiceMailData);
        this.editLoader = false;
        // console.log("response ===>", response);
        this.getConferencesList();
        this.editConferenceDialog = false;
        this.$root.$emit("SHOW_SNACKBAR", {
          text: response.messageDetail,
          color: "success",
        });
      } catch (error) {
        this.editLoader = false;
        this.$root.$emit("SHOW_SNACKBAR", {
          text: "something wrong",
          color: "error",
        });
      }
    },
    async editParticularConferenceDialog() {
      // this.editMenuDialog = true;
      var getConferenceData = {
        accountId: this.userDetail.accountId,
        conferencesId: this.editedItem.data.id,
      };
      try {
        let response = await callFlow.getConferencesDetails(getConferenceData);
        this.editConference = response.conferencesDetails;
        // this.editConference.member.pins =  response.conferencesDetails.member.pins.join(",");
        // }

        this.editConferenceDialog = true;
      } catch (error) {
        console.log("====error===", error);
        this.editConferenceDialog = true;
      }
    },
    async deleteConferencesDetails() {
      this.deleteLoader = true;
      var deleteConferencesData = {
        accountId: this.userDetail.accountId,
        authToken: this.authToken,
        conferencesId: this.editedItem.data.id,
      };
      try {
        let response = await callFlow.deleteConferencesDetails(
          deleteConferencesData
        );
        this.editConference = {
          member: {
            pins: "",
          },
        };
        this.editedItem.data.id = "";
        this.getConferencesList();
        this.editConferenceDialog = false;
        this.$root.$emit("SHOW_SNACKBAR", {
          text: response.messageDetail,
          color: "success",
        });
        this.deleteLoader = false;
      } catch (error) {
        this.deleteLoader = false;
        this.$root.$emit("SHOW_SNACKBAR", {
          text: "something wrong",
          color: "error",
        });
      }
    },
    resetFormData() {
      this.$refs.form.reset();
    },
    openAddNewUserDialog() {
      this.addNewUserDialog = true;
    },
    closeAddNewUserDialog() {
      this.addNewUserDialog = false;
      this.getUsersList();
    },
  },
};
</script>
<template>
  <section>
    <v-card :loading="loader">
      <v-card-title class="title">
        Route Options
        <v-icon @click="closeRouteOptionDialog" class="ml-auto">cancel</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <!-- {{ showNumberField }} -->
        <v-select
          label="Select Route Key"
          :items="routeOptionList"
          item-text="text"
          item-value="value"
          v-model="getOptionKey.value"
          @change="getOption(getOptionKey.value)"
          outlined
          color="primary"
          return-object
        >
        </v-select>
        <v-text-field
          v-if="showNumberField"
          v-model="callerIdNumber"
          placeholder="Enter require CID here"
          color="primary"
          outlined
          type="number"
        ></v-text-field>
        <v-btn block color="primary" outlined large @click="save()"
          >Save
        </v-btn>
      </v-card-text>
    </v-card>
  </section>
</template>

<script>
export default {
  props: [
    "editedItem",
    "selectedDragList",
    "editedIndex",
    "getOptionKey",
    "optionModule",
    "routeOptionList",
    "selectedStaticKey",
  ],
  components: {},
  data() {
    return {
      callerIdNumber: null,
      loader: false,
      selectOption: null,
      showNumberField: false,
      selectedOptionModule: {},
      getSelectedOptionKeyData: {},
      metaData: {},
    };
  },
  mounted() {},
  methods: {
    // closeAddNewMenuDialog() {
    //   this.addNewMenuDialog = false;
    //   this.resetFormData();
    // },
    getOption(getSelectedOptionKey) {
      // console.log("option.value", getSelectedOptionKey);
      this.getSelectedOptionKeyData = getSelectedOptionKey;
      // console.log("this.getSelectedOptionKey", this.getOptionKey);
      this.metaData = {};
      if (localStorage.getItem("metaData")) {
        this.metaData = JSON.parse(localStorage.getItem("metaData"));
      }
      if (getSelectedOptionKey.value == "caller_id") {
        this.showNumberField = true;
      } else {
        this.showNumberField = false;
        this.metaData[this.getOptionKey.value.value] = {
          name: this.getOptionKey.value.text,
        };
        localStorage.setItem("metaData", JSON.stringify(this.metaData));
      }

      // console.log("metaData", this.metaData);

      // console.log(
      //   "getSelectedOptionKey=========",
      //   getSelectedOptionKey,
      //   "getMenuOption=====",
      //   this.editedItem
      // );
      // this.selectedOptionModule = {};

      // if (getSelectedOptionKey && getSelectedOptionKey.value != "caller_id") {
      // if (Object.keys(this.editedItem).length != 1) {
      //   delete Object.assign(this.editedItem, {
      //     [getSelectedOptionKey.value]:
      //       this.editedItem[this.selectedStaticKey],
      //   })[this.selectedStaticKey];
      //   this.selectedOptionModule = this.editedItem;
      // } else {
      //   for (const key in this.editedItem) {
      //     if (this.editedItem.hasOwnProperty.call(this.editedItem, key)) {
      //       // console.log("object====>", this.editedItem, "key----------->", key);
      //       const element = this.editedItem[key];
      //       this.selectedOptionModule[getSelectedOptionKey.value] = element;
      //     }
      //   }
      //   // console.log("element====", this.selectedOptionModule);
      //   // this.editedItem = this.selectedOptionModule;
      // }
      // }
    },
    save() {
      // if (this.selectOption) {
      //   console.log("this.editedItem", this.editedItem);
      //   if (this.editedItem.timeOfDayId == null) {
      //     this.editedItem.timeOfDay = this.callerIdNumber;
      //     this.editedItem.timeOfDayId = this.callerIdNumber;
      //   }
      // }
      //this.selectedDragList[this.editedIndex] = this.editedItem;

      // console.log("this.optionModule", this.optionModule);
      this.selectedOptionModule = {};

      if (Object.keys(this.getSelectedOptionKeyData).length === 0) {
        // console.log("enter----------------------------------")
        this.getSelectedOptionKeyData = this.getOptionKey;
      }
      // console.log(
      //   "this.getSelectedOptionKeyData========",
      //   this.getSelectedOptionKeyData
      // );
      if (
        this.getSelectedOptionKeyData &&
        this.getSelectedOptionKeyData.value == "caller_id"
      ) {
        if (Object.keys(this.editedItem).length != 1) {
          delete Object.assign(this.editedItem, {
            [this.callerIdNumber]: this.editedItem[this.selectedStaticKey],
          })[this.selectedStaticKey];
          this.selectedOptionModule = this.editedItem;
        } else {
          for (const key in this.editedItem) {
            if (this.editedItem.hasOwnProperty.call(this.editedItem, key)) {
              // console.log("object====>", this.editedItem, "key----------->", key);
              const element = this.editedItem[key];
              this.selectedOptionModule[this.callerIdNumber] = element;
              this.metaData[this.callerIdNumber] = {
                name: this.callerIdNumber,
              };
              localStorage.setItem("metaData", JSON.stringify(this.metaData));
            }
          }
        }
        // console.log("element====", this.selectedOptionModule);
        // this.editedItem = this.selectedOptionModule;
      } else {
        if (Object.keys(this.editedItem).length != 1) {
          delete Object.assign(this.editedItem, {
            [this.getSelectedOptionKeyData.value]:
              this.editedItem[this.selectedStaticKey],
          })[this.selectedStaticKey];
          this.selectedOptionModule = this.editedItem;
        } else {
          for (const key in this.editedItem) {
            if (this.editedItem.hasOwnProperty.call(this.editedItem, key)) {
              // console.log("object====>", this.editedItem, "key----------->", key);
              const element = this.editedItem[key];
              this.selectedOptionModule[this.getSelectedOptionKeyData.value] =
                element;
            }
          }
          // console.log("element====", this.selectedOptionModule);
          // this.editedItem = this.selectedOptionModule;
        }
      }

      if (this.optionModule) {
        this.selectedDragList[this.editedIndex] = this.selectedOptionModule;
      } else {
        // this.selectedDragList[0]._[0].children[this.editedIndex] =
        //   this.editedItem;
        this.$set(
          this.selectedDragList,
          this.editedIndex,
          this.selectedOptionModule
        );
      }
      // console.log(
      //   "this.selectedDragList",
      //   this.selectedDragList,
      //   "this.editedIndex",
      //   this.editedIndex,
      //   "this.editedItem",
      //   this.editedItem
      // );
      //storing the selected data into call flow API on didnpbx page
      this.$emit(
        "save-route-options-data",
        this.selectedDragList,
        this.editedItem,
        this.editedIndex
      );
      this.closeRouteOptionDialog();
    },
    closeRouteOptionDialog() {
      this.callerIdNumber = "";
      this.$emit("close-route-options-dialog");
    },
  },
};
</script>
<template>
  <draggable
    class="pl-0 mx-2"
    :list="tasks"
    :group="{
      name: 'module',
      put: tasks.length == 0 ? true : dropEnable,
    }"
    @change="log"
    :class="tasks.length == 0 ? 'dragArea' : ''"
    :sort="false"
  >
    <!-- {{ checkOptionParentModuleName && checkOptionParentModuleName.module }} -->
    <li v-for="(dropModule, i) in tasks" :key="i" class="d-flex">
      <span v-for="(moduleObj, optionKey) in dropModule" :key="optionKey">
        <!-- arrow down image -->
        <img src="@/assets/images/down.png" width="35" height="35" alt="down" />

        <!-- start:option module -->
        <div
          v-if="checkOptionModule != showOptionModule"
          class="mb-0 mx-auto option-module teal lighten-4"
          @click="
            getParentModule(
              'getIndex' + optionKey,
              dropModule,
              optionKey,
              checkOptionParentModuleName
            )
          "
        >
          <p
            class="mb-0 text-sm"
            v-if="
              checkOptionParentModuleName.module == 'temporal_route' ||
              checkOptionParentModuleName.module == 'fax_detect'
            "
          >
            {{ optionKey | getSelectedOptionName }}
          </p>
          <p v-else class="mb-0 text-sm">
            {{ optionKey == "_" ? "Default Action" : optionKey }}
          </p>
        </div>
        <!-- end:option module -->

        <span
          v-for="(moduleObjdata, index) in moduleObj"
          :key="index"
          class="display-grid"
          @drop="ondrop(moduleObjdata)"
        >
          <!-- call flow module -->
          <v-hover v-slot="{ hover }" close-delay="200">
            <div class="mx-auto releative">
              <!-- remove icon -->
              <v-icon
                v-if="hover"
                class="close-dropModule-card"
                size="18"
                color="error"
                @click="removeItem(dropModule, optionKey)"
                >delete</v-icon
              >
              <v-col
                cols="12"
                @click="editSelectedModuleData(moduleObjdata)"
                class="dragArea-card mt-6"
              >
                <!-- arrow left for horizontal module -->
                <v-icon
                  v-if="
                    moduleObjdata.module == 'menu' ||
                    moduleObjdata.module == 'temporal_route' ||
                    moduleObjdata.module == 'fax_detect' ||
                    moduleObjdata.module == 'check_cid'
                  "
                  class="w-0"
                  color="green"
                  >arrow_left</v-icon
                >

                <div class="module-info">
                  <!-- module icon -->
                  <span v-if="moduleObjdata.module == 'device'">
                    <v-icon size="18" class="pr-3" color="primary">{{
                      moduleObjdata.data[0] &&
                      moduleObjdata.data[0].id | setDeviceModuleIcon
                    }}</v-icon>
                  </span>
                  <span v-else>
                    <v-icon size="18" class="pr-3" color="primary">{{
                      moduleObjdata.module | setModuleIcon
                    }}</v-icon>
                  </span>

                  <!-- module name  -->
                  <p class="mb-0 text-sm font-weight-bold item-center">
                    {{ moduleObjdata.module | setModuleName }}
                    <span v-if="moduleObjdata.module == 'device'">
                      <v-avatar
                        :color="
                          moduleObjdata.data[0] &&
                          moduleObjdata.data[0].id | getDeviceStatus
                        "
                        size="9"
                        class="ml-1"
                      ></v-avatar>
                    </span>
                  </p>
                </div>

                <p
                  class="mb-0 text-md"
                  v-if="moduleObjdata.data[0] && moduleObjdata.data[0].id"
                >
                  {{
                    moduleObjdata && moduleObjdata.data[0]
                      ? moduleObjdata.data[0].id
                      : "" | getSelectedModuleName
                  }}
                </p>
                <p class="mb-0 text-md" v-else>
                  {{
                    moduleObjdata && moduleObjdata.data[0]
                      ? moduleObjdata.data[0].name
                      : moduleObjdata.data.name
                  }}
                </p>

                <!-- arrow right for horizontal module -->
                <v-icon
                  v-if="
                    moduleObjdata.module == 'menu' ||
                    moduleObjdata.module == 'temporal_route' ||
                    moduleObjdata.module == 'fax_detect' ||
                    moduleObjdata.module == 'check_cid'
                  "
                  class="w-0"
                  color="green"
                  >arrow_right</v-icon
                >
              </v-col>
            </div>
          </v-hover>
          <!-- end:callfow drop module card -->
          <div
            v-if="
              moduleObjdata.module == 'menu' ||
              moduleObjdata.module == 'temporal_route' ||
              moduleObjdata.module == 'fax_detect' ||
              moduleObjdata.module == 'check_cid'
            "
          >
            <!-- arrow down icon when multiple option module -->
            <img
              src="@/assets/images/down.png"
              width="35"
              height="35"
              alt="down"
              v-if="
                moduleObjdata &&
                moduleObjdata.children &&
                moduleObjdata.children[0] &&
                Object.keys(moduleObjdata.children[0]).length != 1
              "
            />
            <img
              src="@/assets/images/down.png"
              width="35"
              height="35"
              alt="down"
              v-else-if="moduleObjdata.children.length != 1"
            />

            <div class="option-module-br">
              <!-- option module -->
              <nested-draggable
                class="d-flex"
                :tasks="moduleObjdata && moduleObjdata.children"
                :dropEnable="true"
                :showOptionModule="true"
                :checkOptionParentModuleName="moduleObjdata"
              />
            </div>
          </div>
          <div v-else>
            <nested-draggable
              :showOptionModule="false"
              :tasks="moduleObjdata && moduleObjdata.children"
              :checkOptionParentModuleName="moduleObjdata"
              v-if="moduleObjdata && moduleObjdata.module != 'callflow'"
            />
          </div>
        </span>
      </span>
    </li>

    <!-- didpbnx module dialog box -->
    <v-dialog
      v-model="openSelectedModuleModal"
      :width="selectedModule == 'ring_group' ? '800' : '400'"
      persistent
    >
      <!-- Add User dialog-->
      <add-user-dialog
        v-if="selectedModule == 'user'"
        :selectedDragList="tasks"
        :editedItem="selectedModuleData"
        :editedIndex="editedIndex"
        @save-user-data="saveSelectedDataModule"
        @close-on-save-dialog="closeSelectedDialog"
      />
      <!-- Add Media dialog-->
      <add-media-dialog
        v-if="selectedModule == 'play'"
        :selectedDragList="tasks"
        :editedItem="selectedModuleData"
        :editedIndex="editedIndex"
        @save-media-data="saveSelectedDataModule"
        @close-on-save-media-dialog="closeSelectedDialog"
      />
      <!-- Add Device dialog-->
      <add-device-dialog
        v-if="selectedModule == 'device'"
        :selectedDragList="tasks"
        :editedItem="selectedModuleData"
        :editedIndex="editedIndex"
        @save-device-data="saveSelectedDataModule"
        @close-on-save-device-dialog="closeSelectedDialog"
      />
      <!-- Conference Dialog-->
      <add-conference-dialog
        v-if="selectedModule == 'conference'"
        :selectedDragList="tasks"
        :editedItem="selectedModuleData"
        :editedIndex="editedIndex"
        @close-on-save-conference-dialog="closeSelectedDialog"
        @save-conference-data="saveSelectedDataModule"
      />
      <!-- Call Ring Group Dialog-->
      <add-ring-group
        v-if="selectedModule == 'ring_group'"
        :selectedDragList="tasks"
        :editedItem="selectedModuleData"
        :editedIndex="editedIndex"
        @save-ringGroup-data="saveSelectedDataModule"
        @close-on-save-ringGroup-dialog="closeSelectedDialog"
        ref="getRingGroupsData"
      />
      <!-- Call Recording Dialog-->
      <add-call-recording
        v-if="selectedModule == 'record_call'"
        :selectedDragList="tasks"
        :editedItem="selectedModuleData"
        :editedIndex="editedIndex"
        @save-callRecording-data="saveSelectedDataModule"
        @close-on-save-callRecording-dialog="closeSelectedDialog"
      />
      <!-- Voice Mail Dialog-->
      <add-voice-mail-dialog
        v-if="selectedModule == 'voicemail'"
        :selectedDragList="tasks"
        :editedItem="selectedModuleData"
        :editedIndex="editedIndex"
        @save-voice-mail-data="saveSelectedDataModule"
        @close-on-save-voice-mail-dialog="closeSelectedDialog"
      />
      <!-- fax detect module -->
      <addFaxDetectDialog
        v-if="selectedModule == 'fax_detect'"
        :selectedDragList="tasks"
        :editedItem="selectedModuleData"
        :editedIndex="editedIndex"
        @save-faxbox-detect-data="saveSelectedDataModule"
        @close-on-save-faxbox-detect-dialog="closeSelectedDialog"
      />
      <!-- Fax Box Dialog-->
      <add-fax-box-dialog
        v-if="selectedModule == 'faxbox'"
        :selectedDragList="tasks"
        :editedItem="selectedModuleData"
        :editedIndex="editedIndex"
        @save-faxbox-data="saveSelectedDataModule"
        @close-on-save-faxbox-dialog="closeSelectedDialog"
      />

      <!-- time zone dialog -->
      <add-timezone-dialog
        v-if="selectedModule == 'temporal_route'"
        :selectedDragList="tasks"
        :editedItem="selectedModuleData"
        :editedIndex="editedIndex"
        @save-timezone-data="saveSelectedDataModule"
        @close-on-save-timezone-dialog="closeSelectedDialog"
      />
      <!-- Menu Dialog-->
      <add-menu-dialog
        v-if="selectedModule == 'menu'"
        :selectedDragList="tasks"
        :editedItem="selectedModuleData"
        :editedIndex="editedIndex"
        @close-on-save-menu-dialog="closeSelectedDialog"
        @save-menu-data="saveSelectedDataModule"
      />

      <!-- CID check Module -->
      <add-check-cid-dialog
        v-if="selectedModule == 'check_cid'"
        :selectedDragList="tasks"
        :editedItem="selectedModuleData"
        :editedIndex="editedIndex"
        @save-cid-check-data="saveSelectedDataModule"
        @close-on-save-cid-check-dialog="closeSelectedDialog"
      />

      <!-- Call flow Module -->
      <addCallFLow
        v-if="selectedModule == 'callflow'"
        :selectedDragList="tasks"
        :editedItem="selectedModuleData"
        :editedIndex="editedIndex"
        @add-call-flow-Numbers="saveSelectedDataModule"
        @close-call-flow-Dialog="closeSelectedDialog"
      />

      <!-- menu option zone dialog -->
      <add-menu-options-vue
        v-if="addMenuOptionDialog"
        :selectedDragList="tasks"
        :editedItem="selectedModuleData"
        :editedIndex="editedIndex"
        :getOptionKey="getOptionKey"
        :optionModule="optionModule"
        :menuList="routeOptionList"
        :selectedStaticKey="selectedOptionKey"
        @save-menu-option-data="saveSelectedDataModule"
        @close-on-save-menu-option-dialog="closeOptionsDialog"
      />

      <!-- time of day option dialog -->
      <add-time-of-day-dialog
        v-if="timeOfDayOptionDialog"
        :selectedDragList="tasks"
        :editedItem="selectedModuleData"
        :editedIndex="editedIndex"
        :getOptionKey="getOptionKey"
        :optionModule="optionModule"
        :selectedStaticKey="selectedOptionKey"
        :getSelectedOptionKeys="getSelectedOptionKeys"
        @save-timeOfDay-data="saveSelectedDataModule"
        @close-timeOfDay-dialog="closeOptionsDialog"
      />

      <!-- Route Option dialog -->
      <add-route-options
        v-if="faxDetectOptionDialog"
        :selectedDragList="tasks"
        :editedItem="selectedModuleData"
        :editedIndex="editedIndex"
        :getOptionKey="getOptionKey"
        :optionModule="optionModule"
        :routeOptionList="routeOptionList"
        :selectedStaticKey="selectedOptionKey"
        @save-route-options-data="saveSelectedDataModule"
        @close-route-options-dialog="closeOptionsDialog"
      />

      <!--cid check Route Option dialog -->
      <addCidCheckRouteOptions
        v-if="cidCheckOptionDialog"
        :selectedDragList="tasks"
        :editedItem="selectedModuleData"
        :editedIndex="editedIndex"
        :getOptionKey="getOptionKey"
        :optionModule="optionModule"
        :routeOptionList="routeOptionList"
        :selectedStaticKey="selectedOptionKey"
        @save-route-options-data="saveSelectedDataModule"
        @close-route-options-dialog="closeOptionsDialog"
      />
    </v-dialog>
  </draggable>
</template>
<script>
import draggable from "vuedraggable";
import AddUserDialog from "./addUserDialog";
import AddMediaDialog from "./addMediaDialog";
import AddDeviceDialog from "./addDeviceDialog";
import AddConferenceDialog from "./addConferenceDialog";
import AddRingGroup from "./addRingGroup";
import AddCallRecording from "./addCallRecording";
import AddVoiceMailDialog from "./addVoiceMailDialog";
import addFaxDetectDialog from "./addFaxDetectDialog.vue";
import AddFaxBoxDialog from "./addFaxBoxDialog";
import AddTimezoneDialog from "./addTimezoneDialog.vue";
import AddMenuDialog from "./addMenuDialog";
import AddMenuOptionsVue from "./addMenuOptions.vue";
import AddTimeOfDayDialog from "./addTimeOfDayDialog.vue";
import AddRouteOptions from "./addFaxDetectRouteOptions.vue";
import AddCheckCidDialog from "./addCheckCidDialog.vue";
import addCidCheckRouteOptions from "./addCidCheckRouteOptions.vue";
import basicActionList from "./jsFile/basicActionList";
import advanceActionsList from "./jsFile/advanceActionList";
import timeOfDayList from "./jsFile/timeOfDayList";
import faxModuleList from "./jsFile/faxModuleList";
import voiceMailList from "./jsFile/voiceMailList";
import callFlowList from "./jsFile/callFlow";
import addCallFLow from "./addCallFLow.vue";

let selectedDropData = [];
let allModuleList = [];
let getParentModuleData = "";
let selectedRegexMatch = false;
let getOptionName = "";

export default {
  props: {
    tasks: {
      required: true,
      type: Array,
    },
    metaData: {
      required: false,
      type: Object,
    },
    dropEnable: {
      required: false,
      type: Boolean,
    },
    showOptionModule: {
      required: false,
      type: Boolean,
    },
    checkOptionParentModuleName: {
      required: false,
      type: Object,
    },
  },
  components: {
    draggable,
    AddUserDialog,
    AddMediaDialog,
    AddDeviceDialog,
    AddConferenceDialog,
    AddRingGroup,
    AddCallRecording,
    AddVoiceMailDialog,
    addFaxDetectDialog,
    AddFaxBoxDialog,
    AddTimezoneDialog,
    AddMenuDialog,
    AddMenuOptionsVue,
    AddTimeOfDayDialog,
    AddRouteOptions,
    AddCheckCidDialog,
    addCidCheckRouteOptions,
    addCallFLow,
    // Loading,
  },
  name: "nested-draggable",
  data() {
    return {
      // boolean
      openSelectedModuleModal: false,
      addMenuOptionDialog: false,
      optionModule: false,
      timeOfDayOptionDialog: false,
      faxDetectOptionDialog: false,
      cidCheckOptionDialog: false,
      selectedRegexMatch: false,
      checkOptionModule: false,
      //string
      editedIndex: -1,
      selectedModule: "",
      getSelectedModule: "",
      selectedOptionKey: "_",
      selectedParentModule: "",
      // getOptionName: "",

      //object
      selectedModuleData: {},
      getOptionKey: {},
      userDetail: {},

      //array
      // outputKeys: [],
      timeOfDayData: [],
      routeOptionList: [],
      // deviceList: [],
      optionList: [],
      getSelectedOptionKeys: [],
      avaliableModuleList: [
        "user",
        "play",
        "device",
        "conference",
        "ring_group",
        "record_call",
        "voicemail",
        "fax_detect",
        "faxbox",
        "temporal_route",
        "menu",
        "check_cid",
        "callflow",
      ],
    };
  },
  beforeMount() {
    // merged all drag data into allModule list
    allModuleList = [
      ...basicActionList.actionListMenu,
      ...advanceActionsList.actionListMenu,
      ...timeOfDayList.timeOfDayListMenu,
      ...faxModuleList.faxModuleMenu,
      ...voiceMailList.voiceMailListMenu,
      ...callFlowList.callFLow,
    ];
  },
  mounted() {
    // get user data
    this.userDetail = JSON.parse(localStorage.getItem("userDetail"));
    // this.getAlldivices();
  },
  filters: {
    getSelectedModuleName(id) {
      let metaData = JSON.parse(localStorage.getItem("metaData"));

      for (const key in metaData) {
        if (Object.hasOwnProperty.call(metaData, key)) {
          const element = metaData[key];
          // console.log("element", element, "key", key);
          if (key === id) {
            return element.name
              ? element.name
              : element.numbers
              ? element.numbers[0]
              : "-";
          } else {
            // console.log("id");
          }
        }
      }
    },
    getSelectedOptionName(id) {
      // console.log("id", id);
      let setMetaData = JSON.parse(localStorage.getItem("metaData"));
      if (id) {
        if (id == "ON_VOICE") {
          setMetaData["ON_VOICE"] = {
            name: "Voice",
          };
          // console.log("setMetaData", setMetaData);
          localStorage.setItem("metaData", JSON.stringify(setMetaData));
        } else if (id == "ON_FAX") {
          setMetaData["ON_FAX"] = {
            name: "Fax",
          };
          localStorage.setItem("metaData", JSON.stringify(setMetaData));
        }
      }

      let metaData = JSON.parse(localStorage.getItem("metaData"));

      for (const key in metaData) {
        if (Object.hasOwnProperty.call(metaData, key)) {
          const element = metaData[key];
          // console.log("element", id, "key", key);
          if (key === id) {
            return element.name;
          }
        }
      }
    },
    // get module name
    setModuleName(moduleName) {
      const getModuleObject = allModuleList.find(
        (moduleObject) => moduleObject.module == moduleName
      );
      return getModuleObject && getModuleObject.name;
    },
    // get module icon
    setModuleIcon(moduleName) {
      const getModuleObject = allModuleList.find(
        (moduleObject) => moduleObject.module == moduleName
      );
      return getModuleObject && getModuleObject.icon;
    },
    setDeviceModuleIcon(deviceId) {
      let deviceList = JSON.parse(localStorage.getItem("getDeviceList"));
      // console.log("this.deviceList", deviceList);
      const getDeviceType = deviceList.find(
        (moduleObject) => moduleObject.id == deviceId
      );
      // console.log("getDeviceT/ype", getDeviceType.device_type, "deviceId", deviceId);
      if (getDeviceType && getDeviceType.device_type == "cellphone") {
        return "phone_iphone";
      } else {
        return "dialer_sip";
      }
    },
    getDeviceStatus(deviceId) {
      // console.log("deviceId", deviceId);
      let deviceList = JSON.parse(localStorage.getItem("getDeviceList"));
      let currentList = JSON.parse(localStorage.getItem("currentList"));
      const getDevice = deviceList.find((device) => device.id == deviceId);

      // console.log("getDevice=====", getDevice);

      const getDeviceType = currentList.find(
        (moduleObject) => moduleObject.authorizing_id == deviceId
      );

      if (getDevice && getDevice.device_type == "cellphone") {
        return "transparent";
      } else {
        if (getDeviceType) {
          return "green";
        } else {
          return "red";
        }
      }
    },
  },
  methods: {
    getParentModule(
      parentModuleData,
      dropModule,
      optionKey,
      checkOptionParentModuleName
    ) {
      this.optionList = this.tasks;
      this.editedIndex = this.tasks.indexOf(dropModule);
      this.selectedModuleData = dropModule;

      // showing regex option
      if (
        checkOptionParentModuleName &&
        checkOptionParentModuleName.data[0] &&
        checkOptionParentModuleName.data[0].name == "Regex match"
      ) {
        selectedRegexMatch = true;
      } else {
        selectedRegexMatch = false;
      }

      getOptionName = checkOptionParentModuleName.module;
      this.getOptionKey.value = optionKey;
      this.selectedOptionKey = optionKey;
      this.openSelectedModuleModal = true;
      getParentModuleData = getOptionName;

      this.setDataSelectedOptionModule();

      if (getOptionName == "menu") {
        this.addMenuOptionDialog = true;
      } else if (getOptionName == "temporal_route") {
        this.timeOfDayOptionDialog = true;
      } else if (getOptionName == "check_cid") {
        this.cidCheckOptionDialog = true;
      } else {
        this.faxDetectOptionDialog = true;
        if (Object.keys(this.selectedModuleData).length == 2) {
          this.routeOptionList = [];
        }
      }
    },
    ondrop(evt) {
      if (selectedDropData.length == 0) {
        // if user selected regex match from CID CHECK module
        if (
          evt &&
          evt.data &&
          evt.data[0] &&
          evt.data[0].name === "Regex match"
        ) {
          selectedRegexMatch = true;
        } else if (evt.data.name === "Regex match") {
          selectedRegexMatch = true;
        } else {
          selectedRegexMatch = false;
        }

        selectedDropData.push(evt.module);
        getParentModuleData = selectedDropData[0];
      }
    },

    log: function (evt) {
      this.selectedModule = "";
      this.getSelectedModule = "";
      let selectedModuleObject = {};
      this.selectedModuleData = {};

      if (evt && evt.added && evt.added.element) {
        selectedModuleObject = evt.added.element;
      } else if (evt && evt.removed && evt.removed.element) {
        selectedModuleObject = evt.removed.element;
      }

      var checkFaxBoxOnFaxDetect = true;

      if (Object.keys(selectedModuleObject).length != 0) {
        for (let index = 0; index < selectedModuleObject._.length; index++) {
          const getModuleObject = selectedModuleObject._[index];
          this.getSelectedModule = getModuleObject.module;
          // check if not selected fax detect
          if (this.getSelectedModule == "faxbox") {
            if (getParentModuleData == "fax_detect") {
              checkFaxBoxOnFaxDetect = true;
            } else {
              checkFaxBoxOnFaxDetect = false;
              const index = this.tasks.indexOf(this.selectedModuleData);
              // console.log("index", index);
              this.tasks.splice(index, 1);
              this.$root.$emit("SHOW_SNACKBAR", {
                text: "Please Select First Fax Detect!",
                color: "error",
              });
            }
          }
          if (checkFaxBoxOnFaxDetect) {
            if (
              getParentModuleData == "menu" ||
              getParentModuleData == "temporal_route" ||
              getParentModuleData == "fax_detect" ||
              getParentModuleData == "check_cid"
            ) {
              this.selectedModuleData = selectedModuleObject;
              this.editedIndex = this.tasks.indexOf(this.selectedModuleData);
              this.getOptionKey.value = "_";
              this.optionModule = true;
            } else {
              this.selectedModuleData = getModuleObject;
              this.selectedModuleData.data =
                getModuleObject && getModuleObject.data[0]
                  ? getModuleObject.data[0]
                  : getModuleObject.data;
            }
          }
        }
        if (checkFaxBoxOnFaxDetect) {
          this.showSelectedModule();
        }
      }
    },

    setDataSelectedOptionModule() {
      // added option module data
      let routeOptionList = [];
      if (getParentModuleData === "fax_detect") {
        routeOptionList = [
          { text: "Fax", value: "ON_FAX" },
          { text: "Voice", value: "ON_VOICE" },
        ];
      } else if (getParentModuleData === "menu") {
        routeOptionList = [
          { text: "Default Action", value: "_" },
          { text: "timeout", value: "timeout" },
          { text: "*", value: "*" },
          { text: "0", value: "0" },
          { text: "1", value: "1" },
          { text: "2", value: "2" },
          { text: "3", value: "3" },
          { text: "4", value: "4" },
          { text: "5", value: "5" },
          { text: "6", value: "6" },
          { text: "7", value: "7" },
          { text: "8", value: "8" },
          { text: "9", value: "9" },
        ];
      } else {
        if (selectedRegexMatch) {
          routeOptionList = [
            { text: "Default action", value: "nomatch" },
            { text: "Regex match action", value: "match" },
          ];
        } else {
          routeOptionList = [
            { text: "Default action", value: "_" },
            { text: "Caller ID Number", value: "caller_id" },
          ];
        }
      }

      // aded option data according to selected module
      this.routeOptionList = routeOptionList;
      // console.log(
      //   "routeOptionList----cgexk",
      //   this.routeOptionList,
      //   "this.selectedOptionKey",
      //   this.selectedOptionKey
      // );

      let getSelectedRouteOptionKeys = [];
      // removed data if already selected option key data
      if (this.tasks) {
        this.tasks.forEach((element) => {
          Object.keys(element).forEach((key) => {
            getSelectedRouteOptionKeys.push({ value: key });
          });
        });

        getSelectedRouteOptionKeys.forEach((selectedRouteOption) => {
          routeOptionList.forEach((routeOptions) => {
            if (this.selectedOptionKey != selectedRouteOption.value) {
              if (selectedRouteOption.value == routeOptions.value) {
                // if (routeOptions.value != "_") {
                const index = this.routeOptionList.indexOf(routeOptions);
                this.routeOptionList.splice(index, 1);
                // }
              }
            }
          });
        });
      }

      if (
        this.tasks[0] &&
        this.tasks[0]._ &&
        this.tasks[0]._[0] &&
        this.tasks[0]._[0].children.length == 2
      ) {
        this.tasks[0]._[0].children.forEach((element) => {
          // console.log("element-----------B", element);
          Object.keys(element).forEach((key) => {
            getSelectedRouteOptionKeys.push({ value: key });
          });
        });

        getSelectedRouteOptionKeys.forEach((selectedRouteOption) => {
          this.routeOptionList.forEach((routeOptions) => {
            if (selectedRouteOption.value == routeOptions.value) {
              // console.log("routeOptions--c", routeOptions);
              // if (routeOptions.value != "_") {
              const index = this.routeOptionList.indexOf(routeOptions);
              this.routeOptionList.splice(index, 1);
              // }
            }
          });
        });
      }
    },

    showSelectedModule() {
      this.setDataSelectedOptionModule();

      if (this.optionModule) {
        // this.selectedModuleData._.subParentData = "_";
        // this.selectedModule = this.selectedModuleData.module;
        if (getParentModuleData === "menu") {
          this.addMenuOptionDialog = true;
          this.openSelectedModuleModal = true;
          this.optionModule = true;
        } else if (getParentModuleData === "temporal_route") {
          this.timeOfDayOptionDialog = true;
          this.openSelectedModuleModal = true;
          this.optionModule = true;
        } else if (
          getParentModuleData === "fax_detect" ||
          getParentModuleData === "check_cid"
        ) {
          if (this.routeOptionList.length == 0) {
            this.$root.$emit("SHOW_SNACKBAR", {
              text: "No routing keys left",
              color: "error",
            });
            const index = this.tasks.indexOf(this.selectedModuleData);
            // console.log("index", index);
            this.tasks.splice(index, 1);
          } else {
            if (getParentModuleData === "fax_detect") {
              this.faxDetectOptionDialog = true;
            } else {
              this.cidCheckOptionDialog = true;
            }
            this.openSelectedModuleModal = true;
            this.optionModule = true;
          }
        }
      } else {
        // console.log("this.getSelectedModule", this.getSelectedModule);
        if (this.getSelectedModule === "ring_group") {
          var ringGroup = {
            strategy: "simultaneous",
            repeats: 1,
            name: "",
          };
          setTimeout(() => {
            this.$refs.getRingGroupsData.setValue(ringGroup);
          }, 500);
        }
        if (
          this.getSelectedModule != "dead_air" &&
          this.getSelectedModule != "cidlistmatch"
        ) {
          let checkVoiceMail = true;
          if (this.getSelectedModule == "voicemail") {
            if (
              this.selectedModuleData.data &&
              this.selectedModuleData.data.action == "check"
            ) {
              checkVoiceMail = false;
            } else {
              checkVoiceMail = true;
            }
          }
          if (checkVoiceMail) {
            this.selectedModule = this.getSelectedModule;
            this.openSelectedModuleModal = true;
            this.optionModule = false;
          }
        }
      }
      // }
    },
    saveSelectedDataModule(selectedDragList, editedItem, editedIndex) {
      let selectedModule = "";
      selectedDropData = [];
      if (this.optionModule == true) {
        // open option child module dialog when save the data option module
        for (const key in editedItem) {
          this.getOptionKey.value = key;
          // console.log("this.getOptionKey", this.getOptionKey);
          if (Object.hasOwnProperty.call(editedItem, key)) {
            const getModuleArray = editedItem[key];
            if (Array.isArray(getModuleArray) == true) {
              for (let index = 0; index < getModuleArray.length; index++) {
                const getModuleObject = getModuleArray[index];
                this.selectedModuleData = getModuleObject;
                this.selectedModuleData.data =
                  getModuleObject && getModuleObject.data[0]
                    ? getModuleObject.data[0]
                    : getModuleObject.data;
                selectedModule = getModuleObject.module;
              }
            }
          }
        }

        setTimeout(() => {
          this.addMenuOptionDialog = false;
          this.timeOfDayOptionDialog = false;
          if (selectedModule == "ring_group") {
            var ringGroup = {
              strategy: "simultaneous",
              repeats: 1,
              name: "",
            };
            setTimeout(() => {
              this.$refs.getRingGroupsData.setValue(ringGroup);
            }, 500);
          }
          if (
            this.getSelectedModule != "dead_air" &&
            this.getSelectedModule != "Check Voicemail Box" &&
            this.getSelectedModule != "cidlistmatch"
          ) {
            this.selectedModule = selectedModule;
            this.openSelectedModuleModal = true;
            this.optionModule = false;
          }
        }, 500);
      } else {
        this.closeSelectedDialog();
      }
    },
    // open model by module name
    editSelectedModuleData(item) {
      // console.log("item", item);
      this.editedItem = {};
      // added data of selectd on dropModule module  card
      this.editedItem = item;
      this.editedItem.data = item.data[0] ? item.data[0] : item.data;

      if (this.tasks[0]._) {
        this.editedIndex = this.tasks[0]._.indexOf(this.editedItem);
      }

      if (this.editedItem.module == "ring_group") {
        if (!this.editedItem.data.name) {
          this.editedItem.data = {
            strategy: "simultaneous",
            repeats: 1,
          };
        }
        setTimeout(() => {
          this.$refs.getRingGroupsData.setValue(this.editedItem.data);
        }, 500);
      }

      // open module of dropModule module card
      const getModuleName = this.avaliableModuleList.find(
        (moduleName) => moduleName == this.editedItem.module
      );
      // console.log("getModuleName", getModuleName);
      if (getModuleName) {
        let checkVoiceMail = true;
        if (getModuleName == "voicemail") {
          if (this.editedItem.data && this.editedItem.data.action == "check") {
            checkVoiceMail = false;
          } else {
            checkVoiceMail = true;
          }
        }
        if (checkVoiceMail) {
          this.selectedModuleData = this.editedItem;
          this.selectedModule = this.editedItem.module;
          this.openSelectedModuleModal = true;
        }
      } else {
        this.$root.$emit("SHOW_SNACKBAR", {
          text: "This module is not avalible!",
          color: "error",
        });
      }
    },

    removeItem(dropModule, optionKey) {
      if (Object.keys(dropModule).length != 1) {
        this.tasks.forEach((element) => {
          this.$delete(element, optionKey);
        });
      } else {
        const index = this.tasks.indexOf(dropModule);
        this.tasks.splice(index, 1);
      }
      this.closeSelectedDialog();
    },

    closeSelectedDialog() {
      this.openSelectedModuleModal = false;
      this.addMenuOptionDialog = false;
      this.timeOfDayOptionDialog = false;
      this.optionModule = false;
      this.selectedModule = "";
      this.getSelectedModule = "";
      getParentModuleData = "";
      selectedDropData = [];
    },

    closeOptionsDialog() {
      this.openSelectedModuleModal = false;
      this.timeOfDayOptionDialog = false;
      this.faxDetectOptionDialog = false;
      this.cidCheckOptionDialog = false;
      this.addMenuOptionDialog = false;
    },
  },
};
</script>

<style scoped>
.dragArea {
  min-height: 79px;
  outline: thin solid rgba(0, 0, 0, 0.12);
  border-radius: 13px;
  min-width: 160px;
  height: fit-content;
  margin-bottom: 4px;
  margin-top: 4px;
}

.module-card {
  min-width: 160px;
  max-width: 160px;
  margin: auto;
}

.dragArea li {
  list-style-type: none;
  position: relative;
}

.dragArea-card {
  width: 160px;
  height: 48px;
  background-color: rgb(255, 255, 255);
  border: 2px solid rgb(255, 255, 255);
  border-radius: 13px;
  box-shadow: 0 1px 2px rgb(0 0 0 / 10%);
  cursor: pointer;
  transition: all 0.1s ease-in-out 0s;
  margin: 8px;
  position: relative;
  display: flex;
  align-items: center;
}

.dragArea-card .module-info {
  position: absolute;
  top: -18px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 142px;
  height: 28px;
  border: 2px solid #fff;
  border-radius: 14px;
  font-weight: 500;
  text-transform: capitalize;
  line-height: 1;
  background-color: #e1f0ff;
}

.dragArea-card .text-md {
  font-size: 14px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 100%;
  text-align: center;
  line-height: 2;
}

.text-sm {
  font-size: 12px;
}

.close-dropModule-card {
  position: absolute;
  top: 5px;
  z-index: 1;
  left: 94%;
  width: 24px;
  height: 24px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 1px 2px rgb(0 0 0 / 5%);
  cursor: pointer;
  transition: all 0.8s ease-in-out 0s;
}

.option-module {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 142px;
  height: 28px;
  border: 2px solid #fff;
  border-radius: 14px;
  font-weight: 500;
  text-transform: capitalize;
  line-height: 1;
  cursor: pointer;
}

.releative {
  position: relative;
}

.display-grid {
  display: grid;
}

.option-module-br {
  border: 2px dashed #b2dfdb;
  border-radius: 13px;
}

@media screen and (max-width: 1400px) {
}
</style>

<template>
  <section>
    <v-card :loading="loader">
      <v-card-title class="title">
        Voice Mail
        <v-icon @click="closeVoiceMailDialog" class="ml-auto">cancel</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <p>Connect caller to voiceMail...</p>
        <v-select
          label="Which Voicemail Box?"
          :items="voiceMailList"
          item-text="name"
          item-value="id"
          v-model="editedItem.data.id"
          outlined
          color="primary"
        >
          <template slot="item" slot-scope="data">
            <v-list-item-content @click="getItemText(data.item.name)">
              <v-list-item-title v-html="data.item.name"></v-list-item-title>
            </v-list-item-content>
          </template>
        </v-select>
        <v-row>
          <v-col cols="12" sm="6">
            <v-btn
              block
              color="green"
              outlined
              :disabled="disabledEditButton"
              @click="editParticularVoiceMailDialog(editedItem)"
            >
              Edit</v-btn
            >
          </v-col>
          <v-col cols="12" sm="6">
            <v-btn
              block
              color="info"
              outlined
              @click="addNewVoiceMailDialog = true"
            >
              Add
            </v-btn>
          </v-col>
        </v-row>
        <v-btn block color="primary" outlined large @click="save()"
          >Save
        </v-btn>
      </v-card-text>
    </v-card>
    <!-- Add voiceMail Dialog -->
    <v-dialog v-model="addNewVoiceMailDialog" width="600" persistent>
      <v-card>
        <v-card-title class="title">
          Create Voice Mail
          <v-icon @click="closeAddNewVoiceMailDialog()" class="ml-auto"
            >cancel</v-icon
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-5">
          <v-form ref="form" v-model="isFormValid" lazy-validation>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Name"
                  v-model="voiceMail.name"
                  hint="Friendly name for this voicemail file"
                  :rules="nameRules"
                  color="primary"
                  outlined
                ></v-text-field>
                <v-text-field
                  label="Voice Mail Number"
                  v-model="voiceMail.mailbox"
                  type="number"
                  counter
                  maxlength="30"
                  hint="This field uses 30 maxlength"
                  :rules="numberRules"
                  color="primary"
                  outlined
                ></v-text-field>
                <v-row class="text-right mx-right">
                  <v-col cols="12">
                    <v-btn
                      text
                      class="primary-btn"
                      @click="openAddNewUserDialog"
                      >Add a user</v-btn
                    >
                  </v-col>
                </v-row>
                <v-select
                  label="Assign To"
                  :items="usersList"
                  v-model="voiceMail.owner_id"
                  item-text="name"
                  item-value="id"
                  outlined
                  color="primary"
                  clearable
                  hint="Chose the owner of this voicemail box"
                  persistent-hint
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Pin Number"
                  v-model="voiceMail.pin"
                  hint="Enter the PIN Number of this voicemail box (must be at least 4 digits)"
                  color="primary"
                  type="number"
                  minlength="4"
                  maxlength="6"
                  outlined
                  counter
                ></v-text-field>
                <v-select
                  label="Unavailable Message"
                  :items="mediaList"
                  item-text="name"
                  item-value="id"
                  v-model="voiceMail.unavailable"
                  outlined
                  color="primary"
                  clearable
                >
                </v-select>
                <v-row class="text-right mx-right">
                  <v-col cols="12">
                    <v-btn
                      text
                      class="primary-btn"
                      @click="openNewAddMediaDialog"
                      >Add a media</v-btn
                    >
                  </v-col>
                </v-row>
                <v-select
                  label="Temporary Message"
                  :items="mediaList"
                  item-text="name"
                  item-value="id"
                  v-model="voiceMail.temporary_unavailable"
                  outlined
                  color="primary"
                  clearable
                  hint="Select the Temporary message that you want to use for this this device. If you select - Not set -, then no media file will be played."
                  persistent-hint
                >
                </v-select>
              </v-col>
            </v-row>
            <v-btn
              color="primary"
              outlined
              :loading="saveLoader"
              class="px-5"
              large
              block
              @click="saveNewVoiceMail()"
              >Save New Voice Mail
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Edit voiceMail Dialog -->
    <v-dialog v-model="editVoiceMailDialog" width="800" persistent>
      <v-card>
        <v-card-title class="title">
          Edit Voice Mail
          <v-icon @click="editVoiceMailDialog = false" class="ml-auto"
            >cancel</v-icon
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-5">
          <v-form ref="editform" v-model="isEditFormValid" lazy-validation>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Name"
                  v-model="editVoiceMail.name"
                  hint="Friendly name for this voicemail file"
                  :rules="nameRules"
                  color="primary"
                  outlined
                ></v-text-field>
                <v-text-field
                  label="Voicemail Number"
                  v-model="editVoiceMail.mailbox"
                  :rules="numberRules"
                  color="primary"
                  outlined
                ></v-text-field>
                <v-row class="text-right mx-right">
                  <v-col cols="12">
                    <v-btn
                      text
                      class="primary-btn"
                      @click="openAddNewUserDialog"
                      >Add a user</v-btn
                    >
                  </v-col>
                </v-row>
                <v-select
                  label="Assign To"
                  :items="usersList"
                  item-text="name"
                  item-value="id"
                  v-model="editVoiceMail.owner_id"
                  outlined
                  color="primary"
                  hint="Chose the owner of this voicemail box"
                  clearable
                  persistent-hint
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Pin Number"
                  v-model="editVoiceMail.pin"
                  hint="Enter the PIN Number of this voicemail box (must be at least 4 digits)"
                  color="primary"
                  minlength="3"
                  maxlength="6"
                  type="number"
                  outlined
                  counter
                ></v-text-field>
                <v-select
                  label="Unavailable Message"
                  :items="mediaList"
                  item-text="name"
                  item-value="id"
                  v-model="editVoiceMail.media.unavailable"
                  outlined
                  color="primary"
                  clearable
                >
                </v-select>
                <v-row class="text-right mx-right">
                  <v-col cols="12">
                    <v-btn
                      text
                      class="primary-btn"
                      @click="openNewAddMediaDialog"
                      >Add a media</v-btn
                    >
                  </v-col>
                </v-row>
                <v-select
                  label="Temporary Message"
                  :items="mediaList"
                  item-text="name"
                  item-value="id"
                  v-model="editVoiceMail.media.temporary_unavailable"
                  outlined
                  color="primary"
                  clearable
                  hint="Select the Temporary Unavailable message that you want to use for this this device. If you set this field, it will take precedence over the Unavailable message, but if you select - Not set -, then the Unavailable message will be played instead."
                  persistent-hint
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col xs="6">
                <v-btn
                  color="error"
                  outlined
                  large
                  :loading="deleteLoader"
                  @click="deleteVMBoxesDetails()"
                  block
                  >Delete
                </v-btn>
              </v-col>
              <v-col xs="6">
                <v-btn
                  color="primary"
                  outlined
                  class="px-5"
                  :loading="editLoader"
                  large
                  block
                  @click="editVoiceMailData()"
                  >Save
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Add media Dialog -->
    <add-new-media
      :addNewMediaDialogBySelect="addNewMediaDialogBySelect"
      @close-media="closeNewMedia"
    />
    <!-- Add new user Dialog -->
    <add-new-user-dialog
      :addNewUserDialog="addNewUserDialog"
      @close-new-user-dialog="closeAddNewUserDialog"
    />
  </section>
</template>

<script>
import callFlow from "@/services/callFlowAPI.js";
import addNewMedia from "./addNewMedia.vue";
import addNewUserDialog from "./addNewUserDialog";

export default {
  props: ["editedItem", "selectedDragList", "editedIndex"],
  components: {
    addNewMedia,
    addNewUserDialog,
  },
  data() {
    return {
      voiceMailList: [],
      voiceMail: {
        pin: "",
      },
      mediaList: [],
      editVoiceMail: {
        media: {},
      },
      addNewVoiceMailDialog: false,
      editVoiceMailDialog: false,
      isFormValid: true,
      isEditFormValid: true,
      loader: false,
      editLoader: false,
      disabledEditButton: false,
      saveLoader: false,
      deleteLoader: false,
      usersList: [],
      rulesPin: [(v) => (v && v.length >= 4) || "Min 4 characters"],
      nameRules: [(v) => !!v || "Name is required"],
      numberRules: [
        (v) => (v && v.length <= 30) || "Max 30 characters",
        (v) => !!v || "Number is required",
      ],
      editedItemModal: false,
      addNewMediaDialogBySelect: false,
      addNewUserDialog: false,
      byDefualtAddData: true,
      authToken: "",
      selectedValue: "",
    };
  },
  mounted() {
    this.userDetail = JSON.parse(localStorage.getItem("userDetail"));
    this.authToken = localStorage.getItem("authToken");
    this.getUsersList();
    this.getMediaList();
    this.getVMBoxesList();
  },
  methods: {
    async getUsersList() {
      var getUsersData = {
        accountId: this.userDetail.accountId,
        userId: this.userDetail.userId,
        forwardType: "SOFTPHONE",
      };
      try {
        let response = await callFlow.getUsersList(getUsersData);
        var userData = {};
        for (let index = 0; index < response.usersList.length; index++) {
          const element = response.usersList[index];
          element.name = element.first_name + " " + element.last_name;
          userData = element;
          this.usersList.push(userData);
        }
      } catch (error) {
        console.log("====error===", error);
      }
    },
    async getMediaList() {
      this.mediaData = [];
      var getUsersData = {
        accountId: this.userDetail.accountId,
      };
      try {
        let response = await callFlow.getMediaList(getUsersData);
        this.mediaList = response.mediaList;
      } catch (error) {
        console.log("====error===", error);
      }
    },
    async getVMBoxesList() {
      this.loader = true;
      this.voiceMailList = [];
      var getUsersData = {
        accountId: this.userDetail.accountId,
      };
      try {
        let response = await callFlow.getVMBoxesList(getUsersData);
        this.voiceMailList = response.vmboxesList;
        if (this.voiceMailList.length != 0) {
          if (this.editVoiceMail.id) {
            this.editedItem.data.id = this.editVoiceMail.id;
            this.editedItem.data.name = this.editVoiceMail.name;
            this.selectedValue = this.editedItem.data.name;
            this.byDefualtAddData = false;
          } else {
            if (this.editedItem.data.id) {
              this.editedItemModal = true;
              for (let index = 0; index < this.voiceMailList.length; index++) {
                const voiceMailList = this.voiceMailList[index];
                if (voiceMailList.id === this.editedItem.data.id) {
                  this.editedItem.data.id = voiceMailList.id;
                  this.editedItem.data.name = voiceMailList.name;
                  this.selectedValue = this.editedItem.data.name;
                  this.byDefualtAddData = false;
                }
              }
            } else {
              this.editedItem.data.id = this.voiceMailList[0].id;
              this.editedItem.data.name = this.voiceMailList[0].name;
              this.selectedValue = this.editedItem.data.name;
              this.byDefualtAddData = true;
            }
          }
          this.disabledEditButton = false;
        } else {
          this.disabledEditButton = true;
        }
        this.loader = false;
      } catch (error) {
        this.loader = false;

        console.log("====error===", error);
      }
    },
    closeAddNewVoiceMailDialog() {
      this.addNewVoiceMailDialog = false;
      this.resetFormData();
    },
    getItemText(selectText) {
      // get the selected module name
      this.selectedValue = selectText;
    },
    save() {
      this.byDefualtAddData = true;
      // get the selected module properties from editedItem obj
      var editedItem = {};
      editedItem.data = {
        name: this.selectedValue,
        timeout: this.editedItem.data.timeout,
        can_call_self: this.editedItem.data.can_call_self,
        icon: this.editedItem.data.icon,
        id: this.editedItem.data.id,
      };
      this.editedItem.data = editedItem.data;

        //this.selectedDragList[this.editedIndex] = this.editedItem;
      this.editedItemModal = true;
      //storing the selected data into call flow API on didnpbx page
      this.$emit(
        "save-voice-mail-data",
        this.selectedDragList,
        this.editedItem,
        this.editedIndex
      );
      this.closeOnSave();
    },
    closeOnSave() {
      this.$emit("close-on-save-voice-mail-dialog");
    },
    closeVoiceMailDialog() {
      if (this.byDefualtAddData) {
        this.editedItem.data.id = "";
        this.editedItem.data.name = "";
        this.selectedValue = "";
      }
      this.closeOnSave();
    },
    async saveNewVoiceMail() {
      if (this.$refs.form.validate()) {
        this.loader = true;
        this.saveLoader = true;
        var voiceMailData = {
          accountId: this.userDetail.accountId,
          authToken: this.authToken,
          vmboxesData: {
            data: {
              require_pin: true,
              check_if_owner: true,
              pin: this.voiceMail.pin,
              media: {
                unavailable: this.voiceMail.unavailable,
                temporary_unavailable: this.voiceMail.temporary_unavailable,
              },
              hasTranscribe: false,
              transcribe: false,
              include_message_on_notify: true,
              name: this.voiceMail.name,
              mailbox: this.voiceMail.mailbox,
              owner_id: this.voiceMail.owner_id,
              is_setup: false,
              skip_greeting: false,
              skip_instructions: false,
              delete_after_notify: false,
              is_voicemail_ff_rw_enabled: false,
              oldest_message_first: false,
              notify_email_addresses: [],
              not_configurable: false,
              ui_metadata: {
                version: "4.3-139",
                ui: "monster-ui",
                origin: "callflows",
              },
            },
          },
        };

        if (this.voiceMail.pin.length != 0) {
          if (this.voiceMail.pin.length < 3 || this.voiceMail.pin.length > 6) {
            this.$root.$emit("SHOW_SNACKBAR", {
              text: "Min 3 characters and Max 6 characters",
              color: "error",
            });
            this.loader = false;
            this.saveLoader = false;
          } else {
            this.submitVoiceMail(voiceMailData);
          }
        } else if (this.voiceMail.pin.length === 0) {
          delete voiceMailData.vmboxesData.data.pin;
          this.submitVoiceMail(voiceMailData);
        }
      }
    },
    async submitVoiceMail(voiceMailData) {
      try {
        let response = await callFlow.addVMBoxes(voiceMailData);
        this.editedItem.data.id = response.responseData.data.id;
        this.addNewVoiceMailDialog = false;
        this.loader = false;
        this.saveLoader = false;
        this.getVMBoxesList();
        this.resetFormData();
        this.$root.$emit("SHOW_SNACKBAR", {
          text: response.messageDetail,
          color: "success",
        });
      } catch (error) {
        this.loader = false;
        this.saveLoader = false;
        this.$root.$emit("SHOW_SNACKBAR", {
          text: "something wrong",
          color: "error",
        });
        console.log("====error===", error);
      }
    },
    async editVoiceMailData() {
      if (this.$refs.editform.validate()) {
        var editVoiceMailData = {
          accountId: this.userDetail.accountId,
          authToken: this.authToken,
          vmboxesId: this.editVoiceMail.id,
          editData: { data: this.editVoiceMail },
        };
        if (this.editVoiceMail.pin.length != 0) {
          if (
            this.editVoiceMail.pin.length < 3 ||
            this.editVoiceMail.pin.length > 6
          ) {
            this.$root.$emit("SHOW_SNACKBAR", {
              text: "Min 3 characters and Max 6 characters",
              color: "error",
            });
            this.loader = false;
            this.saveLoader = false;
          } else {
            this.submitEditVoiceMail(editVoiceMailData);
          }
        } else if (this.editVoiceMail.pin.length === 0) {
          delete this.editVoiceMail.pin;
          this.submitEditVoiceMail(editVoiceMailData);
        }
      }
    },
    async submitEditVoiceMail(voiceMailData) {
      this.editLoader = true;
      try {
        let response = await callFlow.editVMBoxesDetails(voiceMailData);
        this.editLoader = false;
        this.getVMBoxesList();
        this.editVoiceMailDialog = false;
        this.$root.$emit("SHOW_SNACKBAR", {
          text: response.messageDetail,
          color: "success",
        });
      } catch (error) {
        this.editLoader = false;
        this.$root.$emit("SHOW_SNACKBAR", {
          text: "something wrong",
          color: "error",
        });
        console.log("====error===", error);
      }
    },
    async editParticularVoiceMailDialog() {
      var getUsersData = {
        accountId: this.userDetail.accountId,
        vmboxesId: this.editedItem.data.id,
      };
      try {
        let response = await callFlow.getVMBoxesDetails(getUsersData);
        this.editVoiceMail = response.vmboxesDetails;
        if (this.editVoiceMail.pin === undefined) {
          this.editVoiceMail.pin = "";
        }
        this.editVoiceMailDialog = true;
      } catch (error) {
        console.log("====error===", error);
      }
      this.editVoiceMailDialog = true;
    },
    async deleteVMBoxesDetails() {
      this.deleteLoader = true;
      var delteUserData = {
        accountId: this.userDetail.accountId,
        authToken: this.authToken,
        vmboxesId: this.editedItem.data.id,
      };
      try {
        let response = await callFlow.deleteVMBoxesDetails(delteUserData);
        this.editVoiceMail = {
          media: {},
        };
        this.editedItem.data.id = "";
        this.getVMBoxesList();
        this.editVoiceMailDialog = false;
        this.deleteLoader = false;
        this.$root.$emit("SHOW_SNACKBAR", {
          text: response.messageDetail,
          color: "error",
        });
      } catch (error) {
        this.deleteLoader = false;
        this.$root.$emit("SHOW_SNACKBAR", {
          text: "something wrong",
          color: "error",
        });
        console.log("====error===", error);
      }
    },
    resetFormData() {
      this.$refs.form.reset();
    },
    openNewAddMediaDialog() {
      this.addNewMediaDialogBySelect = true;
    },
    closeNewMedia() {
      this.addNewMediaDialogBySelect = false;
      this.getMediaList();
    },
    openAddNewUserDialog() {
      this.addNewUserDialog = true;
    },
    closeAddNewUserDialog() {
      this.addNewUserDialog = false;
      this.getUsersList();
    },
  },
};
</script>
<template>
  <section>
    <v-card :loading="loader">
      <v-card-title class="title">
        Media
        <v-icon @click="closeMediaDialog" class="ml-auto">cancel</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <h3 class="pb-5">Connect caller to media...</h3>
        <v-select
          label="Media"
          :items="mediaList"
          item-text="name"
          item-value="id"
          v-model="editedItem.data.id"
          outlined
          color="primary"
        >
          <template slot="item" slot-scope="data">
            <v-list-item-content @click="getItemText(data.item.name)">
              <v-list-item-title v-html="data.item.name"></v-list-item-title>
            </v-list-item-content>
          </template>
        </v-select>
        <v-row>
          <v-col cols="12" sm="6">
            <v-btn
              block
              color="green"
              outlined
              :disabled="showEditBtn"
              @click="editMediaDialog()"
            >
              Edit
            </v-btn>
          </v-col>
          <v-col cols="12" sm="6">
            <v-btn block color="info" outlined @click="openNewAddMediaDialog()">
              Add
            </v-btn>
          </v-col>
        </v-row>
        <v-btn block color="primary" outlined large @click="save()"
          >Save
        </v-btn>
      </v-card-text>
    </v-card>
    <!-- Add media Dialog -->
    <add-new-media
      :addNewMediaDialogBySelect="addNewMediaDialogBySelect"
      @close-media="closeNewMedia"
    />
    <!-- Edit media Dialog -->
    <v-dialog v-model="editSelectedMediaDialog" width="400" persistent>
      <v-card>
        <v-card-title class="title">
          Edit Media
          <v-icon @click="editSelectedMediaDialog = false" class="ml-auto"
            >cancel</v-icon
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-text-field
            class="pt-5"
            label="Name"
            v-model="editMedia.name"
            hint="Friendly name for this media file"
            color="primary"
            outlined
          ></v-text-field>
          <v-select
            label="Media Type"
            :items="mediaTypeList"
            v-model="editMedia.media_source"
            outlined
            color="primary"
            clearable
          >
          </v-select>
          <v-text-field
            v-if="editMedia.media_source != 'tts'"
            label="File Input"
            v-model="editMedia.description"
            placeholder="No file selected"
            color="primary"
            clearable
            outlined
            disabled
          ></v-text-field>
          <!-- <v-file-input
            v-if="editMedia.media_source != 'tts'"
            label="File Input"
            v-model="editMedia.description"
            placeholder="No file selected"
            color="primary"
            @change="createBinaryData"
            show-size
            clearable 
            outlined
          ></v-file-input> -->
          <span v-if="editMedia.media_source == 'tts'">
            <v-textarea
              label="Text"
              v-model="editMedia.tts.text"
              outlined
              color="primary"
            ></v-textarea>
            <v-select
              label="Select speech type"
              :items="selectSpeechType"
              v-model="editMedia.tts.voice"
              outlined
              clearable
              color="primary"
            >
            </v-select>
          </span>
          <p><b>Advanced Options</b></p>
          <v-checkbox
            class="pt-0 mt-0"
            label="Streamable"
            v-model="editMedia.streamable"
          ></v-checkbox>
          <v-row>
            <v-col xs="6">
              <v-btn
                color="error"
                outlined
                large
                :loading="deleteLoader"
                @click="deleteMedia()"
                block
                >Delete
              </v-btn>
            </v-col>
            <v-col xs="6">
              <v-btn
                color="primary"
                outlined
                :loading="editLoader"
                class="px-5"
                large
                @click="saveEditMedia()"
                block
                >Save
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import callFlow from "@/services/callFlowAPI.js";
import getSpechType from "./jsFile/speechType";
import addNewMedia from "./addNewMedia.vue";

export default {
  props: ["editedItem", "selectedDragList", "editedIndex"],
  components: {
    addNewMedia,
  },
  data() {
    return {
      mediaList: [],
      selectSpeechType: [],
      addNewMediaDialog: false,
      editSelectedMediaDialog: false,
      showEditBtn: false,
      loader: false,
      editMedia: {
        tts: {},
      },
      authToken: "",
      mediaTypeList: [
        {
          text: "File",
          value: "upload",
        },
        {
          text: "TTS",
          value: "tts",
        },
      ],
      editLoader: false,
      rawData: [],
      deleteLoader: false,
      mediaType: "",
      addNewMediaDialogBySelect: false,
      editedItemModal: false,
      byDefualtAddData: true,
      selectedValue: "",
      silenceData: {},
    };
  },
  mounted() {
    this.selectSpeechType = getSpechType.speechType;
    this.userDetail = JSON.parse(localStorage.getItem("userDetail"));
    this.authToken = localStorage.getItem("authToken");
    this.getMediaList();
  },
  methods: {
    async getMediaList() {
      this.mediaData = [];
      var getUsersData = {
        accountId: this.userDetail.accountId,
      };
      try {
        let response = await callFlow.getMediaList(getUsersData);
        this.mediaList = response.mediaList;
        this.mediaList.push({ name: "Silence" });
        if (this.mediaList.length != 1) {
          if (this.editMedia.id) {
            this.editedItem.data.id = this.editMedia.id;
            this.editedItem.data.name = this.editMedia.name;
            this.selectedValue = this.editedItem.data.name;
            this.byDefualtAddData = false;
          } else {
            if (this.editedItem.data.id) {
              this.editedItemModal = true;
              for (let index = 0; index < this.mediaList.length; index++) {
                const mediaList = this.mediaList[index];
                if (mediaList.id === this.editedItem.data.id) {
                  this.editedItem.data.id = mediaList.id;
                  this.editedItem.data.name = mediaList.name;
                  this.selectedValue = this.editedItem.data.name;
                  this.byDefualtAddData = false;
                }
              }
            } else {
              this.editedItem.data.id = this.mediaList[0].id;
              this.editedItem.data.name = this.mediaList[0].name;
              this.selectedValue = this.editedItem.data.name;
              this.byDefualtAddData = true;
            }
          }
          this.showEditBtn = false;
        } else {
          this.showEditBtn = true;
        }
      } catch (error) {
        console.log("====error===", error);
      }
    },
    getItemText(name) {
      if (name === "Silence") {
        this.showEditBtn = true;
        this.silenceData = {
          id: "silence_stream://300000",
          name: name,
        };
      } else {
        this.silenceData = {};
        this.showEditBtn = false;
        // get the selected module name
        this.selectedValue = name;
      }
    },
    save() {
      this.byDefualtAddData = false;
      // get the selected module properties from editedItem obj
      var editedItem = {};
      if (this.silenceData && this.silenceData.name == "Silence") {
        editedItem.data = this.silenceData;
      } else {
        editedItem.data = {
          name: this.selectedValue,
          timeout: this.editedItem.data.timeout,
          can_call_self: this.editedItem.data.can_call_self,
          icon: this.editedItem.data.icon,
          id: this.editedItem.data.id,
        };
      }
      this.editedItem.data = editedItem.data;

      this.editedItemModal = true;
      //storing the selected data into call flow API on didnpbx page
      this.$emit(
        "save-media-data",
        this.selectedDragList,
        this.editedItem,
        this.editedIndex
      );
      this.closeOnSave();
    },
    openNewAddMediaDialog() {
      this.addNewMediaDialogBySelect = true;
    },
    async editMediaDialog() {
      this.loader = true;
      var getMediaDeatils = {
        accountId: this.userDetail.accountId,
        authToken: this.authToken,
        mediaId: this.editedItem.data.id,
      };
      try {
        let response = await callFlow.getMediaDetails(getMediaDeatils);
        this.editMedia = response.mediaDetails;
        this.editMedia.description =
          this.editMedia.description && this.editMedia.description
            ? this.editMedia.description.slice(12)
            : "";
        this.editSelectedMediaDialog = true;
        this.loader = false;
      } catch (error) {
        console.log("====error===", error);
        this.loader = false;
      }
    },
    async saveEditMedia() {
      this.editLoader = true;
      var editMediaData = {
        accountId: this.userDetail.accountId,
        authToken: this.authToken,
        mediaId: this.editedItem.data.id,
        editData: {
          data: {
            streamable: this.editedItem.streamable,
            name: this.editMedia.name,
            media_source: this.editMedia.media_source,
            auth_token: "",
            // description: this.editMedia.description,
            tts: {
              text: this.editMedia.tts.text,
              voice: this.editMedia.tts.voice,
            },
            ui_metadata: {
              version: "4.3-139",
              ui: "monster-ui",
              origin: "callflows",
            },
            language: "en-us",
            id: this.editedItem.data.id,
          },
        },
      };
      if (this.media && this.media.upload) {
        editMediaData.editData.data.description = this.media.upload;
      } else if (this.editMedia.description) {
        editMediaData.editData.data.description = this.editMedia.description;
      }
      if (this.rawData.length != 0) {
        editMediaData.rawData = this.rawData;
      }
      if (this.mediaType === "error") {
        this.$root.$emit("SHOW_SNACKBAR", {
          text: "This is an invalid upload file.",
          color: "error",
        });
        this.editLoader = false;
      } else {
        try {
          let response = await callFlow.editMediaDetails(editMediaData);
          this.getMediaList();
          this.editLoader = false;
          this.editSelectedMediaDialog = false;
          this.$root.$emit("SHOW_SNACKBAR", {
            text: response.messageDetail,
            color: "success",
          });
        } catch (error) {
          this.editLoader = false;
          this.$root.$emit("SHOW_SNACKBAR", {
            text: "something wrong",
            color: "error",
          });
          console.log("====error===", error);
        }
      }
    },
    async deleteMedia() {
      this.deleteLoader = true;
      var deleteMediaData = {
        accountId: this.userDetail.accountId,
        authToken: this.authToken,
        mediaId: this.editedItem.data.id,
      };
      try {
        let response = await callFlow.deleteMediaDetails(deleteMediaData);
        this.editMedia = {
          tts: {},
        };
        this.editedItem.data.id = "";
        this.getMediaList();
        this.editSelectedMediaDialog = false;
        this.$root.$emit("SHOW_SNACKBAR", {
          text: response.messageDetail,
          color: "success",
        });
        this.deleteLoader = false;
      } catch (error) {
        console.log("====error===", error);
        this.deleteLoader = false;
        this.$root.$emit("SHOW_SNACKBAR", {
          text: "something wrong",
          color: "error",
        });
      }
    },
    closeOnSave() {
      this.$emit("close-on-save-media-dialog");
    },
    closeMediaDialog() {
      if (this.byDefualtAddData) {
        this.editedItem.data.id = "";
        this.editedItem.data.name = "";
        this.selectedValue = "";
      }
      this.closeOnSave();
    },
    closeNewMedia(mediaId) {
      if (mediaId) {
        this.editedItem.data.id = mediaId;
      }
      this.addNewMediaDialogBySelect = false;
      this.getMediaList();
    },
  },
};
</script>


<style>
.v-input__append-outer,
.v-input__prepend-outer {
  display: none;
}
</style>
<template>
  <section>
    <v-card :loading="loader">
      <v-card-title class="title">
        Device
        <v-icon @click="closeDeviceDialog" class="ml-auto">cancel</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <h3 class="pb-5">Connect a caller to a device...</h3>
        <v-select
          label="Which Device?"
          :items="deviceList"
          item-text="name"
          item-value="id"
          v-model="editedItem.data.id"
          outlined
          color="primary"
        >
          <template slot="item" slot-scope="data">
            <v-list-item-content @click="getItemText(data.item.name)">
              <v-list-item-title v-html="data.item.name"></v-list-item-title>
            </v-list-item-content>
          </template>
        </v-select>
        <v-text-field
          hint="Ring this device for 20 seconds."
          outlined
          v-model="editedItem.data.timeout"
          color="primary"
          persistent-hint
        ></v-text-field>
        <v-checkbox
          label="Allow this device to call itself."
          v-model="editedItem.data.can_call_self"
        ></v-checkbox>
        <p class="text-center">
          <small
            >When you check this box, calls from the device's own extension to
            themselves will not ring the extension. This allows them to dial
            their own number to get to voicemail.</small
          >
        </p>
        <v-row>
          <v-col cols="12" sm="6">
            <v-btn
              block
              color="green"
              outlined
              :disabled="disabledEditButton"
              @click="editParticularDeviceDialog(editedItem)"
            >
              Edit</v-btn
            >
          </v-col>
          <v-col cols="12" sm="6">
            <v-btn block color="info" outlined @click="openNewDeviceDialog()">
              Add
            </v-btn>
          </v-col>
        </v-row>
        <v-btn block color="primary" outlined large @click="save()"
          >Save
        </v-btn>
      </v-card-text>
    </v-card>
    <!-- Add new device Dialog -->
    <add-new-devices
      :addNewDeviceDialog="addNewDeviceDialog"
      :usersList="usersList"
      :userDetail="userDetail"
      :authToken="authToken"
      @openAddNewUserData="openAddNewUserDialog"
      @getSoftPhoneData="getSoftPhoneDeviceData"
      @close-new-device-modal="closeNewDeviceDialog"
      ref="getUsername"
    />
    <!-- end: Add new device Dialog  -->

    <!-- Edit device Dialog -->
    <edit-device-dialog
      :editCreatedDeviceDialog="editCreatedDeviceDialog"
      :userDetail="userDetail"
      :authToken="authToken"
      :usersList="usersList"
      :editDevice="editDevice"
      :editedItem="editedItem"
      @close-edit-device-dialog="closeEditDeviceDialog"
      @close-on-delete-edit-device-dialog="closeOnDeleteEditDeivce"
    />
    <!-- end: edit device Dialog  -->

    <!-- Add new user Dialog -->
    <add-new-user-dialog
      :addNewUserDialog="addNewUserDialog"
      @close-new-user-dialog="closeAddNewUserDialog"
    />
  </section>
</template>

<script>
import callFlow from "@/services/callFlowAPI.js";
import addNewUserDialog from "./addNewUserDialog";
import AddNewDevices from "./addNewDevices.vue";
import editDeviceDialog from "./editDeviceDialog.vue";

export default {
  props: ["editedItem", "selectedDragList", "editedIndex"],
  components: {
    addNewUserDialog,
    AddNewDevices,
    editDeviceDialog,
  },
  data() {
    return {
      editDevice: {},
      deviceList: [],
      deviceData: {},
      addNewDeviceDialog: false,
      editCreatedDeviceDialog: false,
      loader: false,
      disabledEditButton: false,
      // subscriptionNewList:[],
      tab: null,
      usersList: [],
      authToken: "",
      selectedValue: "",
      editedItemModal: false,
      addNewUserDialog: false,
      byDefualtAddData: true,
      userDetail: "",
    };
  },
  mounted() {
    this.userDetail = JSON.parse(localStorage.getItem("userDetail"));
    this.authToken = localStorage.getItem("authToken");
    this.getSoftPhoneDeviceList();
    this.getUsersList();
  },
  methods: {
    async getUsersList() {
      var getUsersData = {
        accountId: this.userDetail.accountId,
        userId: this.userDetail.userId,
        forwardType: "SOFTPHONE",
      };
      try {
        let response = await callFlow.getUsersList(getUsersData);
        // console.log("response ", response);
        var userData = {};
        for (let index = 0; index < response.usersList.length; index++) {
          const element = response.usersList[index];
          if (element.last_name === undefined || element.last_name === null) {
            element.name = element.first_name;
          } else {
            element.name = element.first_name + " " + element.last_name;
          }
          userData = element;
          this.usersList.push(userData);
        }
      } catch (error) {
        console.log("====error===", error);
      }
    },
    getSoftPhoneDeviceData(deviceId) {
      this.editedItem.data.id = deviceId;
      this.getSoftPhoneDeviceList();
    },
    async getSoftPhoneDeviceList() {
      this.loader = true;
      this.deviceList = [];
      var getFilteredDeviceData = {
        accountId: this.userDetail.accountId,
      };
      try {
        let response = await callFlow.getDeviceList(getFilteredDeviceData);
        this.deviceList = response.deviceList;
        localStorage.setItem("getDeviceList", JSON.stringify(this.deviceList));
        // console.log("this.deviceList=======",this.deviceList)
        if (this.deviceList.length != 0) {
          if (this.editDevice.id) {
            this.editedItem.data.id = this.editDevice.id;
            this.editedItem.data.name = this.editDevice.name;
            this.selectedValue = this.editDevice.name;
            this.byDefualtAddData = false;
          } else {
            // this function for when get the call flow and already created value in call flow then showing value name in module name select box
            if (this.editedItem.data.id) {
              this.editedItemModal = true;
              for (let index = 0; index < this.deviceList.length; index++) {
                const deviceList = this.deviceList[index];
                if (deviceList.id === this.editedItem.data.id) {
                  this.editedItem.data.id = deviceList.id;
                  this.editedItem.data.name = deviceList.name;
                  this.selectedValue = deviceList.name;
                  this.byDefualtAddData = false;
                }
              }
            } else {
              // this function for by default showing first element in select box name
              this.editedItem.data.id = this.deviceList[0].id;
              this.editedItem.data.name = this.deviceList[0].name;
              this.selectedValue = this.deviceList[0].name;
              this.disabledEditButton = false;
              this.byDefualtAddData = true;
            }
          }
          this.disabledEditButton = false;
        } else {
          this.disabledEditButton = true;
        }
        this.loader = false;
      } catch (error) {
        this.loader = false;
        console.log("====error===", error);
      }
    },
    getItemText(selectText) {
      // get the selected module name
      this.selectedValue = selectText;
    },
    save() {
      this.byDefualtAddData = false;
      // get the selected module properties from editedItem obj
      var editedItem = {};
      editedItem.data = {
        name: this.selectedValue,
        timeout: this.editedItem.data.timeout,
        can_call_self: this.editedItem.data.can_call_self,
        icon: this.editedItem.data.icon,
        id: this.editedItem.data.id,
      };
      this.editedItem.data = editedItem.data;
      // this.selectedDragList[this.editedIndex] = this.editedItem;
      this.editedItemModal = true;
      //storing the selected data into call flow API on didnpbx page
      this.$emit(
        "save-device-data",
        this.selectedDragList,
        this.editedItem,
        this.editedIndex
      );
      this.closeOnSave();
    },
    closeOnSave() {
      this.$emit("close-on-save-device-dialog");
    },
    closeDeviceDialog() {
      if (this.byDefualtAddData) {
        this.editedItem.data.id = "";
        this.editedItem.data.name = "";
        this.selectedValue = "";
      }
      this.closeOnSave();
    },

    async editParticularDeviceDialog(item) {
      this.editDevice = {};
      // console.log("this.editedItem.data.id", this.editedItem.data.id);
      this.loader = true;
      var getDeviceData = {
        accountId: this.userDetail.accountId,
        authToken: this.authToken,
        deviceId: this.editedItem.data.id,
      };
      try {
        let response = await callFlow.getParticularDeviceDetails(getDeviceData);
        this.editDevice = response.deviceDetails;
        if (
          this.editDevice &&
          this.editDevice.call_forward &&
          this.editDevice.call_forward.number
        ) {
          this.editDevice.number = this.editDevice.call_forward.number;
        } else {
          if (
            this.editDevice.caller_id &&
            Object.keys(this.editDevice.caller_id).length != 0
          ) {
            if (
              this.editDevice &&
              this.editDevice.caller_id &&
              this.editDevice.caller_id.external &&
              this.editDevice.caller_id.external.number
            ) {
              this.editDevice.number =
                this.editDevice.caller_id.external.number;
            }
          }
        }
        this.editCreatedDeviceDialog = true;
        this.getUsersList();
        // this.closeOnSave();
        this.loader = false;
      } catch (error) {
        console.log("====error===", error);
        this.loader = false;
      }
    },

    closeEditDeviceDialog() {
      this.$emit("open-device-modal");
      this.editCreatedDeviceDialog = false;
      this.getSoftPhoneDeviceList();
      this.editDevice = {};
    },
    closeNewDeviceDialog() {
      this.addNewDeviceDialog = false;
      this.editDevice = {};
      // this.resetUserData();
      // this.$emit("open-device-modal");
    },
    closeOnDeleteEditDeivce() {
      this.$emit("open-device-modal");
      this.getSoftPhoneDeviceList();
      this.editCreatedDeviceDialog = false;
      this.editedItem.data.id = "";
      this.editDevice = {};
    },
    openNewDeviceDialog() {
      this.addNewDeviceDialog = true;
      this.$refs.getUsername.setValue();
      // this.$emit("close-on-save-device-dialog");
    },
    openAddNewUserDialog() {
      this.addNewUserDialog = true;
    },
    closeAddNewUserDialog() {
      this.addNewUserDialog = false;
      this.getUsersList();
    },
  },
};
</script>
const actionListMenu = [
    {
        module: "record_call",
        name: "Record Call",
        data: {
            action: "start",
            time_limit: "600"
        },
        children: {},
        icon: "mic",
        tooltip: "Direct a caller to a specific user",
        dropEnable: false

    },
    {
        module: "dead_air",
        name: "Dead Air",
        data: {},
        children: {},
        icon: "connecting_airports",
        tooltip: "Answer and keep silence",
        dropEnable: false

    },
    {
        module: "check_cid",
        name: "CID Check",
        data: {
            regex: "",
            use_absolute_mode: true
        },
        children: {},
        icon: "library_add_check",
        tooltip: "Route incoming call based on Caller ID.",
        dropEnable: false
    },
    {
        module: "cidlistmatch",
        name: "CID Match",
        data: {
            use_absolute_mode: true
        },
        children: {},
        icon: "compare",
        tooltip: "Route incoming call based on Caller ID.",
        dropEnable: false

    }
]


export default { actionListMenu };
<template>
  <v-card>
    <v-card-title class="title">
      Call Flow
      <v-icon @click="closeNumberDialog" class="ml-auto">cancel</v-icon>
    </v-card-title>
    <v-divider></v-divider>
    <!-- {{ editedItem.data.id }} -->
    <v-card-text class="pt-5">
      <v-form ref="form" v-model="isFormValid" lazy-validation>
        <v-select
          label="Other call flows"
          :items="getCallFlowList"
          :item-text="
            (item) =>
              item.name ? item.name + ' - ' + item.numbers : item.numbers
          "
          item-value="id"
          v-model="editedItem.data.id"
          :rules="subscriptionNumberRules"
          outlined
          color="primary"
        ></v-select>
        <v-btn
          :disabled="getCallFlowList ? false : true"
          block
          color="primary"
          outlined
          large
          @click="addSubscriptionNumber()"
        >
          Link Call Flow
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>
  
  
  <script>
// import callFlow from "@/services/callFlowAPI.js";

export default {
  props: ["editedItem", "selectedDragList", "editedIndex"],
  data() {
    return {
      //   editCallFlow: [
      //     {
      //       title: "Available Spare Numbers",
      //       value: "spareNumber",
      //     },
      //     {
      //       title: "Extension",
      //       value: "extension",
      //     },
      //   ],
      //   selectCallFlowType: "spareNumber",
      selectedSubscriptionNumber: "",
      //   userDetail: {},
      isFormValid: true,
      //   typeNumberRules: [(v) => !!v || "This fields is required"],
      subscriptionNumberRules: [(v) => !!v || "This fields is required"],
      getCallFlowList: [],
      //   extensionNumberRules: [
      //     (v) =>
      //       (v && v.length <= 5) ||
      //       "This field is required and you can enter max 5 digits.",
      //   ],
    };
  },
  mounted() {
    // this.userDetail = JSON.parse(localStorage.getItem("userDetail"));
    // this.selectCallFlowType = "";
    this.getCallFlowList = JSON.parse(localStorage.getItem("callFlowList"));
    // var selectedNumbers = localStorage.getItem("selectedNumbers");
    // console.log("selectedNumbers",selectedNumbers)
    // console.log("this.getCallFlowList", this.getCallFlowList);
  },
  methods: {
    addSubscriptionNumber() {
      if (this.$refs.form.validate()) {
        // var checkAlreadySelectedData = this.checkItem(
        //   this.selectedNumbers,
        //   this.selectedSubscriptionNumber
        // );
        // if (checkAlreadySelectedData == false) {
        //   this.selectedNumbers.push(this.selectedSubscriptionNumber);
        // var editedItem = {
        //   data: {
        // id: this.selectedSubscriptionNumber,
        //   },
        // };
        const callFLow = this.getCallFlowList.find(
          (moduleObject) => moduleObject.id == this.editedItem.data.id
        );
        // console.log("callFLow", callFLow);
        // this.editedItem.data.id = this.selectedSubscriptionNumber;
        this.editedItem.data.name = callFLow.name
          ? callFLow.name + " - " + callFLow.numbers[0]
          : callFLow.numbers[0];
        // console.log("this.editedItem.data", this.editedItem);
        this.$emit(
          "add-call-flow-Numbers",
          this.selectedDragList,
          this.editedItem,
          this.editedIndex
        );
        // this.closeNumberDialog();
      }
      // else {
      //   this.$root.$emit("SHOW_SNACKBAR", {
      //     text: " Already Selected!",
      //     color: "error",
      //   });
      // }
      //   }
    },
    closeNumberDialog() {
      this.$emit("close-call-flow-Dialog");
      this.selectedSubscriptionNumber = "";
      //   (this.selectCallFlowType = "spareNumber"), this.$refs.form.reset();
    },
    checkItem(arrayItem, searchItem) {
      return (
        arrayItem.findIndex((element) => element.includes(searchItem)) >= 0
      );
    },
  },
};
</script>
const http = require('./http');
const authToken = localStorage.getItem("authToken");

const getDidNumberDetailSubscriptionNew = (payload) => {
    return new Promise((resolve, reject) => {
        // http.get(`/getDidNumberDetailSubscriptionNew?viewIndex=${payload.viewIndex}&viewSize=${payload.viewSize}&partyId=${payload.partyId}&didStatus=${payload.didStatus}`).then((data) => {
        http.get(`/getSpareNumbers?accountId=${payload.accountId}`).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const getDeviceList = (payload) => {
    return new Promise((resolve, reject) => {
        http.get(`/getDeviceList?accountId=${payload.accountId}&authToken=${localStorage.getItem("authToken")}`).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const getDeviceDetails = (payload) => {
    return new Promise((resolve, reject) => {
        http.get(`/getDeviceDetails?accountId=${payload.accountId}&authToken=${localStorage.getItem("authToken")}&deviceId=${payload.deviceId}`).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const getFaxboxesList = (payload) => {
    return new Promise((resolve, reject) => {
        http.get(`/getFaxboxesList?accountId=${payload.accountId}&authToken=${localStorage.getItem("authToken")}`).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const deleteDeviceDetails = (payload) => {
    return new Promise((resolve, reject) => {
        http.delete(`/deleteDeviceDetails?accountId=${payload.accountId}&deviceId=${payload.deviceId}&authToken=${localStorage.getItem("authToken")}`).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const deleteFaxboxesDetails = (payload) => {
    return new Promise((resolve, reject) => {
        http.delete(`/deleteFaxboxesDetails?accountId=${payload.accountId}&faxboxesId=${payload.faxboxesId}&authToken=${localStorage.getItem("authToken")}`).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const deleteCallFlow = (payload) => {
    return new Promise((resolve, reject) => {
        http.delete(`/deleteCallFlow?accountId=${payload.accountId}&didNumber=${payload.didNumber}&authToken=${payload.authToken}&inventoryItemId=${payload.inventoryItemId}&callflowId=${payload.callflowId}`).then((data) => {
            resolve(data);
            this
        }).catch((error) => {
            reject(error);
        });
    });
};


module.exports = {
    getDidNumberDetailSubscriptionNew,
    getDeviceList,
    getDeviceDetails,
    getFaxboxesList,
    deleteDeviceDetails,
    deleteFaxboxesDetails,
    deleteCallFlow,
};
<template>
  <section>
    <v-card :loading="loader">
      <v-card-title class="title">
        Timezone
        <v-icon @click="closeTimeOfDayDialog" class="ml-auto">cancel</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="form" v-model="isFormValid" lazy-validation>
          <p>Select Timezone</p>
          <v-select
            label="Timezone"
            :items="timeZoneCountryList"
            v-model="editedItem.data.timezone"
            outlined
            color="primary"
            :rules="formatRules"
          ></v-select>
          <v-btn block color="primary" outlined large @click="save()"
            >Save
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </section>
</template>

<script>
import getTimeZoneCuntryList from "./jsFile/timeZoneCuntryList";

export default {
  props: ["editedItem", "selectedDragList", "editedIndex"],
  data() {
    return {
      formatRules: [(v) => !!v || "Fields is required"],
      userDetail: {},
      loader: false,
      isFormValid: true,
      saveLoader: false,
      editedItemModal: false,
      authToken: "",
      selectedValue: "",
      timeZoneCountryList: [],
    };
  },
  mounted() {
    this.timeZoneCountryList = getTimeZoneCuntryList.timezoneCountryList;
    this.userDetail = JSON.parse(localStorage.getItem("userDetail"));
    this.authToken = localStorage.getItem("authToken");
  },
  methods: {
    closeAddNewTimeOfDayDialog() {
      this.addNewTimeOfDayDialog = false;
    },
    save() {
      // get the selected module properties from editedItem obj
      if (this.$refs.form.validate()) {
        this.editedItem.data.name = this.editedItem.data.timezone;
        if (this.editedItem.data.timezone === "- Account/User default -") {
          delete this.editedItem.data.timezone;
        }
        this.editedItemModal = true;
        //storing the selected data into call flow API on didnpbx page
        this.$emit(
          "save-timezone-data",
          this.selectedDragList,
          this.editedItem,
          this.editedIndex
        );
        this.closeOnSave();
      }
    },
    closeOnSave() {
      this.$emit("close-on-save-timezone-dialog");
    },
    closeTimeOfDayDialog() {
      this.closeOnSave();
    },
  },
};
</script>